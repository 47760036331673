import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ReactWhatsapp from "react-whatsapp";
import React, { useState } from "react";
import {
  Box,
  IconButton,
  Container,
  Divider,
  FormControl,
  Grid,
  Typography,
  styled,
} from "@mui/material";
function WhatsApp() {
  return (
    <>
      <IconButton
        component="label"
        fullWidth
        sx={{
          width: "30%",
          height: "30px",
          borderRadius: 50,
          display: "flex",
          justifyContent: "center",
          color: "white",
        }}
        size="large"
      >
        <WhatsAppIcon style={{ marginLeft: "5px", fontSize: "30px" }} />
        <ReactWhatsapp
          style={{ visibility: "hidden" }}
          number="9944603844"
          message="hii"
        />
      </IconButton>
    </>
  );
}
export default WhatsApp;
