import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  keyframes,
  styled,
} from "@mui/material";
import { CSSTransition } from "react-transition-group";
import Accounticon from "../../assets/Images/homepageimage.png";
// import SAP_ABAP from "/images/SAP ABAP.png";
// import ACP from "/images/PMI-ACP.png";
// import BlackBelt from "/images/BlackBelt.png";
// import PMPcertification from "/images/PMPcertification.png";
// import agileandscrum from "/images/agileandscrum.png";
// import Leankanban from "/images/Leankanban.png";
// import { courseSubjects } from "./CourseSubData";
import { coursesnames } from "./CourseSubData";
import { get } from "../../apis/apiMethods";
import urls from "../../apis/urlContants";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import { theme } from "../../theme";

const courseSubjects = [
  {
    id: "1",
    coursetitle: "ABAP",
    img: "/images/SAP ABAP.png",
    coursedesc:
      "SAP ABAP (Advanced Business Application Programming) is a high-level programming language created by SAP for developing custom business applications in the SAP environment.",

    vediotitle: "Mathematics",
    vediodesc: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    virtualtitle: "Science",
    virtualdesc: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  },
  {
    id: "2",
    coursetitle: "Agile and Scrum",
    img: "/images/agile and scrum.png",
    coursedesc:
      "Embark on a transformative journey in the world of project management with our Agile and Scrum course, equipping you with the skills to navigate dynamic landscapes and deliver successful, iterative solutions",
    vediotitle: "Mathematics",
    vediodesc: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    virtualtitle: "Science",
    virtualdesc: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  },
  {
    id: "3",
    coursetitle: "Lean Six Sigma Black Belt",
    img: "/images/Black Belt.png",
    coursedesc:
      "This course equips professionals with advanced problem-solving and process improvement skills, integrating the principles of Lean and Six Sigma methodologies for optimal organizational performance.",
    vediotitle: "Mathematics",
    vediodesc: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    virtualtitle: "Science",
    virtualdesc: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  },
  {
    id: "4",
    coursetitle: "PMP certification",
    img: "/images/PMP certification.png",
    coursedesc:
      "This course equips professionals with essential project management skills and methodologies, validating their ability to lead and deliver successful projects.",
    vediotitle: "Mathematics",
    vediodesc: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    virtualtitle: "Science",
    virtualdesc: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  },
  {
    id: "5",
    coursetitle: "PMP ACP",
    img: "/images/PMI ACP.png",
    coursedesc:
      "The PMI Agile Certified Practitioner certification course equips professionals with a versatile skill set, integrating various agile methodologies to enhance project management agility.",
    vediotitle: "Mathematics",
    vediodesc: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    virtualtitle: "Science",
    virtualdesc: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  },
  {
    id: "6",
    coursetitle: "Lean and Kanban",
    img: "/images/Lean and kanban.png",

    coursedesc:
      "Enroll in our Lean and Kanban course to master streamlined processes and agile methodologies for optimized workflow and continuous improvement.",
    vediotitle: "Mathematics",
    vediodesc: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    virtualtitle: "Science",
    virtualdesc: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  },
];

const Account = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "10px",
  marginTop: -20,
  marginRight: 10,
}));
const rotateAnimation = keyframes`
  100% {
    transform: rotate(1turn);
  }
`;
const ViewMore = styled(Button)(({ theme }) => ({
  border: "1px solid grey",
  padding: "4px 25px",
  color: "grey",
  textTransform: "none",
  "&:hover": {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
}));
const ViewLess = styled(Button)(({ theme }) => ({
  border: "1px solid grey",
  padding: "4px 25px",
  color: "grey",
  textTransform: "none",
  "&:hover": {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
}));

const Heading = styled(Typography)({
  textAlign: "center",
  fontSize: 35,
  fontWeight: 700,
  fontFamily: "Quicksand",
});

const CardContents = styled(CardContent)(() => ({
  color: "white",
  // display: "flex",
  // flex: 1,
  // flexDirection: "column",
  // height: "100%",
}));

const styles = {
  conic: {
    position: "relative",
    zIndex: 0,

    borderRadius: "0 50px",
    overflow: "hidden",
    border: "15px inset #ffff",
    "&::before": {
      content: '""',
      position: "absolute",
      zIndex: -2,
      left: "-50%",
      top: "-50%",
      width: "200%",
      height: "200%",
      backgroundColor: "#1a232a",

      backgroundRepeat: "no-repeat",
      backgroundPosition: "0 0",
    },
    " &::after": {
      content: '""',
      position: "absolute",
      zIndex: -1,
      left: "6px",
      top: "6px",
      width: "calc(100% - 12px)",
      height: "calc(100% - 12px)",
      background: theme.palette.primary.main,
      borderRadius: "5px",
    },
    "&:hover::before": {
      // backgroundImage:
      //   "conic-gradient(transparent, gold,white,#009974,gold, transparent 30%)",
      // animation: `${rotateAnimation} 4s linear infinite`,

      backgroundImage: `
    conic-gradient(
      transparent,
      ${theme.palette.secondary.main},
      white,
       ${theme.palette.primary.main},
     ${theme.palette.secondary.main},
      transparent 30%
    )`,
      animation: `${rotateAnimation} 4s linear infinite`,
    },
  },
};

const CourseSub = () => {
  const [showAll, setShowAll] = useState(false);
  const [selectedButton, setSelectedButton] = useState(0);
  const [GetSelectedCourses, setSelectedCourses] = useState([]);
  // console.log(GetSelectedCourses, "-----GetSelectedCourses");

  const handleSelectedClick = (index) => {
    setSelectedButton(index);
  };

  const handleClick = () => {
    setShowAll(!showAll);
  };

  const rightSlideAnimation = keyframes`
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0%);
    }
  `;
  const leftSlideAnimation = keyframes`
             from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0%);
    }
  `;
  const fadeInAnimation = keyframes`
            0% {
        transform: translateX(-100%) scale(1);
      }
      10% {
        transform: translateX(-90%) scale(0.8);
      }
      20% {
        transform: translateX(-80%) scale(0.6);
      }
      30% {
        transform: translateX(-70%) scale(0.4);
      }
      40% {
        transform: translateX(-60%) scale(0.2);
      }
      50% {
        transform: translateX(-50%) scale(0.1);
      }
      60% {
        transform: translateX(-40%) scale(0.2);
      }
      70% {
        transform: translateX(-30%) scale(0.4);
      }
      80% {
        transform: translateX(-20%) scale(0.6);
      }
      90% {
        transform: translateX(-10%) scale(0.8);
      }
      100% {
        transform: translateX(0%) scale(1);
      }
`;

  const getCourseList = async () => {
    const res = await get(`${urls.course.list}`);
    setSelectedCourses(res?.data?.result?.data);
    const allCourses = res?.data?.result?.data;
    // console.log(allCourses.onlineInstructorLedInfo, "-66allCourses");
    // allCourses.filter((courses) => console.log(courses, "---y"));
  };

  React.useEffect(() => {
    getCourseList();
  }, [window.localStorage.getItem("courseId")]);

  return (
    <>
      <Box
        bgcolor="whitesmoke"
        sx={{ padding: { xs: "0 0", sm: "0 0", md: "0 50px" } }}>
        <Container maxWidth="xl">
          <Grid container mt={1} spacing={6} my={3}>
            <Grid item xs={12} md={12}>
              <Heading>Trending Courses!</Heading>
            </Grid>
            {courseSubjects
              .slice(0, showAll ? courseSubjects.length : 6)
              .map((subject, index) => (
                <CSSTransition key={index} timeout={500} classNames="slide">
                  <Grid
                    sx={{ display: "flex", flex: 1 }}
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={4}>
                    <Box sx={{ display: "flex", flex: 1 }}>
                      {selectedButton === 0 && (
                        <Card
                          sx={{
                            ...styles.conic,
                            display: "flex",
                            flex: 1,

                            animation: `${leftSlideAnimation} 0.5s forwards`,
                          }}>
                          <CardContents>
                            <Account>
                              <Typography variant="h6">
                                {subject.coursetitle}
                              </Typography>
                              <img
                                height={80}
                                width={80}
                                src={subject.img}
                                alt="Loading..."
                              />
                            </Account>
                            <Typography fontSize="16px" padding="10px">
                              {subject.coursedesc}
                            </Typography>
                          </CardContents>
                        </Card>
                      )}
                      {/* {selectedButton === 1 && (
                        <Card
                          sx={{
                            ...styles.conic,
                            animation: `${fadeInAnimation} 0.5s forwards`,
                          }}
                        >
                          <CardContent sx={{ color: "#ffff" }}>
                            <Typography>{subject.virtualtitle}</Typography>
                            <Account>
                              <img
                                height={50}
                                width={35}
                                src={Accounticon}
                                alt=""
                              />
                            </Account>
                            <Typography fontSize="20px">
                              {subject.virtualdesc}
                            </Typography>
                          </CardContent>
                        </Card>
                      )}
                      {selectedButton === 2 && (
                        <Card
                          sx={{
                            ...styles.conic,
                            animation: `${rightSlideAnimation} 0.5s forwards`,
                          }}
                        >
                          <CardContents>
                            <Typography>{subject.vediotitle}</Typography>
                            <Account>
                              <img
                                height={50}
                                width={35}
                                src={Accounticon}
                                alt=""
                              />
                            </Account>
                            <Typography fontSize="20px">
                              {subject.vediodesc}
                            </Typography>
                          </CardContents>
                        </Card>
                      )} */}
                    </Box>
                  </Grid>
                </CSSTransition>
              ))}
          </Grid>
          {/* ==================READ MORE=========DONOT REMOVE THIS COMMENT======= */}
          {/* <Grid
            item
            xs={12}
            md={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: 5,
              marginBottom: 5,
            }}
          >
            {showAll ? (
              <ViewLess variant="outlined" onClick={handleClick}>
                View Less
              </ViewLess>
            ) : (
              <ViewMore variant="outlined" onClick={handleClick}>
                View More
              </ViewMore>
            )}
          </Grid> */}
        </Container>
      </Box>
    </>
  );
};

export default CourseSub;
