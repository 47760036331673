import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  Typography,
  styled,
} from "@mui/material";
import ScrollAnimation from "react-animate-on-scroll";
import BecomeIn from "../assets/Images/become-in.png";
import FormInput from "../form/FormInput";
import { Form, useFormik } from "formik";
import * as Yup from "yup";
import { post } from "../apis/apiMethods";
import urls from "../apis/urlContants";
import useScrollToTopOnNavigation from "../hooks/useScrollToTopNavigation";
import { LoadingButton } from "@mui/lab";
import { theme } from "../theme";
import TrainingPartner from "../assets/Images/Trainngpartner.png";

const becomeContaints = [
  {
    heading: "Impactful Teaching",
    subHeading:
      "Inspire and educate students who are eager to learn and grow in their chosen fields. Your expertise can make a lasting difference in their lives.",
  },
  {
    heading: "Global Reach",
    subHeading:
      "Reach a diverse and global audience. Our platform connects you with students from all corners of the world, allowing you to share your knowledge with a wide range of individuals.",
  },
  {
    heading: "Flexibility",
    subHeading:
      "Enjoy the freedom of teaching on your terms. Set your own schedule, create your own courses, and choose the subjects you're passionate about.",
  },
  {
    heading: "Financial Rewards",
    subHeading:
      "Earn a competitive income as you teach what you love. We offer a revenue-sharing model that rewards you for your contributions.",
  },
  {
    heading: "Professional Development",
    subHeading:
      "Access resources, training, and support to enhance your teaching skills and content creation. We are invested in your growth as an educator.",
  },
];

const Subheading = styled(Typography)(() => ({
  fontSize: " 20px",
  margin: " 13px 0",
  color: "black",
  fontWeight: 500,
  fontFamily: "Quicksand",
  fontWeight: 700,
}));

const Content = styled(Typography)(() => ({
  color: " #555",
  fontSize: "15px",
}));

const Heading = styled(Typography)(() => ({
  color: "#000",
  marginBottom: "15px",
  marginTop: "15px",
  fontFamily: "Quicksand",
  fontSize: 20,
  fontWeight: 700,
}));

export const BecomeInstructor = () => {
  useScrollToTopOnNavigation();
  const [submittingForm, setSubmittingForm] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      contactNumber: "",
      expertise: "",
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required().label("Name").max(150),
      email: Yup.string().email().required().label("Email").max(150),
      contactNumber: Yup.number()
        .nullable()
        .required()
        .label("Contact Number")
        .min(1000000000, "Invalid contact number")
        .max(999999999999, "Invalid contact number"),
      expertise: Yup.string().required().label("Expertise"),
      message: Yup.string().required().label("Message"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        const body = {
          subject: "Regarding Becoming an Instructor",
          customerDetails: {
            name: values.name,
            email: values.email,
            contactNumber: values.contactNumber,
          },
          enquiryDetails: {
            others: {
              expertise: values.expertise,
            },
            programmeDetails: {},
          },
          message: values.message,
        };

        setSubmittingForm(true);

        const { data } = await post(`${urls.enquiry.createForm}`, body);
        resetForm();
      } catch (error) {
        console.error(error);
      }
      setSubmittingForm(false);
    },
    validateOnBlur: false,
    enableReinitialize: true,
  });

  return (
    <>
      <Box
        sx={{
          backgroundImage: `radial-gradient(at bottom left, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.0)),url(${TrainingPartner})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "100%",
          // margin: "5px 5px",
          padding: { xs: "100px 0", sm: "100px 0", md: "170px 50px" },
        }}
      >
        <Container maxWidth="xl">
          <Typography
            variant="h2"
            sx={{
              fontFamily: "Quicksand",
              fontSize: { xs: "24px", sm: "36px", md: "52px" },
              color: "#fff",
              fontWeight: 700,
            }}
          >
            Become an Instructor
          </Typography>
        </Container>
      </Box>
      <Box bgcolor="whitesmoke">
        <Container maxWidth="xl">
          <Grid
            container
            spacing={2}
            sx={{
              padding: { xs: "0 0", sm: "0 0", md: "50px 50px" },
            }}
          >
            <Grid item xs={12} md={12} lg={8}>
              <Typography variant="body1">
                Are you passionate about sharing your knowledge and expertise
                with others? Do you have a skill or subject you're deeply
                passionate about? If so, we invite you to become an instructor
                with Aphia Learning. Join our team of dedicated educators and
                contribute to the growth and success of learners from around the
                world.
              </Typography>
              <Grid item xs={12} md={12} lg={12}>
                <Heading variant="h6">Why Teach with Us</Heading>
                <Typography variant="body1">
                  At Aphia Learning, we believe that education is a powerful
                  tool for personal and professional growth. We are committed to
                  providing high-quality, accessible, and engaging learning
                  experiences for our students. When you become an instructor
                  with us, you become an integral part of this mission, and
                  you'll benefit in several ways:
                </Typography>
                {becomeContaints.map((becomeContaintsItem, i) => (
                  <>
                    <Typography variant="body1" mt={2}>
                      <Typography variant="span" fontWeight="bold">
                        {becomeContaintsItem.heading}{" "}
                      </Typography>
                      {becomeContaintsItem.subHeading}
                    </Typography>
                  </>
                ))}
                <Grid item xs={12} md={12} lg={12} py={2}>
                  <Heading variant="h3">Who Can Become an Instructor</Heading>
                  <Typography variant="body1">
                    At Aphia Learning, we welcome instructors from diverse
                    backgrounds and expertise. Whether you are a subject matter
                    expert, a professional in your field, or an experienced
                    teacher, you can contribute to our platform. Here's who
                    we're looking for:
                  </Typography>
                  <Typography variant="body1" mt={2}>
                    <Typography variant="span" fontWeight="bold">
                      {" "}
                      Subject Matter Experts{" "}
                    </Typography>
                    If you have deep knowledge of a particular subject or skill,
                    we encourage you to share it with our learners. Your
                    expertise can empower others to succeed.
                  </Typography>
                  <Typography variant="body1" mt={2}>
                    <Typography variant="span" fontWeight="bold">
                      Industry Professionals{" "}
                    </Typography>
                    Professionals with real-world experience are highly valued.
                    Your insights and practical knowledge can bridge the gap
                    between theory and application.
                  </Typography>
                  <Typography variant="body1" mt={2}>
                    <Typography variant="span" fontWeight="bold">
                      Experienced Educators
                    </Typography>{" "}
                    If you have a background in teaching, whether in traditional
                    classrooms or online settings, your pedagogical skills are a
                    valuable asset.
                  </Typography>
                  <Grid item xs={12} sm={12} md={12} lg={12}></Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={12} lg={4}>
              <Grid item xs={12} md={12} lg={12}>
                <img
                  style={{
                    width: "100%",
                    borderRadius: 5,
                  }}
                  src={BecomeIn}
                  alt="Lodding..."
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12} pt={{ xs: 4, sm: 2, md: 10 }}>
                <Box
                  component={"form"}
                  onSubmit={formik.handleSubmit}
                  //   boxShadow={4}
                  p={3}
                  sx={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    flexDirection: "column",
                    borderRadius: "5px",
                    height: 520,
                    border: `1px solid ${theme.palette.primary.main}`,
                    backgroundColor: "transparant",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    textAlign="center"
                    fontSize="20px"
                  >
                    Become an Instructor
                  </Typography>
                  <FormInput
                    formik={formik}
                    name="name"
                    type="text"
                    label="Full Name"
                  />
                  <FormInput
                    formik={formik}
                    name="email"
                    type="email"
                    label="Email"
                  />

                  <FormInput
                    formik={formik}
                    name="contactNumber"
                    label="Contact No."
                    type="number"
                  />

                  <FormInput
                    formik={formik}
                    name="expertise"
                    type="text"
                    label="Expertise In"
                  />
                  <FormInput
                    formik={formik}
                    name="message"
                    type="text"
                    label="Tell us about yourself"
                  />
                  <LoadingButton
                    disableElevation
                    type="submit"
                    variant="contained"
                    loading={submittingForm}
                    size="small"
                    sx={{
                      width: "40%",
                      marginBottom: "20px",
                    }}
                  >
                    Send
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};
