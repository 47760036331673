import * as React from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import EastIcon from "@mui/icons-material/East";
import { ContactCourseInfo } from "./ContactCourseInfo";
import { Typography, Button, Divider, Box } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { EnqueryBookdata } from "../../context/CourseContext";
import { theme } from "../../theme";

export const SelectForEnquery = ({
  setState,
  getEnquery,
  courseId,
  standardPrice,
  earlyBirdPrice,
  programmeName,
  handleOpen,
  courseTitle,
}) => {
  // const [openContact, setOpenContact] = React.useState(false);
  // const handleOpen = () => {
  //   setOpenContact(true);
  // };

  // const handleClose = () => {
  //   setOpenContact(false);
  // };

  const navigate = useNavigate();

  const { setEnqueryBookdata } = React.useContext(EnqueryBookdata);

  const handleSubmitBooknow = (
    earlyBirdPrice,
    getEnquery,
    standardPrice,
    programmeName
  ) => {
    //  console.log(earlyBirdPrice,getEnquery,standardPrice,programmeName,"dadaadaddadds");
    setEnqueryBookdata({
      earlyBirdPrice,
      getEnquery,
      standardPrice,
      programmeName,
      courseTitle,
    });
    navigate(`/booknow`);
  };

  // React.useEffect(()=>{
  //   setEnqueryBookdata({earlyBirdPrice,...getEnquery,standardPrice,programmeName})
  // },[]);

  function getWeekendDates(startDate, endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const weekendDates = [];

    let currentDateGetWeek = new Date(start);
    while (currentDateGetWeek <= end) {
      if (
        currentDateGetWeek.getDay() === 0 ||
        currentDateGetWeek.getDay() === 6
      ) {
        weekendDates.push(new Date(currentDateGetWeek));
      }

      currentDateGetWeek.setDate(currentDateGetWeek.getDate() + 1);
    }
    return weekendDates.map((date) => date.getDate()).join(",");
  }

  const calculatedPercentage =
    ((standardPrice - earlyBirdPrice) / standardPrice) * 100;

  console.log(getEnquery, "==getEnqueryhhhhhh");

  return (
    <>
      <Button
        onClick={() => {
          setState(false);
        }}
        startIcon={<ArrowBackIcon />}
      >
        Go Back
      </Button>

      <Typography variant="h6" mt={3}>
        {programmeName}
      </Typography>
      <Divider />
      <Box pacing={2} mt={4}>
        <Typography variant="body" mt={2} color="grey">
          DELIVERY METHOD
        </Typography>
        <Typography variant="body1">{programmeName}</Typography>
      </Box>
      <Box mt={3}>
        <Typography variant="body" color="grey">
          Days
        </Typography>
        {getEnquery ? (
          <>
            <Typography
              variant="body1"
              gap={2}
              sx={{ display: "flex", alignItems: "center" }}
            >
              {moment(getEnquery?.startDate).format("ddd, Do MMM YYYY")}{" "}
              <EastIcon />
              {moment(getEnquery?.endDate).format("ddd, Do MMM YYYY")} (
              {moment(new Date(getEnquery.endDate)).diff(
                new Date(getEnquery.startDate),
                "days"
              ) + 1}{" "}
              Days)
            </Typography>
            {/* <Typography
              variant="body1"
              gap={2}
              sx={{ display: "flex", alignItems: "center" }}
            >
              {getEnquery?.startDate} <EastIcon />
              {getEnquery?.endDate}
            </Typography> */}
          </>
        ) : (
          // <Typography
          //   variant="body1"
          //   gap={2}
          //   sx={{ display: "flex", alignItems: "center" }}
          // >
          //   {getEnquery?.startdate} <EastIcon />
          //   {getEnquery?.enddate} (4Days)
          // </Typography>
          <Typography
            variant="body1"
            gap={2}
            sx={{ display: "flex", alignItems: "center" }}
          >
            Comming Soon...!
          </Typography>
        )}
      </Box>
      <Box mt={3}>
        {getEnquery?.weekType.toLowerCase() === "weekend" && (
          <Typography variant="body" color="grey">
            Weekend Classes Date:
          </Typography>
        )}

        <Typography variant="body1">
          {getEnquery?.weekType.toLowerCase() === "weekend"
            ? getWeekendDates(getEnquery?.startDate, getEnquery?.endDate)
            : null}
        </Typography>
      </Box>

      <Box mt={3}>
        <Typography sx={{ marginTop: "20px" }} variant="body" color="grey">
          Time
        </Typography>
        <Typography variant="body1">{getEnquery?.startTime}</Typography>
      </Box>
      <Box mt={3}>
        <Typography variant="body" color="grey">
          Offer
        </Typography>
        <Typography>{calculatedPercentage.toFixed(0)}% Off</Typography>
      </Box>
      <Box my={2}>
        <Typography variant="body" color="grey">
          Price
        </Typography>
        <Typography variant="h6" color={theme.palette.primary.main}>
          <Typography variant="body2" color="gray">
            <s>₹ {standardPrice}</s>
          </Typography>
          <Typography variant="body">₹ {earlyBirdPrice}</Typography>
        </Typography>
      </Box>
      <Typography
        mt={2}
        variant="body"
        color="gray"
        textAlign="center"
        sx={{ display: "flex", alignItems: "center" }}
      >
        <ErrorOutlineIcon sx={{ marginRight: 1 }} />
        Information: Limited budget?
      </Typography>
      <Box mt={3} display="flex">
        <Button
          type="submit"
          variant="outlined"
          sx={{
            width: "40%",
            height: 50,
            objectFit: "contain",
            "&.MuiButtonBase-root": { borderRadius: "5px" },
          }}
          onClick={handleOpen}
        >
          Enquiery Now
        </Button>

        <Button
          disableElevation
          type="submit"
          variant="contained"
          sx={{
            width: "40%",
            marginLeft: 6,
            objectFit: "contain",
            "&.MuiButtonBase-root": { borderRadius: "5px" },
          }}
          onClick={() =>
            handleSubmitBooknow(
              earlyBirdPrice,
              getEnquery,
              standardPrice,
              programmeName,
              courseTitle
            )
          }
        >
          Book Now
        </Button>
      </Box>
      {/* <ContactCourseInfo
        openContact={openContact}
        handleClose={handleClose}
        courseId={courseId}
        programmeName={programmeName}
        getEnquery={getEnquery}
      /> */}
    </>
  );
};
