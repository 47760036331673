import React from "react";
import { Box, Typography, styled, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const Container = styled(Box)(() => ({
  alignItems: "center",
  display: "flex",
  flex: 1,
  justifyContent: "center",
  flexDirection: "column",
}));

function PageNotFound() {
  const navigate = useNavigate();
  const handleNavigateToHome = () => {
    navigate("/", { replace: true });
  };
  return (
    <>
      <Helmet>
        <title>Page not found - 404</title>
      </Helmet>
      <Container>
        <Typography variant="h6" sx={{ fontSize: "4rem", textAlign: "center" }}>
          Error 404{" "}
        </Typography>

        <Typography>Page not found!</Typography>
        <Button
          sx={{ margin: "10px 0" }}
          size="small"
          onClick={handleNavigateToHome}
        >
          Back to Home
        </Button>
      </Container>
    </>
  );
}

export default PageNotFound;
