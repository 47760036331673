import {
  Container,
  Grid,
  Typography,
  styled,
  Button,
  Box,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import React, { useState } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import FormInput from "../form/FormInput";
import * as Yup from "yup";
import { Form, Formik, useFormik } from "formik";
import { FormLabel } from "react-bootstrap";
import urls from "../apis/urlContants";
import { post } from "../apis/apiMethods";
import { CourseContext } from "../context/CourseContext";
import { LoadingButton } from "@mui/lab";
import { theme } from "../theme";
import ProgramNames from "../data/programmName";

const Text = styled(Typography)(() => ({
  fontSize: 17,
  // marginTop: "20px",
  fontWeight: 500,
}));

const style = {
  fontSize: 15,
  color: "grey",
  paddingLeft: "10px",
};

const Phone = styled(PhoneInput)(() => ({
  ".PhoneInputInput": {
    padding: "11px 14px",
    borderRadius: 20,
    border: "1px solid #e0e0e0",
    marginBottom: "25px",
    width: "70px",
  },
  ".PhoneInputCountry": {
    marginBottom: "20px",
  },
  ".PhoneInputInput:focus-visible": {
    outline: `1px solid ${theme.palette.primary.main}`,
    marginBottom: "25px",
  },
}));

const Onsite = ({ selectedCourse }) => {
  const [value, setValue] = useState();
  const courseId = window.localStorage.getItem("courseId");
  const [submittingForm, setSubmittingForm] = useState(false);
  const [programmeDates, setProgrammeDates] = useState([]);
  const onsiteFormik = useFormik({
    initialValues: {
      name: "",
      participant: "",
      webname: "",
      message: "",
      contactNumber: null,
      email: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required().label("Full Name").max(150),
      webname: Yup.string().required().label("Web name").max(150),
      email: Yup.string().email().required().label("Email").max(150),
      participant: Yup.string().required().label("No. of Participant"),
      message: Yup.string().required().label("Message"),
      contactNumber: Yup.number()
        .nullable()
        .required()
        .label("Contact No")
        .max(999999999999, "Invalid contact number")
        .min(1000000000, "Invalid contact number"),
    }),
    onSubmit: async (values) => {
      try {
        const body = {
          subject: ProgramNames["Onsite"],
          customerDetails: {
            name: values.name,
            contactNumber: values.contactNumber,
            email: values.email,
          },
          enquiryDetails: {
            courseId: courseId,
            programmeName: ProgramNames["Onsite"],
            programmeDetails: {
              inhouse: {
                name: values.name,
                participant: values.participant,
                webname: values.webname,
                programmeDates: programmeDates,
                contactNumber: values.contactNumber,
                email: values.email,
              },
            },
          },

          message: values.message,
        };

        setSubmittingForm(true);

        const { data } = await post(`${urls.enquiry.createForm}`, body);
        onsiteFormik.resetForm();
        setProgrammeDates([]);
      } catch (err) {
        console.error(err, "err");
      }
      setSubmittingForm(false);
    },
    validateOnBlur: false,
  });

  return (
    <>
      {/* <Container> */}
      {selectedCourse.programmeDates &&
      selectedCourse.programmeDates.includes("onsite") ? (
        <>
          <Text>
            Please Submit your requirements, our staff will be in touch with you
            to further discuss your onsite requirements.
          </Text>

          <Box
            component={"form"}
            onSubmit={onsiteFormik.handleSubmit}
            sx={{
              width: "100%",
              margin: "auto",
              fontFamily: "Inter",
            }}
          >
            <Grid container spacing={2} py={4}>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormLabel style={style}>Full Name*</FormLabel>
                <FormInput formik={onsiteFormik} name="name" type="text" />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormLabel style={style}>No of Participant*</FormLabel>
                <FormInput
                  formik={onsiteFormik}
                  name="participant"
                  type="text"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormLabel style={style}>Email *</FormLabel>
                <FormInput formik={onsiteFormik} name="email" type="email" />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormLabel style={style}>Company Website*</FormLabel>
                <FormInput formik={onsiteFormik} name="webname" type="text" />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormLabel style={style}>Message*</FormLabel>
                <FormInput formik={onsiteFormik} name="message" type="text" />
              </Grid>
              {/* <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth required size="small">
                  <FormLabel style={style}>Programme Types</FormLabel>
                  <Select
                    label="Programme Types"
                    id="demo-simple-select-filled"
                    value={programmeDates}
                    name="programmeDates"
                    size="small"
                    onChange={(e) => setProgrammeDates(e.target.value)}
                    multiple
                  >
                    <MenuItem value={"onlineInstructorLed"}>
                      {ProgramNames["Online Instructor-Led"]}
                    </MenuItem>
                    <MenuItem value={"classRoom"}>
                      {ProgramNames["Classroom"]}
                    </MenuItem>
                    <MenuItem value={"selfPacedInfo"}>
                      {ProgramNames["Online Self-Paced"]}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid> */}
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormLabel style={style}>Contact No*</FormLabel>

                <Box
                  gap={1}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Phone
                    formik={onsiteFormik}
                    name="contactNumber"
                    international
                    countryCallingCodeEditable={false}
                    defaultCountry="IN"
                  />
                  <FormInput
                    formik={onsiteFormik}
                    name="contactNumber"
                    type="number"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} mt={2.5}>
                <LoadingButton
                  disableElevation
                  type="submit"
                  variant="contained"
                  loading={submittingForm}
                  size="small"
                  sx={{
                    width: "20%",
                  }}
                >
                  Submit
                </LoadingButton>
                {/* </Box> */}
              </Grid>
            </Grid>
          </Box>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "Inter",
            textAlign: "center",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Typography sx={{ fontSize: 20 }}>
            {ProgramNames["Onsite"]} information is not available for this
            course
          </Typography>
        </Box>
      )}
      {/* </Container> */}
    </>
  );
};

export default Onsite;
