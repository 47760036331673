// import { Typography, styled } from "@mui/material";
// import React from "react";
// const Para = styled(Typography)(({ theme }) => ({
//     color: "#555",
//     lineHeight: "24px",
//     margin: "15px 0",
//     fontSize: "15px",
//     textAlign: "justify",
//   }));
// const KeyFeatures = () => {

//   return (
//     <>
//        <Para>
//         <li> Instructor led Online Training</li>
//         <li> World-Class Training Sessions from Experienced Instructors</li>
//         <li> Course completion Certificate</li>
//         <li>Digital Delegate Pack</li>
//         <li>Simulation/Mock Test</li>
//         <li> Industrial Case Studies</li>
//         <li> 120 days Online access</li>
//         <li> Post training support</li>
//         <li>15% discount on any other Course</li>
//         <li>30% discount on any Online Course.</li>
//         </Para>
//     </>
//   );
// };

// export default KeyFeatures;

import { Typography, styled } from "@mui/material";
import React from "react";
import { PmpCertificateData } from "../helpers/Commun.hellper";

const Para = styled(Typography)(({ theme }) => ({
  // color: "#555",
  lineHeight: "24px",
  margin: "15px 0",
  fontSize: "16px",
  textAlign: "justify",
  fontFamily: "Inter",
}));
const KeyFeatures = ({keyFeatures}) => {
  return (
    <>
      <Para>
          <div dangerouslySetInnerHTML={{__html: keyFeatures}} />
      </Para>
    </>
  );
};

export default KeyFeatures;
