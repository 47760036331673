import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  keyframes,
  styled,
} from "@mui/material";
import React, { useState } from "react";
import Refund from "../assets/Images/privacurefund2.jpg";
import MailIcon from "@mui/icons-material/Mail";
import DiscountIcon from "@mui/icons-material/Discount";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import FormInput from "../form/FormInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import ScrollAnimation from "react-animate-on-scroll";
import urls from "../apis/urlContants";
import { post } from "../apis/apiMethods";
import useScrollToTopOnNavigation from "../hooks/useScrollToTopNavigation";
import { LoadingButton } from "@mui/lab";
import { theme } from "../theme";
const data = [
  {
    id: "1",
    title: "Group of 3 people 10% Discount",
  },
  {
    id: "2",
    title: "Group of 5 people 15% Discount",
  },
  {
    id: "3",
    title: "Group of 10 people 20% Discount",
  },
];
const slideInFromLeftAnimation = keyframes`
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
`;

const fadeInAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const style = {
  color: "#555",
  lineHeight: "24px",
  margin: "15px",
  fontSize: "15px",
  textAlign: "justify",
};

const Querys = styled(Box)(({ theme }) => ({
  width: "50%",
  animation: `${fadeInAnimation} 2s ease-out forwards`,
  marginTop: "40px",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const Text = styled(Typography)(({ theme }) => ({
  color: "black",
  lineHeight: "24px",
  display: "flex",
  fontFamily: "Inter",
  fontSize: 30,
  fontWeight: 700,
  justifyContent: "center",
  alignItems: "center",
  letterSpacing: "2px",
  marginBottom: "20px",
}));

const Form = styled(Box)(() => ({
  padding: 20,
  borderRadius: "5px",
  marginTop: 20,
  background: "white",
  border: `5px solid ${theme.palette.primary.main}`,
}));

const Heading = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  fontWeight: "bold",
  color: "#000",
  padding: "5% 0",
  // width:"700px",
  marginTop: "10%",
  fontFamily: "Quicksand",
  [theme.breakpoints.down("sm")]: {
    marginTop: "0px",
    fontSize: "38px",
    padding: "0px",
  },
  [theme.breakpoints.down("md")]: {
    marginTop: "0px",
  },
}));

const InHouse = () => {
  useScrollToTopOnNavigation();
  const [submittingForm, setSubmittingForm] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      contactNumber: null,
      yourmessage: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required().label("Name").max(150),
      email: Yup.string().email().required().label("Email").max(150),
      contactNumber: Yup.number()
        .nullable()
        .required()
        .label("Contact Number")
        .min(1000000000, "Invalid contact number")
        .max(99999999999, "Invalid contact number"),
      yourmessage: Yup.string().required().label("Message"),
    }),
    onSubmit: async (values) => {
      try {
        const body = {
          subject: "Drop Us a Query",
          customerDetails: {
            name: values.name,
            email: values.email,
            contactNumber: values.contactNumber,
          },
          enquiryDetails: {
            others: {
              yourmessage: values.yourselef,
            },
            programmeName: "Drop Us a Query",
            programmeDetails: {},
          },
        };
        setSubmittingForm(true);
        const { data } = await post(urls.enquiry.createForm, body);
        formik.resetForm();
      } catch (error) {
        console.error(error);
      }
      setSubmittingForm(false);
    },
    validateOnBlur: false,
  });

  return (
    <>
      <Box
        sx={{
          backgroundImage:
            "url(https://www.learnfly.com/img/testimonial/teaching_banner.jpg)",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "100%",
          padding: { xs: "0 50px", sm: "0 50px", md: "0 62px" },
        }}
      >
        <Typography
          variant="h6"
          sx={{
            opacity: 0,
            animation: `${fadeInAnimation} 1s ease-out forwards`,
            animationDelay: "1s",
            fontFamily: "Quicksand",
            fontSize: { xs: "24px", sm: "36px", md: "52px" },
            color: "#fff",
            fontWeight: 700,
            marginTop: { xs: "20%", md: "10%" },
          }}
        >
          Corporate Training <br />
          (In-House Training)
        </Typography>
        <Box sx={{ marginBottom: { xs: "14%", md: "5%" } }} />
      </Box>
      <Box bgcolor="whitesmoke">
        <Container maxWidth="xl">
          <Grid
            container
            spacing={2}
            sx={{ padding: { xs: "0 0", sm: "0 0", md: "50px 50px" } }}
          >
            <Grid item xs={12} md={6} lg={6} p={2}>
              <Box
                sx={{
                  padding: "5% 0",
                  clipPath: "polygon(0% 4%, 75% 4%, 98% 50%, 75% 95%, 0% 95%)",
                  backgroundColor: theme.palette.primary.main,
                  animation: `${slideInFromLeftAnimation} 1s ease-out forwards`,
                  animationDelay: "1s",
                }}
              >
                <img
                  style={{
                    width: "100%",
                    clipPath:
                      "polygon(0% 0%, 74% 0%, 95% 50%, 74% 100%, 0% 100%)",
                  }}
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR8kYxyZtYrMbKeomWzrCmhL02QUDqZbj0M0w&usqp=CAU"
                  alt=""
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Typography
                sx={{
                  fontWeight: "bold",
                }}
              >
                We improve and transform lives through learning
              </Typography>
              <Typography>
                Whether you want to learn, upskill, implement or to share what
                you know, you have come to the right place. As a global
                destination for online learning, we empower organizations and
                individuals with flexible, value added and effective skill
                development.
              </Typography>
              <Typography>
                Aphia customize Corporate training based on client needs and has
                been the foundation of client education. Many participants learn
                best and have the greatest opportunity for retention when they
                learn from a live instructor in a classroom setting. Classroom
                training affords the opportunity to incorporate various learning
                principles, including lectures, demonstrations, hands-on
                workshops, and retention quizzes. Aphia gives you the best
                experience on our Classroom training, depending on the training
                subject, learners may thrive best in an environment where
                collaboration is easy and peer insight is bountiful. Getting
                away from the office can often be beneficial for employees: They
                can focus on learning without work distractions and also build
                collaboration and rapport with other users that can result in
                valuable knowledge sharing relationships in the future. In
                addition, participants can work with industry colleagues to
                solve problems, gain & share knowledge and even learn tips and
                tricks.
              </Typography>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Grid container>
                <Grid item xs={12} sm={8} md={9} lg={9} mt={2}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    Why Aphia?
                  </Typography>
                  <ul style={style}>
                    <li>Expert trainers and accredited courses</li>
                    <li>Innovative learning methodology</li>
                    <li>Meet and exceed industry standards</li>
                    <li>
                      Training with current relevance and Faster business growth
                    </li>
                    <li>Increase staff efficiency and productivity levels</li>
                    <li>Prevent disastrous IT errors; maintain compliances</li>
                    <li>
                      Accreditation for your employees, increasing your
                      organizational credibility
                    </li>
                  </ul>
                  <Typography sx={{ fontWeight: "bold" }}>
                    Basic requirement
                  </Typography>
                  <ol style={style}>
                    <li>Discuss with your team</li>
                    <li>Define your need area’s</li>
                    <li>Analyze and assess your current state</li>
                    <li>Develop your plan</li>
                  </ol>
                </Grid>

                <Grid item xs={12} sm={4} md={3} lg={3} mb={4}>
                  {/* <ScrollAnimation
                  animateIn="animate__slideInRight"
                  duration={2}
                  animateOnce={true}
                > */}
                  <Text>Group Discount</Text>
                  {data.map((ele, i) => (
                    <Box
                      key={i}
                      sx={{
                        p: 2,
                        border: `5px solid ${theme.palette.primary.main}`,
                        boxShadow: 5,
                        marginBottom: 2,
                        transition: "0.3s",
                        display: "flex",
                        gap: "5px",
                        animation: `${fadeInAnimation} 2s ease-out forwards`,
                      }}
                    >
                      <DiscountIcon
                        sx={{
                          marginTop: "10px",
                          color: theme.palette.primary.main,
                        }}
                      />
                      <Typography> {ele.title}</Typography>
                    </Box>
                  ))}

                  <Button
                    variant="contained"
                    sx={{
                      clipPath:
                        " polygon(8% 0%, 92% 0%, 100% 50%, 92% 100%, 8% 100%, 0% 50%)",
                      textTransform: "none",
                      fontSize: "13px",
                      width: "100%",
                    }}
                  >
                    {/* <ErrorOutlineIcon /> */}
                    Can't Find Convenient Shedule? Let Us Know
                  </Button>
                  {/* </ScrollAnimation> */}
                </Grid>

                <Grid item xs={12} md={12} lg={12} mt={2}>
                  <Typography variant="subtitle1">
                    Email us: enquiry@aphiya.com to discuss more about your
                    training needs.
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: "#555",
                      lineHeight: "24px",
                      fontSize: "15px",
                      marginTop: "15px",
                      textAlign: "justify",
                    }}
                  >
                    From Aphiya corporate training, your staff will gain the
                    ability to easily integrate new technologies into learning
                    through an experiential approach. Our trainers use real life
                    examples and case studies that explain the practical
                    applications of the training imparted.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Querys>
                {/* <ScrollAnimation
                  animateIn="animate__slideInUp"
                  duration={2}
                  animateOnce={true}
                > */}
                <Text>Drop Us a Query</Text>
                <Form
                  component={"form"}
                  onSubmit={formik.handleSubmit}
                  boxShadow={4}
                >
                  <FormInput
                    formik={formik}
                    name="name"
                    type="text"
                    label="Full Name"
                  />
                  <FormInput
                    formik={formik}
                    name="email"
                    type="email"
                    label="Email"
                  />

                  <FormInput
                    formik={formik}
                    name="contactNumber"
                    label="Contact No."
                    type="number"
                  />

                  <FormInput
                    formik={formik}
                    name="yourmessage"
                    type="text"
                    label="Your Message"
                  />
                  <LoadingButton
                    loading={submittingForm}
                    disableElevation
                    type="submit"
                    variant="contained"
                    size="small"
                    sx={{
                      width: "40%",
                    }}
                  >
                    Send
                  </LoadingButton>
                </Form>
                {/* </ScrollAnimation> */}

                <Box pb={2} />
              </Querys>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default InHouse;
