import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  keyframes,
  styled,
} from "@mui/material";
import React, { useState } from "react";
import blueGirl from "../assets/Images/blueGirl.png";
import corporate from "../assets/Images/corporate.png";
import affiliateprogram from "../assets/Images/affiliateprogram.jpg";
import smileGirl from "../assets/Images/smileGirl.png";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

import useScrollToTopOnNavigation from "../hooks/useScrollToTopNavigation";
import ContactDropusForm from "./Contact.DropusForm";

const fadeInAnimation = keyframes`
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      `;

const slideInFromLeftAnimation = keyframes`
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
`;

const style = {
  // color: "#555",
  lineHeight: "24px",
  fontSize: "15px",
  fontFamily: "inter",
};

const CorporateTraining = () => {
  useScrollToTopOnNavigation();

  return (
    <>
      <Box
        sx={{
          backgroundImage: `url(${affiliateprogram})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          height: 300,
          display: "flex",
          alignItems: "center",
          padding: { xs: "0 40px", sm: "0 40px", md: "0 80px" },
          backgroundPosition: "center",
        }}
      >
        {/* <Container maxWidth="xl"> */}
        <Typography
          variant="h6"
          sx={{
            opacity: 0,
            animation: `${fadeInAnimation} 1s ease-out forwards`,
            animationDelay: "1s",
            fontFamily: "Quicksand",
            fontSize: { xs: "24px", sm: "36px", md: "52px" },
            WebkitTextFillColor: "black",
            color: "black",
            TextShadow:
              "3px 3px 0 #000,-3px 3px 0 #000,-3px -3px 0 #000, 3px -3px 0 #000;",

            fontWeight: 700,
          }}
        >
          Corporate Training
        </Typography>
        {/* </Container> */}
      </Box>
      <Box>
        <Container maxWidth="xl">
          <Grid
            container
            spacing={2}
            py={6}
            sx={{
              padding: { xs: "20px 0", sm: "20px 0", md: "50px 50px" },
            }}
          >
            {/* <Grid item xs={12} sm={12} md={6} p={2}>
              <Box
                sx={{
                  padding: "5% 0",
                  clipPath: "polygon(0% 4%, 75% 4%, 98% 50%, 75% 95%, 0% 95%)",
                  backgroundColor: "#009974",
                  animation: `${slideInFromLeftAnimation} 1s ease-out forwards`,
                  animationDelay: "1s",
                }}
              >
                <img
                  style={{
                    width: "100%",
                    clipPath:
                      "polygon(0% 0%, 74% 0%, 95% 50%, 74% 100%, 0% 100%)",
                  }}
                  src={image5}
                  alt="Loading..."
                />
              </Box>
            </Grid> */}
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                style={{
                  width: "100%",
                  borderRadius: 5,
                }}
                src={corporate}
                alt="Loading..."
              />
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
              <Typography
                sx={{
                  fontSize: 25,
                  fontWeight: 600,
                  fontFamily: "Quicksand",
                }}
              >
                We improve and transform lives through learning
              </Typography>
              <Typography sx={style}>
                Whether you want to learn, upskill, implement or to share what
                you know, you have come to the right place. As a global
                destination for online learning, we empower organizations and
                individuals with flexible, value added and effective skill
                development.
              </Typography>
              <Typography sx={style} mt={1}>
                Aphia customize Corporate training based on client needs and has
                been the foundation of client education. Many participants learn
                best and have the greatest opportunity for retention when they
                learn from a live instructor in a classroom setting. Classroom
                training affords the opportunity to incorporate various learning
                principles, including lectures, demonstrations, hands-on
                workshops, and retention quizzes. Aphia gives you the best
                experience on our Classroom training, depending on the training
                subject, learners may thrive best in an environment where
                collaboration is easy and peer insight is bountiful.
                <Typography
                  variant="span"
                  sx={{ display: "block", fontWeight: 600 }}
                  mt={1}
                >
                  Getting away from the office can often be beneficial for
                  employees
                </Typography>
                <Typography sx={style} mt={1}>
                  They can focus on learning without work distractions and also
                  build collaboration and rapport with other users that can
                  result in valuable knowledge sharing relationships in the
                  future. In addition, participants can work with industry
                  colleagues to solve problems, gain & share knowledge and even
                  learn tips and tricks.
                </Typography>
              </Typography>
            </Grid>

            {/* <Grid item xs={12} md={6}>
              <Typography sx={style}>
                Aphia customize Corporate training based on client needs and has
                been the foundation of client education. Many participants learn
                best and have the greatest opportunity for retention when they
                learn from a live instructor in a classroom setting. Classroom
                training affords the opportunity to incorporate various learning
                principles, including lectures, demonstrations, hands-on
                workshops, and retention quizzes. Aphia gives you the best
                experience on our Classroom training, depending on the training
                subject, learners may thrive best in an environment where
                collaboration is easy and peer insight is bountiful. Getting
                away from the office can often be beneficial for employees: They
                can focus on learning without work distractions and also build
                collaboration and rapport with other users that can result in
                valuable knowledge sharing relationships in the future. In
                addition, participants can work with industry colleagues to
                solve problems, gain & share knowledge and even learn tips and
                tricks.
              </Typography>
            </Grid> */}

            {/* <Grid item xs={12} sm={12} md={12} p={2}>
              <Box>
                <img
                  style={{
                    width: "100%",
                    height: "420px",
                  }}
                  src={smileGirl}
                  alt=""
                />
              </Box>
            </Grid> */}
            <Grid item xs={12} sm={12} md={6}>
              <Typography
                sx={{
                  fontSize: 25,
                  fontWeight: 600,
                  fontFamily: "Quicksand",
                }}
              >
                Advantages of our Classroom training{" "}
              </Typography>

              <Typography sx={style} mt={1}>
                <FiberManualRecordIcon
                  style={{
                    width: "10px",
                    height: "10px",
                    color: "black",
                  }}
                ></FiberManualRecordIcon>{" "}
                Industry experts and SMEs are hands-on to give you on spot
                practical help and answer real-life questions
              </Typography>
              <Typography sx={style} mt={1}>
                <FiberManualRecordIcon
                  style={{
                    width: "10px",
                    height: "10px",
                    color: "black",
                  }}
                ></FiberManualRecordIcon>{" "}
                Opportunities to tackle subjects and questions outside our
                knowledge domain
              </Typography>
              <Typography sx={style} mt={1}>
                <FiberManualRecordIcon
                  style={{
                    width: "10px",
                    height: "10px",
                    color: "black",
                  }}
                ></FiberManualRecordIcon>{" "}
                Immediate help and support with correcting mistakes and getting
                it right
              </Typography>
              <Typography sx={style} mt={1}>
                <FiberManualRecordIcon
                  style={{
                    width: "10px",
                    height: "10px",
                    color: "black",
                  }}
                ></FiberManualRecordIcon>{" "}
                Networking with other learners, sharing experiences and social
                collaboration
              </Typography>
              <Typography sx={style} mt={1}>
                <FiberManualRecordIcon
                  style={{
                    width: "10px",
                    height: "10px",
                    color: "black",
                  }}
                ></FiberManualRecordIcon>{" "}
                Real, practical and hands on use of the best practices, tools,
                accelerators, process, methodologies and techniques
              </Typography>
              <Typography sx={style} mt={1}>
                <FiberManualRecordIcon
                  style={{
                    width: "10px",
                    height: "10px",
                    color: "black",
                  }}
                ></FiberManualRecordIcon>{" "}
                Expert facilitators who make the learning experience
                interacting, engaging, interesting, value added and enjoyable
              </Typography>
              <Typography sx={style} mt={1}>
                <FiberManualRecordIcon
                  style={{
                    width: "10px",
                    height: "10px",
                    color: "black",
                  }}
                ></FiberManualRecordIcon>{" "}
                Quickly Inspect and Adapt based on your lessons learnt
              </Typography>
              <Typography sx={style} mt={1}>
                <FiberManualRecordIcon
                  style={{
                    width: "10px",
                    height: "10px",
                    color: "black",
                  }}
                ></FiberManualRecordIcon>{" "}
                Dedicated time out of the office hours to really focus on your
                needs
              </Typography>
              <Typography sx={style} mt={1}>
                <FiberManualRecordIcon
                  style={{
                    width: "10px",
                    height: "10px",
                    color: "black",
                  }}
                ></FiberManualRecordIcon>{" "}
                Multi-sensory experience that appeals to more of your multiple
                intelligences
              </Typography>
              <Typography sx={style} mt={1}>
                <FiberManualRecordIcon
                  style={{
                    width: "10px",
                    height: "10px",
                    color: "black",
                  }}
                ></FiberManualRecordIcon>{" "}
                Valuable feedback from the trainer and others in the group.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: { sm: "center", md: "end" },
                }}
              >
                <img
                  style={{
                    width: "70%",
                    borderRadius: 5,
                  }}
                  src={smileGirl}
                  alt="Loading..."
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontFamily: "Quicksand",
                  fontSize: 25,
                }}
              >
                Join Our Learning Community Today
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Typography sx={style}>
                Discover the endless opportunities for growth and learning that
                Aphia has to offer. Start your technical learning with us and
                unlock your full potential. Whether you're pursuing a degree,
                mastering a skill, or satisfying your curiosity, we're here to
                support you every step of the way. Our Mission is to bring the
                best content to the industry and foster networking, learning and
                sharing of information, knowledge, and skills.
                <Typography
                  variant="subtitle1"
                  mt={1}
                  mb={2}
                  fontFamily="inter"
                >
                  Email us:
                  <a
                    href="mailto:support@aphialearning.com"
                    style={{ textDecoration: "none" }}
                  >
                    support@aphialearning.com
                  </a>{" "}
                  to discuss more about your training needs.
                </Typography>
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={6} display="flex">
              {/* ============================DROPUS CONTACT COMPONENTS==================== */}
              <ContactDropusForm subject="More details about Corporate training." />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Box>
                <img
                  style={{
                    borderRadius: 5,
                    width: "95%",
                  }}
                  src={blueGirl}
                  alt="Loading..."
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Typography>
                To start your new journey of learning,
                <Link to="/signup"> sign up </Link> today and be a part of the
                Aphia family.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};
export default CorporateTraining;
