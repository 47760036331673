import React from "react";
import HomePage from "../components/Homepage/HomePage";
import HomeCard from "../components/Homepage/HomeCard";
import CourseSub from "../components/Homepage/CourseSub";
import Partners from "./Partners";
import TrainingDetails from "./TrainingDetails";
import Scanner from "./Scanner";
import TrendingCourse from "./TrendingCourse";
import useScrollToTopOnNavigation from "../hooks/useScrollToTopNavigation";

export default function Home() {
  useScrollToTopOnNavigation();
  return (
    <>
      <HomePage />
      <HomeCard />
      <CourseSub />
      <TrendingCourse />
      <TrainingDetails />
      <Partners />
      <Scanner />
    </>
  );
}
