import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Grid,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect } from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import { useContext, useState } from "react";
import { EnqueryBookdata } from "../context/CourseContext";
import urls from "../apis/urlContants";
import { post } from "../apis/apiMethods";
import { theme } from "../theme";
import ProgramNames from "../data/programmName";
const selfData = [
  {
    id: 1,
    primary: "Compare and contrast the project management process groups",
  },
  { id: 2, primary: "Explain what a project is (and is not)" },
  { id: 3, primary: "Explain how to initiate a project" },
  { id: 4, primary: "Explain how to initiate a project" },
  { id: 5, primary: "Explain how to initiate a project" },
  { id: 6, primary: "Explain how to initiate a project" },
  { id: 7, primary: "Explain how to initiate a project" },
  { id: 8, primary: "Explain how to initiate a project" },
  { id: 9, primary: "Explain how to initiate a project" },
  { id: 10, primary: "Explain how to initiate a project" },
  { id: 11, primary: "Explain how to initiate a project" },
  { id: 12, primary: "Explain how to initiate a project" },
  { id: 13, primary: "Explain how to initiate a project" },
];

const Text = styled(Typography)(() => ({
  fontSize: 19,
  marginTop: -7,
}));

const OnlineSelf = ({ selectedCourse, onlineSelfPaced, courseTitle }) => {
  const { setEnqueryBookdata } = useContext(EnqueryBookdata);
  const [enrolling, setEnrolling] = useState(false);

  const navigate = useNavigate();

  // useEffect(()=>{setEnqueryBookdata(onlineSelfPaced,courseTitle)},[])
  // console.log(selectedCourse, "onlineInstructor");
  // console.log(onlineSelfPaced, "onlineSelfPaced");
  const handleEnroll = async () => {
    setEnrolling(true);
    try {
      const body = {
        itemDetails: {
          courseId: selectedCourse._id,
        },
      };
      const { data } = await post(urls.payment.order, body);
      // window.localStorage.setItem(
      //   "user-customer-webspruce",
      //   JSON.stringify(data.result)
      // );
      setEnrolling(false);
      toast.success("Course enrolled successfully!");
      // navigate("/my-courses");
    } catch (error) {
      console.log(error);
    }
    setEnrolling(false);
  };
  const handleSubmit = (onlineSelfPaced, courseTitle, selectedCourse) => {
    if (selectedCourse.isPaid == true) {
      setEnqueryBookdata({ ...onlineSelfPaced, courseTitle });
      navigate("/booknow");
    } else {
      navigate("/login");
    }
  };

  return (
    <>
      <Container>
        {selectedCourse.programmeDates &&
        selectedCourse.programmeDates.includes("selfPacedInfo") ? (
          <Grid container>
            <Grid
              item
              md={12}
              sx={{
                margin: "20px auto",
              }}
            >
              <Card
                variant="outlined"
                sx={{
                  textAlign: "center",
                  width: 300,
                  margin: "auto",
                  // padding: "20px 0",
                }}
              >
                {selectedCourse.isPaid == false ? (
                  <CardContent>
                    <Typography
                      variant="h5"
                      fontWeight={600}
                      color={`${theme.palette.primary.main}`}
                    >
                      FREE
                    </Typography>
                    <Typography variant="body2">Courses for you</Typography>
                  </CardContent>
                ) : (
                  <CardContent>
                    <Typography variant="body1" color="text.secondary">
                      Course Fee
                    </Typography>
                    <Typography
                      variant="body"
                      component="div"
                      mt={2}
                      sx={{ display: "flex", flexDirection: "column" }}
                    >
                      <s>INR {onlineSelfPaced?.standardPrice}</s>
                      <a style={{ fontSize: "22px", fontWeight: "bold" }}>
                        INR {onlineSelfPaced?.earlyBirdPrice}
                      </a>
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: "gray", fontSize: "12px" }}
                    >
                      *Applicable taxes may apply
                    </Typography>
                  </CardContent>
                )}
                <LoadingButton
                  variant="contained"
                  loading={enrolling}
                  sx={{
                    mb: 2,
                    mt: 2,
                    fontFamily: "Inter",
                  }}
                  onClick={() =>
                    handleSubmit(onlineSelfPaced, courseTitle, selectedCourse)
                  }
                >
                  Enroll Now
                </LoadingButton>
                {selectedCourse.isPaid == true ? (
                  <Typography variant="caption" display="block" color="#c91114">
                    Hurry up! Deal Ends in{" "}
                    {moment(onlineSelfPaced?.earlyBirdEndDate).format(
                      "Do MMM YYYY"
                    )}
                    .
                  </Typography>
                ) : (
                  ""
                )}

                <Box sx={{ backgroundColor: "#fbc02d" }}>
                  <Typography variant="body1" component="div" mt={2} mb={2}>
                    Complete the Course to take Certificate
                  </Typography>
                </Box>
              </Card>
            </Grid>
          </Grid>
        ) : (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              fontFamily: "Inter",
              textAlign: "center",
              flexDirection: { xs: "column", md: "row" },
              marginTop: "40px",
            }}
          >
            <Typography sx={{ fontSize: 20 }}>
              {ProgramNames["Online Self-Paced"]} information is not available
              for this course
            </Typography>
          </Box>
        )}
      </Container>
    </>
  );
};

export default OnlineSelf;
