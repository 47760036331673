import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  keyframes,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import girlLaptop from "../assets/Images/girlLaptop.png";
import Aphia from "../assets/Images/aphia-n.png";
import aphiaOnline from "../assets/Images/aphiaOnline.png";
import smileGirl from "../assets/Images/smileGirl.png";
import students from "../assets/Images/onpoint.png";
import onlinetraining from "../assets/Images/mainhome.jpg";
import smilegirl from "../assets/Images/a-1.jpg";
import girl from "../assets/Images/a-2.jpg";
import imgAI from "../assets/Images/imgAI.png";
import whiteSirt from "../assets/Images/whiteSirt.png";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import useScrollToTopOnNavigation from "../hooks/useScrollToTopNavigation";
import { theme } from "../theme";

const slideInFromLeftAnimation = keyframes`
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
`;

const fadeInAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const style = {
  lineHeight: "24px",
  // marginTop: "10px",
  fontFamily: "Inter",
  fontSize: "15px",
};

const Querys = styled(Box)(({ theme }) => ({
  width: "50%",
  animation: `${fadeInAnimation} 2s ease-out forwards`,
  marginTop: "40px",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const Text = styled(Typography)(({ theme }) => ({
  // color: "#555",
  lineHeight: "24px",
  fontFamily: "Inter",
  fontSize: 15,
  marginTop: "45px",
  textIndent: 50,
  "::first-letter": {
    color: "Black",
    fontSize: "25px",
    fontWeight: 600,
  },
}));

const FormText = styled(Typography)(({ theme }) => ({
  color: "black",
  lineHeight: "24px",
  display: "flex",
  fontFamily: "Inter",
  fontSize: 30,
  fontWeight: 700,
  justifyContent: "center",
  alignItems: "center",
  letterSpacing: "2px",
  marginBottom: "35px",
}));

const Form = styled(Box)(() => ({
  padding: 20,
  borderRadius: "5px",
  marginTop: 20,
  background: "white",
  border: `5px solid ${theme.palette.primary.main}`,
}));
const OnlineTraining = () => {
  useScrollToTopOnNavigation();

  return (
    <>
      <Box
        sx={{
          backgroundImage: `radial-gradient(at bottom left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.0)),url(${onlinetraining})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "100%",
          padding: { xs: "100px 0", sm: "100px 0", md: "170px 50px" },
        }}
      >
        <Container maxWidth="xl">
          <Typography
            variant="h6"
            sx={{
              opacity: 0,
              animation: `${fadeInAnimation} 1s ease-out forwards`,
              animationDelay: "1s",
              fontFamily: "Quicksand",
              fontSize: "2.5rem",
              color: "#fff",
              fontWeight: 700,
              fontSize: { xs: "24px", sm: "36px", md: "52px" },
            }}
          >
            Online Training
          </Typography>
        </Container>
      </Box>

      <Box>
        <Container maxWidth="xl">
          <Grid
            container
            spacing={3}
            sx={{
              padding: { xs: "20px 0", sm: "20px 0", md: "50px 50px" },
            }}
          >
            <Grid item xs={12} sm={12} md={12}>
              <Typography
                variant="body"
                sx={{ fontWeight: 600, fontSize: 25, fontFamily: "Quicksand" }}
              >
                We share knowledge with the world
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              sx={{
                display: { xs: "none", md: "flex" },
                justifyContent: "center",
              }}
            >
              <img
                style={{
                  borderRadius: 5,
                  width: 300,
                  objectFit: "cover",
                }}
                src={smilegirl}
                alt="Loading..."
                loading="lazy"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={9}>
              <Text>
                Education is root for life. In today’s challenging business
                world, take a chance to stand out with exceptional skills,
                knowledge in a global market by pursuing ‘Online Programme and
                Certification Courses’ from Aphia's learning App, designed
                especially for individuals seeking to enhance their strategic,
                personal and professional intelligence. The curriculum is
                created by Industry experts and SMEs, and it is taught by
                competent faculty / facilitator, ensuring that students receive
                the highest quality education.
              </Text>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              sx={{
                display: { md: "none", xs: "flex" },
                justifyContent: "center",
              }}
            >
              <img
                style={{
                  borderRadius: 5,
                  width: 300,
                  objectFit: "cover",
                }}
                src={smilegirl}
                alt="Loading..."
                loading="lazy"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={9}>
              <Text>
                Whether you are dealing with your daily busy office schedule or
                not able to take out the extra time from your college and
                office, you can still upskill yourself with Aphia. Access latest
                topics anytime, anywhere from any device on the move. You really
                don't have to attend the physical classroom.
              </Text>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                style={{
                  borderRadius: 5,
                  width: 300,
                  objectFit: "cover",
                }}
                src={girl}
                alt="Loading..."
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <Text>
                Welcome to Aphia, where knowledge meets innovation. Our online
                learning app is designed to revolutionize how you learn, acquire
                and implement new skills. Whether you're a student striving for
                academic success, a professional looking to upskill, or someone
                eager to explore and implement new interests, we're here to
                empower your educational journey. Aphia is a leading training
                and Support Services provider, enabling learners across the
                globe by providing various employable digital skills, SAP, SAP
                S4HANA, Cloud Base SAP Module courses, RPA (Robotics Process
                Automation), Quality Management, Business Management, Agile
                Management, Project Management, Personal Development, Soft
                Skills Development, Change Management, Master Class (Industry /
                Technology / Domain / Customized organization specific),
                Transition of Freshers into IT, Competitive Exams and Other
                niche skills business value added Courses, through innovative
                practical approaches for fulfilling market demands and get
                employment to youth generation with practical skill sets under
                guidance of real time industry experts.
              </Text>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <img
                style={{
                  borderRadius: 5,
                  objectFit: "cover",
                  width: "100%",
                }}
                src={Aphia}
                alt="Loading..."
              />
            </Grid>

            <Grid item xs={12} sm={12} md={7} mb={3}>
              <Text mt={-4}>
                At Aphia, our mission is to make learning accessible, engaging,
                and effective for everyone. We believe that education is the key
                to personal and professional growth. With our online learning
                app, we aim to bridge the gap between traditional and digital
                learning, ensuring that you can learn at your own pace, in your
                own space, and with the support you need. We transform
                consultants, leaders and students by empowering via business
                value added digital skills. All sessions contain an introductory
                theoretical overview and with real time industry specific
                scenarios.
              </Text>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: 25,
                  fontFamily: "Quicksand",
                  mt: 2,
                }}
              >
                Improving and transforming lives through learning
              </Typography>
              <Typography fontFamily="Inter">
                Whether you want to learn, upskill, implement or to share what
                you know, you have come to the right place. As a global
                destination for online learning, we empower organizations and
                individuals with flexible, value added and effective skill
                development.
              </Typography>
            </Grid>
            {/* <Grid item xs={12} sm={12} md={12} mt={2}></Grid> */}
            <Grid item xs={12} sm={12} md={5}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: { md: "end", xs: "center" },
                }}
              >
                <img
                  style={{
                    borderRadius: 5,
                    width: "90%",
                    objectFit: "contain",
                  }}
                  src={students}
                  alt=""
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={12} mt={2}>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: 25,
                  fontFamily: "Quicksand",
                  mt: 2,
                }}
              >
                We help organizations of all types and sizes prepare for the
                path ahead
              </Typography>
              <Typography sx={style}>
                Wherever it leads. Our curated collection of business and
                technical courses helps companies, governments, and nonprofits
                go further by placing learning at the canter of their
                strategies. Our strategic consultancy services provide expert
                advice and training for organizations to upskill their employees
                and encourage change through learning in the workplace. We can
                help you identify training needs and create bespoke programs for
                training, to be delivered by either our training specialists or
                staff from your own department.
              </Typography>
            </Grid>
            {/* <Grid item xs={12} sm={12} md={12} mt={2}>
              <Typography sx={{ fontWeight: 600, fontSize: 25,fontFamily:"Quicksand" }}>
                Access thousands of online courses. Learn any topic, anytime.
              </Typography>
            </Grid> */}

            <Grid item xs={12} sm={12} md={12} mt={2}>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: 25,
                  fontFamily: "Quicksand",
                }}
              >
                Learn at your own pace from anywhere in the world at any time{" "}
              </Typography>
              <Typography sx={style}>
                Pause & play the lectures videos while learning online.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} mt={2}>
              <Typography
                sx={{ fontWeight: 600, fontSize: 25, fontFamily: "Quicksand" }}
              >
                Access courses on any device{" "}
              </Typography>
              <Typography sx={style}>
                Learn the topic on PC, mobile, Laptop, Tab or any smart device.{" "}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} mt={2}>
              <Typography
                sx={{ fontWeight: 600, fontSize: 25, fontFamily: "Quicksand" }}
              >
                High quality on-demand content{" "}
              </Typography>
              <Typography sx={style}>
                Experience the high-quality adaptive Tutorials and video
                content.{" "}
              </Typography>
            </Grid>

            {/* <Grid item xs={12} sm={12} md={6} lg={6} p={2}>
              <Box>
                <img
                  style={{
                  borderRadius: 5,
                    width: "80%",
                    height: "340px",
                  }}
                  src={image5}
                  alt=""
                />
              </Box>
            </Grid> */}
            {/* <Grid item xs={12} sm={12} md={12}>
              <Typography sx={{ fontWeight: "bold" }}>
                Access thousands of online courses. Learn any topic, anytime:
              </Typography>
            </Grid> */}
            {/* <Grid item xs={12} sm={12} md={6} lg={6} p={2}>
              <Box>
                <img
                  style={{
                  borderRadius: 5,
                    width: "80%",
                    height: "340px",
                  }}
                  src={image6}
                  alt=""
                />
              </Box>
            </Grid> */}
            {/* <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography sx={{ fontWeight: "bold" }}>
                Learn at your own pace from anywhere in the world at any time:
              </Typography>
              <Typography sx={style}>
                Pause & play the lectures videos while learning online.
              </Typography>
              <Typography sx={{ fontWeight: "bold" }}>
                Access courses on any device:
              </Typography>
              <Typography sx={style}>
                Learn the topic on PC, mobile, Laptop, Tab or any smart device.
              </Typography>
              <Typography sx={{ fontWeight: "bold" }}>
                High quality on-demand content:
              </Typography>
              <Typography sx={style}>
                Experience the high-quality adaptive Tutorials and video
                content.
              </Typography>
            </Grid> */}
            <Grid item xs={12} sm={12} md={12} mt={2}>
              <Typography
                sx={{ fontWeight: 600, fontSize: 25, fontFamily: "Quicksand" }}
              >
                Enjoy smooth streaming of on-demand video lectures today from
                top instructors
              </Typography>
              <Typography sx={style}>
                subjects like SAP, SAP S4HANA, Cloud Base SAP Module courses,
                RPA (Robotics Process Automation), Quality Management, Business
                Management, Agile Management, Project Management, Personal
                Development, Soft Skills Development, Change Management & more.
              </Typography>
            </Grid>
            {/* <Grid item xs={12} sm={12} md={6} lg={6} p={2}>
              <Box>
                <img
                  style={{
                  borderRadius: 5,
                    width: "80%",
                    height: "340px",
                  }}
                  src={image7}
                  alt=""
                />
              </Box>
            </Grid> */}
            <Grid item xs={12} sm={12} md={6} lg={6} mt={1}>
              <Typography sx={{ fontWeight: "bold", fontFamily: "inter" }}>
                Upskill your team with Aphia Business
              </Typography>
              <Typography sx={style} mt={1}>
                <FiberManualRecordIcon
                  style={{
                    width: "10px",
                    height: "10px",
                    color: "black",
                  }}
                ></FiberManualRecordIcon>{" "}
                Unlimited access to top Aphia courses on successful
              </Typography>
              <Typography sx={style} mt={1}>
                <FiberManualRecordIcon
                  style={{
                    width: "10px",
                    height: "10px",
                    color: "black",
                  }}
                ></FiberManualRecordIcon>{" "}
                registration, anytime, anywhere International course collection
              </Typography>
              <Typography sx={style} mt={1}>
                <FiberManualRecordIcon
                  style={{
                    width: "10px",
                    height: "10px",
                    color: "black",
                  }}
                ></FiberManualRecordIcon>{" "}
                Top certifications in tech and business.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} mt={1}>
              <Typography sx={{ fontWeight: "bold", fontFamily: "inter" }}>
                Browse thousands of Online Courses:
              </Typography>
              <Typography sx={style} mt={1}>
                <FiberManualRecordIcon
                  style={{
                    width: "10px",
                    height: "10px",
                    color: "black",
                  }}
                ></FiberManualRecordIcon>{" "}
                Track your real-time Learning Journey Code & Learn within the
              </Typography>
              <Typography sx={style} mt={1}>
                <FiberManualRecordIcon
                  style={{
                    width: "10px",
                    height: "10px",
                    color: "black",
                  }}
                ></FiberManualRecordIcon>{" "}
                platform Interactive Quizzes and Assignments Real Time Chat with
              </Typography>
              <Typography sx={style} mt={1}>
                <FiberManualRecordIcon
                  style={{
                    width: "10px",
                    height: "10px",
                    color: "black",
                  }}
                ></FiberManualRecordIcon>{" "}
                Instructors Get Certificate of Course Completion.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} mt={5}>
              <Typography
                sx={{ fontWeight: 600, fontSize: 25, fontFamily: "Quicksand" }}
              >
                Aphia for Teams
              </Typography>
              <Typography fontFamily="Inter">
                Set your team up for success with reimagined learning to empower
                their personal and professional growth. Introducing the ultimate
                learning solution designed specifically for the modern
                workforce. Our platform offers a wide range of courses,
                workshops, and certifications that are tailored to meet the
                needs of your employees. With a user-friendly interface and
                interactive learning modules, your staff can quickly acquire new
                skills and knowledge.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} mt={2}>
              <Typography
                sx={{ fontWeight: 600, fontSize: 25, fontFamily: "Quicksand" }}
              >
                Fortune 500 companies trust Aphia
              </Typography>
              <Typography fontFamily="Inter">
                Aphia is a preferred training partner for Global Fortune 500
                Companies, Government Bodies & Institutions.
                <Typography
                  variant="body1"
                  component="span"
                  sx={{
                    display: "block",
                    paddingTop: 2,
                    paddingBottom: 2,
                  }}
                >
                  We just keep growing!
                </Typography>
                Our global community and our course catalogue get bigger every
                day. Aphia's Online training is generally self-paced and
                customizable to suit an individual’s specific learning needs.
                Therefore, online training can be conducted at almost any time
                and place, provided there is a computer with high-speed internet
                access. This makes this form of training convenient for the
                user, who can modify their training to fit into their day-to-day
                schedule.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} mt={2}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: { xs: "center", sm: "center", md: "end" },
                }}
              >
                <img
                  style={{
                    borderRadius: 5,
                    width: "65%",
                  }}
                  src={imgAI}
                  alt="Loading..."
                />
              </Box>
            </Grid>
            {/* <Grid item xs={12} sm={12} md={12} p={2}>
              <Box>
                <img
                  style={{
                  borderRadius: 5,
                    width: "100%",
                    objectFit: "cover",

                    height: "420px",
                  }}
                  src={whiteSirt}
                  alt=""
                />
              </Box>
            </Grid> */}
            <Grid item xs={12} sm={12} md={6}>
              <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
                <img
                  style={{
                    borderRadius: 5,
                    width: "70%",
                    objectFit: "cover",
                  }}
                  src={whiteSirt}
                  alt="Loading..."
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Typography
                sx={{ fontWeight: 600, fontSize: 25, fontFamily: "Quicksand" }}
              >
                Key features
              </Typography>

              <Typography sx={style} mt={1}>
                <FiberManualRecordIcon
                  style={{
                    width: "10px",
                    height: "10px",
                    color: "black",
                  }}
                ></FiberManualRecordIcon>{" "}
                Quality Course Material which covers In-depth Subject Knowledge
                and Assistance after Training also
              </Typography>
              <Typography sx={style} mt={1}>
                <FiberManualRecordIcon
                  style={{
                    width: "10px",
                    height: "10px",
                    color: "black",
                  }}
                ></FiberManualRecordIcon>{" "}
                Instructor led Online Training
              </Typography>
              <Typography sx={style} mt={1}>
                <FiberManualRecordIcon
                  style={{
                    width: "10px",
                    height: "10px",
                    color: "black",
                  }}
                ></FiberManualRecordIcon>{" "}
                World-Class Training Sessions from Experienced Instructors
              </Typography>
              <Typography sx={style} mt={1}>
                <FiberManualRecordIcon
                  style={{
                    width: "10px",
                    height: "10px",
                    color: "black",
                  }}
                ></FiberManualRecordIcon>{" "}
                Course completion Certificate
              </Typography>
              <Typography sx={style} mt={1}>
                <FiberManualRecordIcon
                  style={{
                    width: "10px",
                    height: "10px",
                    color: "black",
                  }}
                ></FiberManualRecordIcon>{" "}
                Digital Delegate Pack
              </Typography>
              <Typography sx={style} mt={1}>
                <FiberManualRecordIcon
                  style={{
                    width: "10px",
                    height: "10px",
                    color: "black",
                  }}
                ></FiberManualRecordIcon>{" "}
                Simulation/Mock Test
              </Typography>
              <Typography sx={style} mt={1}>
                <FiberManualRecordIcon
                  style={{
                    width: "10px",
                    height: "10px",
                    color: "black",
                  }}
                ></FiberManualRecordIcon>{" "}
                Industrial Case Studies
              </Typography>
              <Typography sx={style} mt={1}>
                <FiberManualRecordIcon
                  style={{
                    width: "10px",
                    height: "10px",
                    color: "black",
                  }}
                ></FiberManualRecordIcon>{" "}
                Unlimited Online access
              </Typography>
              <Typography sx={style} mt={1}>
                <FiberManualRecordIcon
                  style={{
                    width: "10px",
                    height: "10px",
                    color: "black",
                  }}
                ></FiberManualRecordIcon>{" "}
                Post training support
              </Typography>
              <Typography sx={style} mt={1}>
                <FiberManualRecordIcon
                  style={{
                    width: "10px",
                    height: "10px",
                    color: "black",
                  }}
                ></FiberManualRecordIcon>{" "}
                15% discount on any other Course
              </Typography>
              <Typography sx={style} mt={1}>
                <FiberManualRecordIcon
                  style={{
                    width: "10px",
                    height: "10px",
                    color: "black",
                  }}
                ></FiberManualRecordIcon>{" "}
                30% discount on any Online Course
              </Typography>
              <Typography sx={style} mt={1}>
                <FiberManualRecordIcon
                  style={{
                    width: "10px",
                    height: "10px",
                    color: "black",
                  }}
                ></FiberManualRecordIcon>{" "}
                100% Passing Guarantee
              </Typography>
              <Typography sx={style} mt={1}>
                <FiberManualRecordIcon
                  style={{
                    width: "10px",
                    height: "10px",
                    color: "black",
                  }}
                ></FiberManualRecordIcon>{" "}
                100% Job Guarantee
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <Typography
                sx={{ fontWeight: 600, fontSize: 25, fontFamily: "Quicksand" }}
              >
                E-Learning Access Includes
              </Typography>
              <Typography sx={style}>
                Sample Exams, Recorded Webinars etc. Our Accredited
                Trainers/Consultants have vast Knowledge and Experience on
                Multiple frameworks. Our domain experts at Aphia have
                collaborated and worked with corporates like IBM, Accenture,
                TCS, HP, Infosys, Dell, Microsoft, Oracle, Tech Mahindra, E&Y,
                PWC, Deloitte, KPMG, L&T, Bank of America, Standard Chartered
                Bank, Wipro, Satya Paul, Airtel, Ranbaxy, Vodafone, Glenmark,
                Bank of Baroda, Bombay Dyeing Ltd, NIIT, Modi Xerox, HDFC Bank,
                Viom Networks Ltd (Tata Group subsidiary), Corporation Bank,
                etc. and leading names in the Academia like Amity University –
                Noida , IMT- Ghaziabad, Bennett University – Greater Noida, GD
                Goenka World Institute – in collaboration with Lancaster
                University, UK, etc.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};
export default OnlineTraining;
