import React, { useState } from "react";
import { RiWhatsappFill } from "react-icons/ri";
import FormInput from "../form/FormInput";
import { useFormik } from "formik";
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  styled,
} from "@mui/material";
import * as Yup from "yup";
import { post } from "../apis/apiMethods";
import urls from "../apis/urlContants";
import { ToastContainer, toast } from "react-toastify";
import useScrollToTopOnNavigation from "../hooks/useScrollToTopNavigation";
import { LoadingButton } from "@mui/lab";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
const Heading = styled(Typography)(() => ({
  fontSize: " 32px",
  fontWeight: 500,
  color: "#000",
  marginBottom: "15px",
}));

const Whatsapp = styled(RiWhatsappFill)(() => ({
  fontSize: "50px",
  color: "#4caf50",
  marginTop: "1px",
}));

const Content = styled(Typography)(() => ({
  fontWeight: 600,
  color: "#000",
  margin: "10px 0",
}));

const Subcontent = styled("span")(() => ({
  fontWeight: 300,
  fontSize: "15px",
}));

const Contact = () => {
  useScrollToTopOnNavigation();
  const [submittingForm, setSubmittingForm] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      contactNumber: "",
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required().label("Name").max(150),
      email: Yup.string().email().required().label("Email").max(150),
      contactNumber: Yup.number()
        .default(null)
        .nullable()
        .required()
        .label("Contact Number")
        .min(1000000000, "Invalid contact number")
        .max(999999999999, "Invalid contact number"),
      message: Yup.string().required().label("Message"),
    }),
    onSubmit: async (values, { resetForm, setFieldValue }) => {
      try {
        const body = {
          subject: "Contact",
          customerDetails: {
            name: values.name,
            email: values.email,
            contactNumber: values.contactNumber,
          },
          enquiryDetails: {
            // programmeName: "",
            programmeDetails: {},
          },
          message: values.message,
        };
        setSubmittingForm(true);
        const { data } = await post(`${urls.enquiry.createForm}`, body);
        resetForm();
      } catch (error) {
        console.error(error);
      }
      setSubmittingForm(false);
    },
    validateOnBlur: false,
    enableReinitialize: true,
  });

  return (
    <Box bgcolor="whitesmoke">
      <Container maxWidth="xl">
        <Grid
          container
          sx={{
            padding: { xs: "0 0", sm: "0 0", md: "50px 50px" },
          }}
          my={2}
        >
          <Grid item xs={12} md={6}>
            <Grid item xs={12} md={12}>
              <Heading variant="h1">How To Contact Us?</Heading>
            </Grid>
            <Grid item xs={12} md={12} mt={3}>
              <Content>
                Email:
                <Subcontent>
                  <a
                    href="mailto:support@aphialearning.com"
                    style={{ textDecoration: "none" }}
                  >
                    support@aphialearning.com
                  </a>{" "}
                </Subcontent>
              </Content>
              {/* <Content>
                Call: <Subcontent>+91 1234567890</Subcontent>
              </Content> */}
            </Grid>
            {/* <Grid item xs={12} md={12} mt={3}>
              <Content>Or Chat with our staff:</Content>
              <Box display="flex" mt={3}>
                <Whatsapp />
                <Typography fontWeight='bold'>
                    Whatsapp
                  </Typography>
              </Box>
            </Grid> */}
            <Grid item xs={12} md={6} mt={3}>
              {/* <Content>Business Locations & Address:</Content> */}
              <Box>
                <Content>
                  Address:{" "}
                  {/* <Subcontent>
                    Aphia Learning Private Limited, 29 Sakharitola Street,
                    Intally,Kolkata,Kolkata-700014,West Bengal
                  </Subcontent> */}
                  <Subcontent>
                    Aphia Learning Private Limited Kolkata, West Bengal, India.{" "}
                  </Subcontent>
                </Content>
                <Content>
                  Business hours:{" "}
                  <Subcontent>Mon-Fri 9am to 6:30pm </Subcontent>{" "}
                </Content>
              </Box>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            sx={{ marginTop: { xs: "20px", sm: "20px", md: "0" } }}
          >
            <Box
              sx={{
                background: "#fff",
                p: 4,
                boxShadow: 2,
                borderRadius: "5px",
              }}
            >
              <Typography variant="h6" mb={2}>
                Fill the form
              </Typography>
              <FormInput
                formik={formik}
                name="name"
                type="text"
                label="Full Name"
              />
              <FormInput
                formik={formik}
                name="email"
                type="email"
                label="Email"
              />

              <FormInput
                formik={formik}
                name="contactNumber"
                label="Contact No."
                type="number"
              />

              <FormInput
                formik={formik}
                name="message"
                type="text"
                label="Your Message"
              />
              <LoadingButton
                disableElevation
                variant="contained"
                loading={submittingForm}
                size="small"
                sx={{
                  width: "40%",
                }}
                onClick={formik.handleSubmit}
              >
                Send
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Contact;
