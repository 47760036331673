import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Container, Stack, TextField, styled } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
// import homepageimages from "../../assets/Images/homepageimage.png";
import homepageimage from "../../assets/Images/mainhome.jpg";
import { Send } from "@mui/icons-material";
import { useState } from "react";
import { useEffect } from "react";
import urls from "../../apis/urlContants";
import { get } from "../../apis/apiMethods";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { CourseContext } from "../../context/CourseContext";
import Select, { components } from "react-select";
import { keyframes } from "@emotion/react";
import { theme } from "../../theme";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "transparent",
  padding: "40px 0",
  // marginRight: theme.spacing(2),
  // marginLeft: 0,
  width: "65%",

  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(2, 2, 2, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const TextHeading = styled(Typography)(({ theme }) => ({
  margin: "1rem 0",
  fontWeight: 700,
  color: "#fff",
  // WebkitTextFillColor: "black",
  // color: "black",
  TextShadow:
    "3px 3px 0 #000,-3px 3px 0 #000,-3px -3px 0 #000, 3px -3px 0 #000;",

  letterSpacing: "2px",
  fontFamily: "Quicksand",
}));

const Para = styled(Typography)(({ theme }) => ({
  margin: "1rem 0",
  fontWeight: "bolder",
  color: "#fff",
}));

const DiscoverButton = styled(Button)(({ theme }) => ({
  borderLeft: "1px solid #009974",
  borderTopLeftRadius: "1px",
  borderBottomLeftRadius: "1px",
  textTransform: "none",
}));

const slideInFromLeftAnimation = keyframes`
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
`;

const fadeInAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const Heading = () => {
  return (
    <>
      <Box bgcolor={theme.palette.primary.main} p="4px 10px">
        <Container>
          <Typography
            variant="h6"
            sx={{
              flexGrow: 1,
              textAlign: "center",
              fontSize: { xs: "12px", sm: "12px", md: "14px" },
              color: "#ffff",
              fontFamily: "Inter",
            }}
          >
            Get Discount of 10% On All Our Video Courses For a Limited Time
          </Typography>
        </Container>
      </Box>
    </>
  );
};

export default function HomePage() {
  const [searchData, setSearchData] = useState([]);
  const [searchList, setSearchList] = useState([]);
  const navigate = useNavigate();
  const { setCourseId } = useContext(CourseContext);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      // color: "black",
      borderRadius: "25px",
      display: "flex",
      minWidth: "200px",
      // marginLeft: "15px",
    }),
    input: (provided) => ({
      ...provided,
      flex: 2,
    }),
    menu: (provided, state) => ({
      ...provided,
      color: "black",
    }),
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <SearchIcon style={{ color: "gray" }} />
        <Button
          variant="contained"
          color="primary"
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            height: "100%",
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            textTransform: "capitalize",
          }}
        >
          Discover Courses
        </Button>
      </components.DropdownIndicator>
    );
  };

  useEffect(() => {
    const searchHandler = async () => {
      try {
        const res = await get(`${urls.course.courseNameList}`);

        setSearchList(
          res.data.result.data.map((course) => ({
            label: course.title,
            value: course._id,
          }))
        );

        setSearchData(
          res.data.result.data.map((course) => ({
            label: course.title,
            value: course._id,
          }))
        );
      } catch (error) {}
    };

    searchHandler();
  }, []);

  const filterCourseHandler = (event) => {
    const filterList = searchList.filter((item) =>
      item.label.toLowerCase().includes(event.toLowerCase())
    );
    setSearchData(filterList);
  };

  const handleSearchClick = (options) => {
    const selectedCourse = searchList.find(
      (course) => course.value === options.value
    );

    if (selectedCourse) {
      window.localStorage.setItem("courseId", selectedCourse.value);
      setCourseId(selectedCourse.value);
      navigate("/all-classes", { state: { from: "selected" } });
    }
  };

  return (
    <>
      <Heading />
      <Box
        sx={{
          backgroundImage: `radial-gradient(at bottom left, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.0)), url(${homepageimage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "100%",
          height: "77vh",
          padding: { xs: "0 0", sm: "0 0", md: "0 50px" },
        }}
      >
        <Container maxWidth="xl">
          <Box
            sx={{
              marginTop: { xs: "20%", md: "10%", lg: "6%" },
              textAlign: { xs: "start", md: "left" },
              maxWidth: { xs: "100%", sm: "100%", md: "100%", lg: "80%" },
            }}
          >
            <TextHeading
              variant="h3"
              component="h3"
              sx={{
                textAlign: { xs: "left", md: "left" },
                fontSize: { xs: "40px", sm: "50px", md: "50px" },
                animation: `${fadeInAnimation} 2s ease-out forwards`,
              }}
            >
              Simple and
              <Typography variant="span" sx={{ color: "#146213" }}>
                {" "}
                Effective <br />
              </Typography>
              Accredited Training <br />
              <Typography variant="span" sx={{ color: "#146213" }}>
                Courses
              </Typography>
            </TextHeading>
            <Para
              variant="body1"
              mt={2}
              sx={{
                fontWeight: 400,
                letterSpacing: "1.5px",
                fontSize: { xs: "14px", sm: "14px", md: "16px" },
                fontFamily: "Inter",
                textAlign: { xs: "left", md: "left" },
                animation: `${fadeInAnimation} 2s ease-out forwards`,
              }}
            >
              Aphia is a Global training provider based across the India that
              <br />
              specialises in training courses
            </Para>
            {/* =======search button home start */}
            <Search>
              <Select
                type="text"
                inputProps={{ "aria-label": "search" }}
                options={searchData}
                placeholder="Find a Course"
                onInputChange={filterCourseHandler}
                isSearchable
                styles={customStyles}
                components={{ DropdownIndicator }}
                onChange={handleSearchClick}
              />
            </Search>
            {/* =======================end */}
          </Box>
        </Container>
      </Box>
    </>
  );
}
