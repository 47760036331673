import * as React from "react";
import {
  Box,
  Button,
  Modal,
  Typography,
  FormLabel,
  styled,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormInput from "../../form/FormInput";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Stack } from "react-bootstrap";
import { post } from "../../apis/apiMethods";
import urls from "../../apis/urlContants";
import { CourseContext } from "../../context/CourseContext";
import { theme } from "../../theme";
import ProgramNames from "../../data/programmName";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  border: "none",
  borderRadius: "5px",
  // boxShadow: 24,
  py: 3,
  px: 5,
  // pb: 3,
};

const Form = styled(Box)(() => ({
  // padding: 4,
  borderRadius: "5px",
  // marginTop: 20,
  background: "white",
}));

const Phone = styled(PhoneInput)(() => ({
  ".PhoneInputInput": {
    padding: "11px 11px",
    borderRadius: 20,
    border: "1px solid #e0e0e0",
    marginRight: "10px",
    marginBottom: "25px",
    width: "70px",
  },
  ".PhoneInputCountry": {
    marginBottom: "20px",
  },
  ".PhoneInputInput:focus-visible": {
    outline: `1px solid ${theme.palette.primary.main}`,
  },
}));

const programmes = {
  "Online Instructor-Led": "intructorLedSchedule",
  Classroom: "classRoomSchedule",
};

export const ContactCourseInfo = ({
  handleClose,
  openContact,
  programmeName,
  getEnquery,
}) => {
  // const { courseId } = React.useContext(CourseContext);
  const [submittingForm, setSubmittingForm] = React.useState(false);

  const courseId = window.localStorage.getItem("courseId");

  const onsiteFormik = useFormik({
    initialValues: {
      name: "",
      email: "",
      contactNumber: null,
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required().label("Name").max(150),
      email: Yup.string().email().required().label("Email").max(150),
      contactNumber: Yup.number()
        .nullable()
        .required()
        .label("Contact Number")
        .max(999999999999, "Invalid contact number")
        .min(1000000000, "Invalid contact number"),
      message: Yup.string().required().label("Message"),
    }),
    onSubmit: async (values) => {
      try {
        const body = {
          subject: programmeName,
          customerDetails: {
            name: values.name,
            email: values.email,
            contactNumber: Number(values.contactNumber),
          },
          enquiryDetails: {
            courseId: courseId,
            programmeName: programmeName,
            programmeDetails: {},
          },

          message: values.message,
        };
        // console.log(programmeName, programmes[programmeName],"=====000000000")
        body.enquiryDetails.programmeDetails[programmes[programmeName]] =
          getEnquery;
        setSubmittingForm(true);
        const { data } = await post(`${urls.enquiry.createForm}`, body);
        onsiteFormik.resetForm();

        handleClose();
      } catch (error) {
        console.error(error);
      }
      setSubmittingForm(false);
    },
    validateOnBlur: false,
  });
  console.log(onsiteFormik.errors);

  return (
    <>
      <Modal
        open={openContact}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        sx={{ display: "block" }}
      >
        <Box sx={{ ...style, width: { xs: "100%", md: 700 } }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              variant="body1"
              id="child-modal-description"
              mt={2}
              mb={2}
            >
              Fill out your contact details below !
            </Typography>
            <Typography
              onClick={handleClose}
              component="div"
              sx={{ cursor: "pointer", "&:hover": { opacity: 0.5 } }}
            >
              <CloseIcon />
            </Typography>
          </Box>

          <Form
            component={"form"}
            onSubmit={onsiteFormik.handleSubmit}
            sx={{
              width: "100%",
              margin: "0 auto",
              fontFamily: "Inter",
            }}
          >
            <FormLabel>Full Name *</FormLabel>
            <FormInput formik={onsiteFormik} name="name" type="text" />
            <FormLabel>Email *</FormLabel>
            <FormInput formik={onsiteFormik} name="email" type="email" />
            {/* <FormLabel>Contact No *</FormLabel> */}
            <FormLabel>Contact No*</FormLabel>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Phone
                formik={onsiteFormik}
                name="contactNumber"
                international
                countryCallingCodeEditable={false}
                defaultCountry="IN"
              />
              <FormInput
                formik={onsiteFormik}
                name="contactNumber"
                type="number"
              />
            </Box>

            <FormLabel>Your Message</FormLabel>
            <FormInput
              formik={onsiteFormik}
              name="message"
              type="text"
              multiline={true}
              rows={3}
            />
            <Button
              // disableElevation
              type="submit"
              variant="contained"
              // endIcon={<ArrowForwardIcon />}
              sx={{
                marginBottom: "20px",
                width: "100%",
                fontFamily: "Inter",
              }}
            >
              Send
            </Button>
          </Form>
        </Box>
      </Modal>
    </>
  );
};
