import {
  Box,
  Container,
  Grid,
  Typography,
  styled,
  Tab,
  Tabs,
  TextField,
  Button,
  FormLabel,
} from "@mui/material";
import React, { useContext, useState } from "react";
import OverView from "../../pages/OverView";
import KeyFeatures from "../../pages/KeyFeatures";
import CourseOutline from "../../pages/CourseOutline";
import WhoShouldAttend from "../../pages/WhoShouldAttend";
import Benifits from "../../pages/Benifits";
import FormInput from "../../form/FormInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import { post } from "../../apis/apiMethods";
import urls from "../../apis/urlContants";
import { CourseContext } from "../../context/CourseContext";
import { LoadingButton } from "@mui/lab";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}
const FormHeading = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const Form = styled(Box)(() => ({
  padding: 20,
  borderRadius: "5px",
  marginTop: 20,
  background: "white",
}));

const Details = ({ selectCourse }) => {
  const [tabValue, setTabValue] = useState(0);
  const [submittingForm, setSubmittingForm] = useState(false);

  console.log(selectCourse?.courseDetails?.overview, "-----selectCourse");

  const { courseId } = useContext(CourseContext);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      contactNumber: "",
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required().label("Name").max(150),
      email: Yup.string().email().required().label("Email").max(150),
      contactNumber: Yup.number()
        .nullable()
        .positive()
        .required()
        .label("Contact Number")
        .max(999999999999, "Invalid contact number")
        .min(1000000000, "Invalid contact number"),
      message: Yup.string().required().label("Message"),
    }),
    onSubmit: async (values) => {
      try {
        const body = {
          subject: "More details about the course",
          customerDetails: {
            name: values.name,
            email: values.email,
            contactNumber: values.contactNumber,
          },
          enquiryDetails: {
            courseId: courseId,
            programmeDetails: {},
          },
          message: values.message,
        };
        setSubmittingForm(true);
        const { data } = await post(`${urls.enquiry.createForm}`, body);
        formik.resetForm();
      } catch (error) {
        console.error(error);
      }
      setSubmittingForm(false);

      console.log(values);
    },
    validateOnBlur: false,
    enableReinitialize: true,
  });

  return (
    <>
      <Box bgcolor="whitesmoke">
        <Grid container sx={{ background: "#fff" }}>
          <Grid item xs={12} md={8.3} lg={10}>
            <Container>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                textColor="inherit"
                indicatorColor="secondary"
              >
                <Tab label="Overview" {...a11yProps(0)} />
                <Tab label="Key Features" {...a11yProps(1)} />
                <Tab label="Course outline" {...a11yProps(2)} />
                <Tab label="Benefits" {...a11yProps(3)} />
                <Tab label="Who should attend" {...a11yProps(4)} />
              </Tabs>
            </Container>
          </Grid>
          {/* <FormHeading item xs={12} md={3} lg={2}>
            <Typography
              sx={{
                color: "black",
                fontWeight: "bold",
                display: { xs: "none", md: "flex" },
              }}
            >
              For More Details
            </Typography>
          </FormHeading> */}
        </Grid>
        <Container maxWidth="xl">
          <Grid container>
            <Grid item xs={12} md={7} lg={6}>
              <TabPanel value={tabValue} index={0}>
                <OverView
                  overViewData={selectCourse?.courseDetails?.overview}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <KeyFeatures
                  keyFeatures={selectCourse?.courseDetails?.keyFeatures}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <CourseOutline
                  courseOutline={selectCourse?.courseDetails?.courseOutline}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={3}>
                <Benifits
                  BenifitsData={selectCourse?.courseDetails?.benefits}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={4}>
                <WhoShouldAttend
                  whoShouldAttend={selectCourse?.courseDetails?.whoIsItFor}
                />
              </TabPanel>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} p={{ xs: 2, md: 4 }}>
              {/* <Container> */}
              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: { xs: "center", md: "flex-end" },
                  alignItems: "flex-end",
                  marginTop: { xs: -5, md: 0 },
                }}
              >
                {/* <FormHeading item xs={12} md={12} lg={4}>
                  <Typography
                    sx={{
                      fontFamily: "Inter",
                      color: "black",
                      fontWeight: "bold",
                      display: { xs: "block", md: "none" },
                    }}>
                    For More Details
                  </Typography>
                </FormHeading> */}
                <Form
                  component={"form"}
                  onSubmit={formik.handleSubmit}
                  boxShadow={3}
                  sx={{
                    width: { xs: "100%", sm: "100%", md: "60%" },
                    margin: "0 auto",
                    fontFamily: "Inter",
                  }}
                >
                  <Box
                    sx={{
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    <Typography
                      sx={{
                        color: "black",
                        fontWeight: "bold",
                      }}
                    >
                      For More Details
                    </Typography>
                  </Box>
                  <FormLabel sx={{ paddingLeft: "10px" }}>Name*</FormLabel>
                  <FormInput formik={formik} name="name" type="text" />
                  <FormLabel sx={{ paddingLeft: "10px" }}>Email*</FormLabel>
                  <FormInput formik={formik} name="email" type="email" />
                  <FormLabel sx={{ paddingLeft: "10px" }}>
                    Contact No*
                  </FormLabel>
                  <FormInput
                    formik={formik}
                    name="contactNumber"
                    type="number"
                  />
                  <FormLabel sx={{ paddingLeft: "10px" }}>
                    Your Message*
                  </FormLabel>
                  <FormInput
                    formik={formik}
                    name="message"
                    type="text"
                    multiline={true}
                    rows={3}
                    sx={{
                      "& .MuiInputBase-root": {
                        paddingLeft: "25px",
                      },
                    }}
                  />
                  <LoadingButton
                    disableElevation
                    type="submit"
                    variant="contained"
                    loading={submittingForm}
                    size="small"
                    sx={{
                      marginBottom: "20px",
                      width: "40%",
                      fontFamily: "Inter",
                      "&.MuiButtonBase-root": { borderRadius: 20 },
                    }}
                  >
                    Send
                  </LoadingButton>
                </Form>
              </Grid>
              {/* </Container> */}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

//

export default Details;
