
import {
  Box,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
  Button,
  styled,
} from "@mui/material";
import React from "react";
import { PmpCertificateData } from "../helpers/Commun.hellper";

const OverView = ({overViewData}) => {

  const Para = styled(Typography)(({ theme }) => ({
    // color: "#555",
    lineHeight: "24px",
    margin: "20px 0",
    fontSize: "16px",
    textAlign: "justify",
    fontFamily: "Inter",
  }));

  return (
    <Box>
      {/* {PmpCertificateData.map((text, i) => ( */}
          {/* <Para>{text.overview.desc}</Para>
          <Para>{text.overview.descoOther}</Para>
          <Para fontWeight="bold">
            {text.overview.listTitle}
            {text.overview.listItems.map((item, i) => (
              <div key={i}>
                <li>{item}</li>
              </div>
            ))}
          </Para> */}
          <Para><div dangerouslySetInnerHTML={{__html: overViewData}} /></Para>
          {/* <Para>{overViewData}</Para> */}
      {/* ))} */}
    </Box>
  );
};

export default OverView;
