import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RemoveIcon from "@mui/icons-material/Remove";
import { Box, Container, Grid, styled } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import faqimg from "../assets/Images/faq.png";
import useScrollToTopOnNavigation from "../hooks/useScrollToTopNavigation";
import { theme } from "../theme";

const faq = [
  {
    id: "1",
    qns: "What is virtual training?",
    ans: "Virtual training means learning and skill development online.HR professionals can utilize virtual training to enhance employee skills, offer leadership development, and ensure compliance across geographically dispersed teams. HR departments can utilize virtual training programs for large organizations ",
  },
  {
    id: "2",
    qns: "What is online training?",
    ans: "For a company or organization, virtual training refers to a method of conducting education and skill development activities online.Participants can learn remotely through various methods including:",
  },
  {
    id: "3",
    qns: "What is a virtual training platform?",
    ans: "A virtual training platform is an online software or digital environment specifically designed to facilitate the delivery and management of training and educational content.",
  },
  {
    id: "4",
    qns: "How do you use virtual training?",
    ans: "Virtual training serves these purposes and more, enhancing the company’s overall effectiveness, employee development, and adaptability to changing circumstances.",
  },
  {
    id: "5",
    qns: "What is online training?",
    ans: "Online training involves accessing educational content remotely through the internet, often at one’s own pace, with interaction potentially asynchronous.",
  },
  {
    id: "6",
    qns: "Why is virtual training better?",
    ans: "Online training involves accessing educational content remotely through the internet, often at one’s own pace, with interaction potentially asynchronous.",
  },

  {
    id: "7",
    qns: "What kind of learning/Training does Aphia provide? ",
    ans: "Aphia, we offer instructor-led online live sessions and self-paced online courses covering various categories.",
  },

  {
    id: "8",
    qns: "Who are the trainers at Aphia?",
    ans: "We choose certified subject matter experts with plentiful industry knowledge, and real-time working consultants on various kinds of projects in various MNCs, who have great experience in implementing and supporting a range of multinational SAP and other technologies projects, who know how to make their topics engaging to create a memorable and valuable learning experience. These experts belong to various industries and are willing to share their experiences with learners like you. We have a large catalog of Self-paced online courses as well. We have well-versed partners with leading instructors, premium learning content houses, and universities across the globe",
  },

  {
    id: "9",
    qns: "How are classes at Aphia organized?",
    ans: "Live instructor-led Online courses are taught by live instructors on a specific day and time and with a set duration. Learners can take these courses through online video streaming from anywhere and users can live discuss with our trainers by speaking and sharing screens. Users can speak by using a microphone/Headphone. Self-paced online courses are provided in the form of high-quality pre-recorded videos and these courses can be learned at their own pace, on their own time. These courses can be accessed from anywhere in the world. ",
  },

  {
    id: "10",
    qns: "What is the difference between Class room, pre-recorded video session and instructor-led Virtual online live classes? ",
    ans: "Classroom training will be delivered by our Trainer, physically at our preferred time and training venue. Pre-recorded sessions are the ones in which there is a one-way flow between the trainers and the learners i.e., learners can be visible and able to listen to the training session, but cannot ask queries/doubts from the instructor. These courses can be accessed from any place; all you need is a decent internet connectivity to play videos. You can learn at your own comfortable pace.  Instructor-led Virtual online live sessions are the ones in which there a both ways of communication between the students and trainers. The trainers can easily engage, observe & track their learning progress. The students/participants also have the opportunity to ask any queries through various modes (speaking or emailing) from the esteemed trainer. But we won’t be contingent on the ‘Extreme’ classroom experience!! We are reverent deviate! Our All-live Online Classroom training combines the best features of the classroom experience and the convenience of online self-study. Try it and we are confident you will not be downcast. ",
  },

  {
    id: "11",
    qns: "Can I take my training sessions irrespective of my location? ",
    ans: "Yes indeed, you can take the training sessions irrespective of your location. Please make sure you adhere to the course perquisites.",
  },

  {
    id: "12",
    qns: "Do you provide any Certification for live instructor led online courses? If yes, what is the Certification process? ",
    ans: "In the case of live instructor-led online training, at the end of the course, the trainer will allot you a real-time project which helps you to clear understanding of how to implement the real-time application. We will provide you with continuous support and assist you in completing the project work. On successful completion of the real-time project work, it will be reviewed by our trainer and you will be awarded a certificate with grade. ",
  },
  {
    id: "13",
    qns: "Do I get any assistance after completion of my sessions?",
    ans: "Yes of course, our trainers will always be there to resolve your queries/clarifications and take your doubts even after successful completion of the course.",
  },
  {
    id: "14",
    qns: "What if I am not able to clear the Certification exam in first attempt? ",
    ans: "In the case of a live instructor-led course, after the instructor’s review, if your real time project is not approved, then we will provide you the extra assistance for any clarifications and Aphia will offer a re-attempt it is just free of cost. After the successful completion of the assigned project, you will receive the certificates within specified days.",
  },
];
const Ribben = styled(Box)(() => ({
  height: "50px",
  clipPath: "polygon(15% 0%, 100% 0, 86% 75%, 0 75%)",
  backgroundColor: theme.palette.secondary.main,
}));

const Text = styled(Typography)(() => ({
  color: theme.palette.primary.main,
  fontSize: "33px",
  fontFamily: "Quicksand",
}));

export default function Faq() {
  useScrollToTopOnNavigation();
  const [expanded, setExpanded] = React.useState("");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : "");
  };

  return (
    <Box bgcolor="whitesmoke">
      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          <Box>
            <img width="100%" src={faqimg} alt="" />
            <Ribben />
          </Box>
        </Grid>
        <Grid item xs={12} md={12}>
          <Text textAlign="center">FAQ</Text>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontFamily: "Quicksand",
            }}
          >
            <Box sx={{ width: { xs: "100%", sm: "100%", md: "50%" } }}>
              {faq.map((item, i) => (
                <React.Fragment key={i}>
                  <Accordion
                    expanded={expanded === item.id}
                    onChange={handleChange(item.id)}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === item.id ? (
                          <RemoveIcon sx={{ color: "white" }} />
                        ) : (
                          <AddIcon sx={{ color: "white" }} />
                        )
                      }
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      sx={{
                        backgroundColor: theme.palette.primary.main,
                        color: "#ffff",
                      }}
                    >
                      <Typography>{item.qns}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>{item.ans}</Typography>
                    </AccordionDetails>
                    <AccordionDetails>
                      <Typography>{item.ans2}</Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Box mb={2} />
                </React.Fragment>
              ))}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
