//course subject names

import ibm from "../../assets/Images/ibm.png";
import cognizant from "../../assets/Images/cognizant.png";
import wipro from "../../assets/Images/wipro.jpg";
import deloit from "../../assets/Images/deloit.png";
import infosys from "../../assets/Images/infosys.jpg";
import Ey from "../../assets/Images/EY_logo.png";
import pwc from "../../assets/Images/pwc.png";
import mico from "../../assets/Images/mico.png";
import kpmg from "../../assets/Images/kpmg.png";
import tams from "../../assets/Images/tams.jpg";
import capgimini from "../../assets/Images/capgimini.jpg";
import accenture from "../../assets/Images/accenture.jpg";

export const courseSubjects = [
  {
    id: "1",
    coursetitle: "ABAP",
    coursedesc:
      "SAP ABAP (Advanced Business Application Programming) is a high-level programming language created by SAP for developing custom business applications in the SAP environment.",

    vediotitle: "Mathematics",
    vediodesc: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    virtualtitle: "Science",
    virtualdesc: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  },
  {
    id: "2",
    coursetitle: "Agile and Scrum",
    coursedesc:
      "Embark on a transformative journey in the world of project management with our Agile and Scrum course, equipping you with the skills to navigate dynamic landscapes and deliver successful, iterative solutions",
    vediotitle: "Mathematics",
    vediodesc: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    virtualtitle: "Science",
    virtualdesc: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  },
  {
    id: "3",
    coursetitle: "Lean Six Sigma Black Belt",
    coursedesc:
      "This course equips professionals with advanced problem-solving and process improvement skills, integrating the principles of Lean and Six Sigma methodologies for optimal organizational performance.",
    vediotitle: "Mathematics",
    vediodesc: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    virtualtitle: "Science",
    virtualdesc: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  },
  {
    id: "4",
    coursetitle: "PMP certification",
    coursedesc:
      "This course equips professionals with essential project management skills and methodologies, validating their ability to lead and deliver successful projects.",
    vediotitle: "Mathematics",
    vediodesc: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    virtualtitle: "Science",
    virtualdesc: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  },
  {
    id: "5",
    coursetitle: "PMP ACP",
    coursedesc:
      "The PMI Agile Certified Practitioner certification course equips professionals with a versatile skill set, integrating various agile methodologies to enhance project management agility.",
    vediotitle: "Mathematics",
    vediodesc: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    virtualtitle: "Science",
    virtualdesc: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  },
  {
    id: "6",
    coursetitle: "Lean and Kanban",
    coursedesc:
      "Enroll in our Lean and Kanban course to master streamlined processes and agile methodologies for optimized workflow and continuous improvement.",
    vediotitle: "Mathematics",
    vediodesc: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    virtualtitle: "Science",
    virtualdesc: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  },
  {
    id: "7",
    coursetitle: "Accounting",
    coursedesc: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    vediotitle: "Mathematics",
    vediodesc: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    virtualtitle: "Science",
    virtualdesc: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  },
  {
    id: "8",
    coursetitle: "Accounting",
    coursedesc: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    vediotitle: "Mathematics",
    vediodesc: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    virtualtitle: "Science",
    virtualdesc: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  },
  {
    id: "9",
    coursetitle: "Accounting",
    coursedesc: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    vediotitle: "Mathematics",
    vediodesc: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    virtualtitle: "Science",
    virtualdesc: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  },
];

//course names

export const coursesnames = [
  {
    id: "1",
    title: "Classroom Courses",
  },
  {
    id: "2",
    title: "Virtual Courses",
  },
  {
    id: "3",
    title: "Video Courses",
  },
];

//random logos

export const logo = [
  {
    id: "1",
    image: ibm,
  },
  {
    id: "2",
    image: cognizant,
  },
  {
    id: "3",
    image: wipro,
  },
  {
    id: "4",
    image: deloit,
  },
  {
    id: "5",
    image: infosys,
  },
  {
    id: "6",
    image: Ey,
  },
  {
    id: "7",
    image: pwc,
  },
  {
    id: "8",
    image: mico,
  },
  {
    id: "9",
    image: kpmg,
  },
  {
    id: "10",
    image: tams,
  },
  {
    id: "11",
    image: capgimini,
  },
  {
    id: "12",
    image: accenture,
  },
];
