import React, { useState, useRef, useContext } from "react";
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  styled,
  Button,
  Drawer,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SelectForEnquery } from "./SelectForEnquery";
import EastIcon from "@mui/icons-material/East";
import { Stack } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { ContactCourseInfo } from "./ContactCourseInfo";
import { theme } from "../../theme";

const Arrow = styled(Box)(() => ({
  // position: "absolute",
  height: 40,
  width: 40,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "50%",
  backgroundColor: theme.palette.primary.main,
  color: "#fafafa",
  fontWeight: "bold",
  // right: -170,
  // top: 0,
}));

const ArrowBox = styled(Box)(({ theme }) => ({
  display: "flex",
  textAlign: "center",
  gap: "20px",
  alignItems: "center",
  // background:"red",
  marginBottom: 10,

  // [theme.breakpoints.down("sm")]: {
  //   marginTop: -10,
  //   display: "none",
  // },
}));

const ArrowBack = styled(ArrowBackIcon)(({ theme }) => ({
  // marginLeft: 4,
  // marginTop: 4,
  fontSize: "1.8rem",
  "&:hover": {
    opacity: 0.5,
  },
}));
const ArrowForward = styled(ArrowForwardIcon)(({ theme }) => ({
  // marginLeft: 5,
  // marginTop: 2,
  fontSize: "1.8rem",
  "&:hover": {
    opacity: 0.5,
  },
}));

const Salary = styled(Typography)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  // flexDirection: "column",
  color: theme.palette.primary.main,
}));

const DateBox = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  fontSize: 16,
  [theme.breakpoints.down("sm")]: {
    fontSize: 14,
  },
  [theme.breakpoints.between(279, 281)]: {
    fontSize: 12,
  },
}));

const CardWeek = styled(Box)(({ theme }) => ({
  display: "flex",
  // textAlign: "start",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: "15px",
  // flexDirection: "column",
}));

const CardBox1 = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  // flexDirection: "column",
}));

const EastArrow = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));

export const SelectClassroom = ({
  programmeName,
  classRoomInfo,
  openContact,
  handleClose,
  handleOpen,
  courseTitle,
}) => {
  const [state, setState] = React.useState(false);
  const [classRoomData, setClassRoomData] = React.useState();
  const sliderRef = useRef();

  const toggleDrawer = (anchor, open, ele) => (event) => {
    setState({ ...state, [anchor]: open });
    setClassRoomData(ele);
  };

  console.log(classRoomInfo?.schedules, "200");

  // ================= Card Slider start
  const CardSliderClass = ({ classroomdata }) => {
    const [selectedCardIndexRoom, setSelectedCardIndexRoom] = useState(0);

    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      showArrows: false,
    };
    const cardsPerSlide = 6;

    //================= Function to generate the card components
    const generateCards = (startIndex, endIndex) => {
      return classroomdata?.schedules
        .filter((s) => s.activated)
        ?.slice(startIndex, endIndex)
        .map((ele, i) => (
          <Grid item xs={12} sm={12} md={12} lg={6} key={startIndex + i}>
            <Card
              ele={ele}
              i={startIndex + i}
              selectedCardIndexRoom={selectedCardIndexRoom}
              onClick={(index) => setSelectedCardIndexRoom(index)}
            />
          </Grid>
        ));
    };

    // ==================Calculate the number of slides needed
    const numSlides = Math.ceil(
      classroomdata?.schedules?.length / cardsPerSlide
    );

    // ==================Use the generateCards function for each slide
    const sliderContent = Array.from({ length: numSlides }, (_, slideIndex) => (
      <Box key={slideIndex}>
        <Grid container spacing={2} sx={{ display: "flex" }}>
          {generateCards(
            slideIndex * cardsPerSlide,
            (slideIndex + 1) * cardsPerSlide
          )}
        </Grid>
      </Box>
    ));

    return (
      <Slider ref={sliderRef} {...settings}>
        {sliderContent}
      </Slider>
    );
  };

  // =========== Reusable Card start
  const Card = ({ ele, i, selectedCardIndexRoom, onClick }) => {
    return (
      <>
        <Box
          md={6}
          sx={{
            p: i % 2 === 0 ? 2.5 : 2.3,
            // height: { xs: "100%", md: "100px" },
            backgroundColor: "#ffff",
            // backgroundColor: i === selectedCardIndex1 ? "#F5B901" : "#ffff",
            // color: i === selectedCardIndex1 ? "#212121" : "grey",
            borderRadius: "5px",
            marginBottom: "3px",
            boxShadow: 5,
            "&:hover": {
              backgroundColor: theme.palette.secondary.main,
              color: "#212121",
            },
          }}
          onClick={toggleDrawer("right", true, ele)}>
          <CardBox1>
            <Box sx={{ display: "flex", alignItems: "center" }} gap={1}>
              <DateBox
                sx={{
                  fontWeight: "bold",
                }}>
                {moment(ele?.startDate).format("ddd, Do MMM YYYY")}
              </DateBox>
              <EastArrow>
                <EastIcon />
              </EastArrow>
              <DateBox
                sx={{
                  fontWeight: "bold",
                }}>
                {moment(ele?.endDate).format("ddd, Do MMM YYYY")}
              </DateBox>
            </Box>

            <Salary gap={2}>
              <Box sx={{ display: "flex" }}>
                <s
                  style={{
                    color: "gray",
                    marginRight: "10px",
                    fontSize: "18px",
                  }}>
                  ₹
                </s>
                <s
                  style={{
                    color: "gray",
                    marginRight: "10px",
                    fontSize: "18px",
                  }}>
                  {classRoomInfo?.standardPrice}
                </s>
              </Box>

              <Box sx={{ display: "flex" }}>
                <Typography variant="h5">₹</Typography>
                <Typography variant="h5">
                  {classRoomInfo?.earlyBirdPrice}
                </Typography>
              </Box>
            </Salary>
          </CardBox1>

          <CardBox1>
            <DateBox
              sx={{
                fontWeight: "bold",
              }}>
              Duration: {ele?.hoursPerDay} hrs/day
            </DateBox>
            <Box
              backgroundColor={theme.palette.primary.main}
              color="#fff"
              p={1}
              borderRadius="3px">
              {ele?.weekType.toLowerCase()} - {ele?.startTime} to {ele?.endTime}
            </Box>
          </CardBox1>
          <Typography
            variant="body"
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              fontSize: "14px",
              color: "gray",
              mt: "5px",
            }}>
            *Applicable taxes may apply
          </Typography>
        </Box>
      </>
    );
  };

  return (
    <>
      {classRoomInfo?.schedules.filter((s) => s.activated).length > 6 && (
        <ArrowBox>
          <Arrow onClick={() => sliderRef.current.slickPrev()}>
            <ArrowBack />
          </Arrow>
          <Arrow onClick={() => sliderRef.current.slickNext()}>
            <ArrowForward />
          </Arrow>
        </ArrowBox>
      )}

      <CardSliderClass classroomdata={classRoomInfo} />
      {/* ============= right model ClassRoom */}
      <Drawer
        anchor={"right"}
        open={state["right"]}
        onClose={toggleDrawer("right", false)}>
        <Box
          sx={{ p: 4 }}
          role="presentation"

          // onClick={() => {
          //   toggleDrawer("right", false);
          // }}
          // onKeyDown={() => {
          //   toggleDrawer("right", false);
          // }}
          // onKeyDown={toggleDrawer(anchor, false)}
        >
          <SelectForEnquery
            state={state}
            setState={setState}
            getEnquery={classRoomData}
            handleOpen={handleOpen}
            programmeName={programmeName?.title}
            earlyBirdPrice={classRoomInfo?.earlyBirdPrice}
            standardPrice={classRoomInfo?.standardPrice}
            courseTitle={courseTitle}
          />
          <ContactCourseInfo
            openContact={openContact}
            handleClose={handleClose}
            courseId={classRoomInfo?._id}
            programmeName={programmeName?.title}
            getEnquery={classRoomData}
          />
        </Box>
      </Drawer>
    </>
  );
};
