import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import OnlineSelf from "../OnlineSelf";
import Classroom from "../selectUpcomingClass/Classroom";
import Onsite from "../Onsite";
import { NewCourseOutline } from "./SelectUpcomingClass";
import { theme } from "../../theme";

import ProgramNames from "../../data/programmName";

const details = [
  {
    id: "1",
    title: ProgramNames["Online Instructor-Led"],
    path: "/online-ins",
  },
  {
    id: "2",
    title: ProgramNames["Online Self-Paced"],
    path: "/online-self",
  },
  {
    id: "3",
    title: ProgramNames["Classroom"],
    path: "/classroom",
  },
  {
    id: "4",
    title: ProgramNames["Onsite"],
    path: "/onsite",
  },
];
const details2 = [
  {
    id: "1",
    title: "Online Instructor-Led",
    path: "/online-ins",
  },
  {
    id: "3",
    title: "Classroom",
    path: "/classroom",
  },
  {
    id: "4",
    title: "Onsite",
    path: "/onsite",
  },
];
const Classes = ({
  selectCourse,
  onlineInstructor,
  onlineSelfPaced,
  classRoomInfo,
  courseTitle,
}) => {
  const [selecteditem, setSelectedItem] = useState("1");
  const [openContact, setOpenContact] = useState(false);
  const [openRequest, setOpenRequest] = useState(false);

  const handleItemClick = (id) => {
    setSelectedItem(id);
  };

  const handleOpenRequest = () => {
    setOpenRequest(true);
  };

  const handleOpen = () => {
    setOpenContact(true);
  };

  const handleClose = () => {
    setOpenContact(false);
    setOpenRequest(false);
  };

  useEffect(() => {
    setSelectedItem("1");
  }, [selectCourse]);

  console.log(selectCourse, "000000");

  return (
    <>
      <Typography
        sx={{
          fontSize: 25,
          fontWeight: "bold",
          textAlign: "center",
          padding: "20px",
        }}
      >
        Upcoming Classes
      </Typography>
      <Box bgcolor="whitesmoke">
        <Grid container>
          <Grid item xs={12} md={2} lg={2.5}>
            {details.map((ele, i) => (
              <Box
                key={ele?.id}
                sx={{
                  backgroundColor:
                    selecteditem === ele?.id
                      ? theme.palette.primary.main
                      : "white",
                  p: 3,
                  mt: 5,
                  mb: 5,
                  boxShadow: 5,
                  transition: "0.3s",
                  borderRadius: "5px",
                  color:
                    selecteditem === ele?.id
                      ? "white"
                      : theme.palette.primary.main,
                }}
                onClick={() => handleItemClick(ele?.id)}
              >
                <Typography
                  sx={{
                    color:
                      selecteditem === ele?.id
                        ? "white"
                        : theme.palette.primary.main,
                  }}
                >
                  {ele.title}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: -3,
                  }}
                >
                  <ChevronRightIcon />
                </Box>
              </Box>
            ))}
          </Grid>

          <Grid item xs={12} md={9} lg={9.5}>
            {selecteditem === "1" && (
              <Grid item xs={12} sm={12} md={12} py={4}>
                <NewCourseOutline
                  onlineInstructor={onlineInstructor}
                  handleOpenRequest={handleOpenRequest}
                  handleOpen={handleOpen}
                  handleClose={handleClose}
                  openRequest={openRequest}
                  openContact={openContact}
                  selectedCourse={selectCourse}
                  courseTitle={courseTitle}
                  programmeName={details.find(
                    (programmeName) =>
                      programmeName.title ===
                      ProgramNames["Online Instructor-Led"]
                  )}
                />
              </Grid>
            )}
            {selecteditem === "2" && (
              <Grid item xs={12} sm={12} md={12} py={2}>
                <OnlineSelf
                  courseTitle={courseTitle}
                  onlineSelfPaced={onlineSelfPaced}
                  selectedCourse={selectCourse}
                  programmeName={details.find(
                    (programmeName) =>
                      programmeName.title === ProgramNames["Online Self-Paced"]
                  )}
                />
              </Grid>
            )}

            {selecteditem === "3" && (
              <Grid item xs={12} sm={12} md={12} py={4}>
                <Classroom
                  classRoomInfo={classRoomInfo}
                  courseTitle={courseTitle}
                  handleOpenRequest={handleOpenRequest}
                  handleOpen={handleOpen}
                  handleClose={handleClose}
                  openRequest={openRequest}
                  openContact={openContact}
                  selectedCourse={selectCourse}
                  programmeName={details.find(
                    (programmeName) =>
                      programmeName.title === ProgramNames["Classroom"]
                  )}
                />
              </Grid>
            )}
            {selecteditem === "4" && (
              <Grid item xs={12} sm={12} md={12} p={4}>
                <Onsite selectedCourse={selectCourse} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Classes;
