import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  Typography,
  styled,
} from "@mui/material";
import ScrollAnimation from "react-animate-on-scroll";
import BecomeTraining from "../assets/Images/become-training.png";
import FormInput from "../form/FormInput";
import { Form, useFormik } from "formik";
import * as Yup from "yup";
import urls from "../apis/urlContants";
import { post } from "../apis/apiMethods";
import useScrollToTopOnNavigation from "../hooks/useScrollToTopNavigation";
import { LoadingButton } from "@mui/lab";
import { theme } from "../theme";
import { Stack } from "react-bootstrap";
import TrainingPartner from "../assets/Images/Trainngpartner.png"; 
const becomeTraining = [
  {
    heading: "Extensive Curriculum",
    subHeading:
      "Partnering with us means gaining access to an extensive library of courses and training programs across a wide range of subjects and industries. Whether you're focused on professional development, vocational training, or personal enrichment, our curriculum caters to diverse learner needs.",
  },
  {
    heading: "Innovative Teaching Methods",
    subHeading:
      "We stay at the forefront of educational technology and methodologies. As a Training Channel Partner, you'll have the opportunity to leverage our innovative teaching tools, learning management systems, and resources to enhance your training delivery.",
  },
  {
    heading: "Brand Recognition",
    subHeading:
      "Align your brand with Aphia Learning, a trusted and recognized name in education. Benefit from the credibility and reputation that come with being associated with an established institution.",
  },
  {
    heading: "Marketing Support",
    subHeading:
      "We understand that marketing is crucial to the success of any training program. Our marketing team will work closely with you to develop strategies, promotional materials, and campaigns to reach your target audience effectively.",
  },
  {
    heading: "Sales Assistance",
    subHeading:
      "Our sales team will assist you in selling training programs and courses. We provide the necessary training and resources to help you close deals and generate revenue.",
  },
  {
    heading: "Dedicated Support",
    subHeading:
      "Our partner support team is here to assist you every step of the way. Whether you have questions, need technical assistance, or require guidance on curriculum selection, we are committed to helping you succeed.",
  },
  {
    heading: "Flexible Partnership Models",
    subHeading:
      "We offer flexible partnership models tailored to suit your business goals and objectives. Whether you want to resell our courses, co-brand, or integrate our content into your existing platform, we have options that fit your needs.",
  },
];

const Heading = styled(Typography)(() => ({
  color: "#000",
  marginBottom: "15px",
  marginTop: "15px",
  fontFamily: "Quicksand",
  fontSize: 18,
  fontWeight: 700,
}));

export const BecomeTrainingPartner = () => {
  useScrollToTopOnNavigation();
  const [submittingForm, setSubmittingForm] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      contactNumber: "",
      expertise: "",
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required().label("Name").max(150),
      email: Yup.string().email().required().label("Email").max(150),
      contactNumber: Yup.number()
        .nullable()
        .required()
        .label("Contact Number")
        .min(1000000000, "Invalid contact number")
        .max(99999999999, "Invalid contact number"),
      expertise: Yup.string().required().label("Expertise"),
      message: Yup.string().required().label("Message"),
    }),
    onSubmit: async (values) => {
      try {
        const body = {
          subject: "Regarding Becoming a training partner",
          customerDetails: {
            name: values.name,
            email: values.email,
            contactNumber: values.contactNumber,
          },
          enquiryDetails: {
            others: {
              expertise: values.expertise,
            },

            programmeDetails: {},
          },
          message: values.message,
        };
        setSubmittingForm(true);

        const { data } = await post(urls.enquiry.createForm, body);
        formik.resetForm();
      } catch (error) {
        console.error(error);
      }
      setSubmittingForm(false);
    },
    validateOnBlur: false,
    enableReinitialize: true,
  });
  return (
    <>
      <Box
        sx={{
          backgroundImage: `radial-gradient(at bottom left, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.0)),url(${TrainingPartner})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "100%",
          // padding: 12,
          padding: { xs: "100px 0", sm: "100px 0", md: "170px 50px" },
        }}
      >
        <Container maxWidth="xl">
          <Typography
            variant="h2"
            sx={{
              fontFamily: "Quicksand",
              color: "#fff",
              fontWeight: 700,
              fontSize: { xs: "24px", sm: "36px", md: "52px" },
            }}
          >
            Become a training partner
          </Typography>
        </Container>
      </Box>
      <Box bgcolor="whitesmoke">
        <Container maxWidth="xl">
          <Grid
            container
            spacing={2}
            my={2}
            sx={{
              padding: { xs: "0 0", sm: "0 0", md: "50px 50px" },
            }}
          >
            <Grid item xs={12} md={12} lg={8}>
              <Typography variant="body1">
                Aphia Learning’s partnership program will give you the resources
                you need to stand as a training provider such as:
              </Typography>
              <Grid item xs={12} md={12} lg={12}>
                {becomeTraining.map((becomeTrainingItem, i) => (
                  <>
                    <Heading variant="body1">
                      {i + 1}. {becomeTrainingItem.heading}
                    </Heading>
                    <Typography variant="body1">
                      {becomeTrainingItem.subHeading}
                    </Typography>
                  </>
                ))}
              </Grid>
            </Grid>

            <Grid item xs={12} md={12} lg={4}>
              <Grid item xs={12} md={12} lg={12}>
                <img
                  style={{
                    width: "100%",
                    borderRadius: 5,
                  }}
                  src={BecomeTraining}
                  alt="Lodding..."
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12} pt={{ xs: 4, sm: 2, md: 10 }}>
                <Box
                  //   boxShadow={4}
                  p={3}
                  sx={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    flexDirection: "column",
                    borderRadius: "5px",
                    height: 520,
                    border: `1px solid ${theme.palette.primary.main}`,
                    backgroundColor: "transparant",
                  }}
                >
                  <form onSubmit={formik.handleSubmit}>
                    <Typography variant="h6" textAlign="center">
                      Become a training partner
                    </Typography>
                    <FormInput
                      formik={formik}
                      name="name"
                      type="text"
                      label="Full Name"
                    />
                    <FormInput
                      formik={formik}
                      name="email"
                      type="email"
                      label="Email"
                    />
                    <FormInput
                      formik={formik}
                      name="contactNumber"
                      label="Contact No."
                    />
                    <FormInput
                      formik={formik}
                      name="expertise"
                      type="text"
                      label="Expertise In"
                    />
                    <FormInput
                      formik={formik}
                      name="message"
                      type="text"
                      label="Tell us about yourself"
                    />
                    <LoadingButton
                      disableElevation
                      type="submit"
                      variant="contained"
                      loading={submittingForm}
                      size="small"
                      sx={{
                        width: "40%",
                        marginBottom: "20px",
                      }}
                    >
                      Send
                    </LoadingButton>
                  </form>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};
