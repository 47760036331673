// import { Visibility, VisibilityOff } from "@mui/icons-material";
// import {
//   Box,
//   Button,
//   IconButton,
//   InputAdornment,
//   TextField,
//   Typography,
// } from "@mui/material";
// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import authentication from "../apis/authentication";
// import "../styles/pages/login.css";
// import { objPropertyCheck } from "../utils/utils";
// import { objHas } from "../utils/utils";
// import { post, put, get } from "../../src/apis/apiMethods";
// import { toast } from "react-toastify";
// import useScrollToTopOnNavigation from "../hooks/useScrollToTopNavigation";
// import { theme } from "../theme";

// // const style={
// //      header: {
// //         "font-size": "3rem",
// //         "font-weight": "bolder",
// //         "color": "yellow",
// //         "margin-bottom": "10px"
// //       }
// // }
// const Login = () => {
//   //   const navigate = useNavigate();
//   useScrollToTopOnNavigation();
//   const [formData, setFormData] = useState({});
//   const [formError, setFormError] = useState(false);
//   const [showPassword, setShowPassword] = useState(false);
//   const [resError, setResError] = useState("");
//   const [settings, setSettings] = useState(null);
//   const [isHovered, setIsHovered] = useState(false);

//   const handleFormChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prev) => ({
//       ...prev,
//       [name]: value,
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     return toast.error("Please contact admin!");
//     if (!objPropertyCheck(formData, ["email", "password"]))
//       return setFormError(true);
//     try {
//       const data = {
//         email: formData.email,
//         password: formData.password,
//       };
//       // console.log(data, "dataaaaa");
//       const loginRes = await authentication.login(data);

//       if (loginRes.status === 200) {
//         toast.success(loginRes.data.message.split("_").join(" "));
//         // console.log(loginRes.status, "loginRes");
//         setFormData({});
//         window.location.href = loginRes.data.meta.redirectUrl;
//       }

//       //navigate("/user/print");
//     } catch (error) {
//       // setResError(error);
//       console.log("setResError", error);
//     }
//   };
//   return (
//     <>
//       <div className="loginContainer">
//         <div className="flexContainer">
//           {/* {settings && (
//           <img
//             src={settings && objHas(settings, "logo.link", "")}
//             alt="logo"
//             style={{
//               width: "260px",
//               marginLeft: "-5px",
//               marginTop: "-10px",
//             }}
//           />
//         )}
//         <div style={{ marginTop: "100px" }}>
//           <div className="header">Login</div>
//           <div className="subHeader">
//             Dont Have an Account?{" "}
//             <Link to={"/signup"}>Create your account</Link>
//           </div>
//           <div className="error">{resError && resError}</div>
//           <div className="formContainer">
//             <form onSubmit={handleSubmit}>
//               <div className="formHolder">
//                 <TextField
//                   error={formError && !formData.email}
//                   fullWidth
//                   onChange={handleFormChange}
//                   value={formData.email || ""}
//                   name="email"
//                   label="Email"
//                   variant="outlined"
//                   sx={{ backgroundColor: "#F5F5F5" }}
//                 />
//                 {formError && !formData.email && (
//                   <span style={{ color: "red" }}>This field is required</span>
//                 )}
//               </div>
//               <div className="formHolder">
//                 <TextField
//                   type={showPassword ? "text" : "password"}
//                   error={formError && !formData.password}
//                   fullWidth
//                   onChange={handleFormChange}
//                   value={formData.password || ""}
//                   name="password"
//                   label="Password"
//                   variant="outlined"
//                   sx={{
//                     backgroundColor: "#F5F5F5",
//                   }}
//                   InputProps={{
//                     endAdornment: (
//                       <InputAdornment position="end">
//                         <IconButton
//                           edge="end"
//                           aria-label="toggle password visibility"
//                           onClick={() => setShowPassword(!showPassword)}
//                         >
//                           {showPassword ? <VisibilityOff /> : <Visibility />}
//                         </IconButton>
//                       </InputAdornment>
//                     ),
//                   }}
//                 />
//                 {formError && !formData.password && (
//                   <span style={{ color: "red" }}>This field is required</span>
//                 )}
//               </div>
//               <div className="textBtnContainer">
//                 <div className="forgotText">
//                   <Link
//                     to="/forgotpassword"
//                     style={{
//                       textDecoration: "none",
//                     }}
//                   >
//                     Forgot Password?
//                   </Link>
//                 </div>

//                 <div className="btn">
//                   <Button variant="contained" type="submit">
//                     LOGIN
//                   </Button>
//                 </div>
//               </div>
//             </form>
//           </div>
//         </div> */}
//           <Box
//             component={"form"}
//             onSubmit={handleSubmit}
//             //   boxShadow={4}
//             p={3}
//             sx={{
//               display: "flex",
//               justifyContent: "center",
//               flexDirection: "column",
//               width: { xs: 340, sm: 390, md: 420, lg: 420 },
//               border: `1px solid ${theme.palette.primary.main}`,
//               backgroundColor: "#fff",
//               borderRadius: "8px",
//             }}
//           >
//             <Typography
//               variant="h5"
//               textAlign="center"
//               color={theme.palette.primary.main}
//             >
//               Login
//             </Typography>
//             <Typography
//               variant="body"
//               textAlign="center"
//               mt={2}
//               sx={{
//                 fontSize: { xs: "13px", sm: "14px", md: "16px" },
//               }}
//             >
//               Dont Have an Account?{" "}
//               <Link
//                 to="/signup"
//                 style={{
//                   color: theme.palette.secondary.main,
//                 }}
//               >
//                 Create your account
//               </Link>
//             </Typography>
//             <div className="error">{resError && resError}</div>
//             <div className="formHolder">
//               <TextField
//                 error={formError && !formData.email}
//                 fullWidth
//                 onChange={handleFormChange}
//                 value={formData.email || ""}
//                 name="email"
//                 label="Email"
//                 variant="outlined"
//                 sx={{ backgroundColor: "#F5F5F5", borderRadius: "20px" }}
//               />
//               {formError && !formData.email && (
//                 <span style={{ color: "red" }}>This field is required</span>
//               )}
//             </div>
//             <div className="formHolder">
//               <TextField
//                 type={showPassword ? "text" : "password"}
//                 error={formError && !formData.password}
//                 fullWidth
//                 onChange={handleFormChange}
//                 value={formData.password || ""}
//                 name="password"
//                 label="Password"
//                 variant="outlined"
//                 sx={{
//                   backgroundColor: "#F5F5F5",
//                   borderRadius: "20px",
//                 }}
//                 InputProps={{
//                   endAdornment: (
//                     <InputAdornment position="end">
//                       <IconButton
//                         edge="end"
//                         aria-label="toggle password visibility"
//                         onClick={() => setShowPassword(!showPassword)}
//                       >
//                         {showPassword ? <VisibilityOff /> : <Visibility />}
//                       </IconButton>
//                     </InputAdornment>
//                   ),
//                 }}
//               />
//               {formError && !formData.password && (
//                 <span style={{ color: "red" }}>This field is required</span>
//               )}
//             </div>
//             <Typography component="div" variant="body">
//               <Link
//                 to="/forgotpassword"
//                 style={{
//                   color: isHovered ? theme.palette.primary.main : "#424242",
//                   textDecoration: "none",
//                 }}
//                 onMouseEnter={() => setIsHovered(true)}
//                 onMouseLeave={() => setIsHovered(false)}
//               >
//                 Forgot Password?
//               </Link>
//             </Typography>
//             <Button
//               disableElevation
//               type="submit"
//               variant="contained"
//               disabled
//               size="small"
//               sx={{
//                 width: "40%",
//                 marginBottom: "15px",
//                 marginTop: "20px",
//               }}
//             >
//               LOGIN
//             </Button>
//           </Box>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Login;

//============previous code===========//

import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import authentication from "../apis/authentication";
import "../styles/pages/login.css";
import { objPropertyCheck } from "../utils/utils";
import { objHas } from "../utils/utils";
import { post, put, get } from "../../src/apis/apiMethods";
import { toast } from "react-toastify";
import useScrollToTopOnNavigation from "../hooks/useScrollToTopNavigation";
import { theme } from "../theme";

// const style={
//      header: {
//         "font-size": "3rem",
//         "font-weight": "bolder",
//         "color": "yellow",
//         "margin-bottom": "10px"
//       }
// }
const Login = () => {
  //   const navigate = useNavigate();
  useScrollToTopOnNavigation();
  const [formData, setFormData] = useState({});
  const [formError, setFormError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [resError, setResError] = useState("");
  const [settings, setSettings] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    return toast.error("Please contact admin!");
    if (!objPropertyCheck(formData, ["email", "password"]))
      return setFormError(true);
    try {
      const data = {
        email: formData.email,
        password: formData.password,
      };
      // console.log(data, "dataaaaa");
      const loginRes = await authentication.login(data);

      if (loginRes.status === 200) {
        toast.success(loginRes.data.message.split("_").join(" "));
        // console.log(loginRes.status, "loginRes");
        setFormData({});
        window.location.href = loginRes.data.meta.redirectUrl;
      }

      //navigate("/user/print");
    } catch (error) {
      // setResError(error);
      console.log("setResError", error);
    }
  };
  return (
    <>
      <div className="loginContainer">
        <div className="flexContainer">
          {/* {settings && (
          <img
            src={settings && objHas(settings, "logo.link", "")}
            alt="logo"
            style={{
              width: "260px",
              marginLeft: "-5px",
              marginTop: "-10px",
            }}
          />
        )}
        <div style={{ marginTop: "100px" }}>
          <div className="header">Login</div>
          <div className="subHeader">
            Dont Have an Account?{" "}
            <Link to={"/signup"}>Create your account</Link>
          </div>
          <div className="error">{resError && resError}</div>
          <div className="formContainer">
            <form onSubmit={handleSubmit}>
              <div className="formHolder">
                <TextField
                  error={formError && !formData.email}
                  fullWidth
                  onChange={handleFormChange}
                  value={formData.email || ""}
                  name="email"
                  label="Email"
                  variant="outlined"
                  sx={{ backgroundColor: "#F5F5F5" }}
                />
                {formError && !formData.email && (
                  <span style={{ color: "red" }}>This field is required</span>
                )}
              </div>
              <div className="formHolder">
                <TextField
                  type={showPassword ? "text" : "password"}
                  error={formError && !formData.password}
                  fullWidth
                  onChange={handleFormChange}
                  value={formData.password || ""}
                  name="password"
                  label="Password"
                  variant="outlined"
                  sx={{
                    backgroundColor: "#F5F5F5",
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {formError && !formData.password && (
                  <span style={{ color: "red" }}>This field is required</span>
                )}
              </div>
              <div className="textBtnContainer">
                <div className="forgotText">
                  <Link
                    to="/forgotpassword"
                    style={{
                      textDecoration: "none",
                    }}
                  >
                    Forgot Password?
                  </Link>
                </div>

                <div className="btn">
                  <Button variant="contained" type="submit">
                    LOGIN
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div> */}

          <Box
            component={"form"}
            onSubmit={handleSubmit}
            p="35px 20px"
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              width: { xs: 340, sm: 390, md: 420, lg: 420 },
              border: `1px solid ${theme.palette.primary.main}`,
              backgroundColor: "#fff",
              borderRadius: "8px",
            }}
          >
            <Typography
              variant="h5"
              textAlign="center"
              color={theme.palette.primary.main}
            >
              Login
            </Typography>

            <Typography
              variant="body"
              textAlign="center"
              m={2}
              sx={{
                fontSize: { xs: "13px", sm: "14px", md: "16px" },
              }}
            >
              Dont Have an Account?{" "}
              <Link
                to="/signup"
                style={{
                  color: theme.palette.secondary.main,
                }}
              >
                Create your account
              </Link>
            </Typography>

            <div className="error">{resError && resError}</div>
            <div className="formHolder">
              <TextField
                // size="small"
                error={formError && !formData.email}
                fullWidth
                onChange={handleFormChange}
                value={formData.email || ""}
                name="email"
                label="Email"
                variant="outlined"
                sx={{
                  // backgroundColor: "#F5F5F5",
                  borderRadius: "20px",
                }}
              />
              {formError && !formData.email && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
            </div>
            <div className="formHolder">
              <TextField
                // size="small"
                type={showPassword ? "text" : "password"}
                error={formError && !formData.password}
                fullWidth
                onChange={handleFormChange}
                value={formData.password || ""}
                name="password"
                label="Password"
                variant="outlined"
                sx={{
                  // backgroundColor: "#F5F5F5",
                  borderRadius: "20px",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {formError && !formData.password && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
            </div>

            <div className="formHolder">
              <Typography component="div" variant="body">
                <Link
                  to="/forgotpassword"
                  style={{
                    color: isHovered ? theme.palette.primary.main : "#424242",
                    textDecoration: "none",
                  }}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  Forgot Password?
                </Link>
              </Typography>
            </div>

            <Button
              disableElevation
              type="submit"
              variant="contained"
              size="small"
              sx={{
                width: "40%",
                margin: "16px 0",
                "&.MuiButtonBase-root": { borderRadius: "5px" },
              }}
            >
              Login
            </Button>
          </Box>
        </div>
      </div>
    </>
  );
};

export default Login;
