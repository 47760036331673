import Styles from "./Card.module.css";
import React, { useState } from "react";
import { useSpring, animated } from "react-spring";
import { PMP_CERTIFICATE } from "../helpers/pmp-certificate/PMP-Certificate";
import { keyframes } from "@emotion/react";

const slideInFromLeftAnimation = keyframes`
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
`;

function Cards({ imagen }) {
  const [show, setShown] = useState(false);

  const props3 = useSpring({
    transform: show ? "scale(1.03)" : "scale(1)",
    boxShadow: show
      ? "0 20px 25px rgb(0 0 0 / 25%)"
      : // ? "0 20px 25px rgb(0 0 0 / 25%)"
        "0 2px 10px rgb(0 0 0 / 8%)",
    // : "0 2px 10px rgb(0 0 0 / 8%)",
    animation: "cubic-bezier(0.25, 0.1, 0.25, 1)",
    animationDelay: "2s",
  });
  return (
    <animated.div
      className={Styles.card}
      style={props3}
      onMouseEnter={() => setShown(true)}
      onMouseLeave={() => setShown(false)}
    >
      {/* <img src={imagen} alt="" /> */}
      <PMP_CERTIFICATE />
    </animated.div>
  );
}

export default Cards;
