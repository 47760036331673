// import React, { useState } from "react";
// import {
//   Box,
//   Container,
//   Grid,
//   Typography,
//   keyframes,
//   styled,
//   Button,
// } from "@mui/material";
// import cardList from "./cardData";
// import icon1 from "../../assets/Images/icon1.png";
// import icon2 from "../../assets/Images/icon2.png";
// import icon3 from "../../assets/Images/icon3.png";
// import icon4 from "../../assets/Images/icon4.png";

// const HomeCard = () => {
//   const [selectedCard, setSelectedCard] = useState(null);
//   const slideInFromLeft = keyframes`
//   0% {
//     transform: translateX(-100%);
//     opacity: 0;
//   }
//   100% {
//     transform: translateX(0);
//     opacity: 1;
//   }
// `;

//   const slideInFromTop = keyframes`
//   0% {
//     transform: translateY(-100%);
//     opacity: 0;
//   }
//   100% {
//     transform: translateY(0);
//     opacity: 1;
//   }
// `;

//   const slideInFromBottom = keyframes`
//   0% {
//     transform: translateY(100%);
//     opacity: 0;
//   }
//   100% {
//     transform: translateY(0);
//     opacity: 1;
//   }
// `;

//   const slideInFromRight = keyframes`
//   0% {
//     transform: translateX(100%);
//     opacity: 0;
//   }
//   100% {
//     transform: translateX(0);
//     opacity: 1;
//   }
// `;

//   const SlidingBox = styled(Container)(({ theme, isselected }) => ({
//     width: "100%",
//     // height: "200px",
//     backgroundColor: "#009974",
//     color: "#fff",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     marginTop: "5%",
//     borderRadius: "5px",
//     marginBottom: "5%",
//     animation: isselected ? `${slideInFromBottom} 0.5s ease` : "none",
//   }));

//   const MainBox = styled(Box)(({ theme, isselected, index }) => ({
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     color: "#9e9e9e",
//     // color: "#ffff",
//     textAlign: "center",
//     transition: "1s",
//     border: isselected ? "10px solid #009974" : "none",
//     animation: isselected ? getAnimation(index) : "none",

//     "&:hover": {
//       transform: "scale(1.1)",
//       "& img": {
//         filter:
//           "brightness(0) saturate(100%) invert(71%) sepia(17%) saturate(7473%) hue-rotate(353deg) brightness(104%) contrast(104%)",
//         transition: "all 0.3s ease",
//       },
//       "& h6": {
//         color: "#009974",
//         fontFamily: "Quicksand",
//       },
//     },
//   }));

//   const Title = styled(Typography)(() => ({
//     fontSize: 20,
//     fontFamily: "Quicksand",
//     color: "#fb8c00",
//     fontWeight: "bold",
//   }));
//   const styles = {
//     button: {
//       // border: "1px solid red",
//       background: "none",
//       border: "0",
//       boxSizing: "border-box",
//       margin: "1em",
//       padding: "1em 2em",
//       boxShadow: "inset 0 0 0 2px  #ffff",

//       fontSize: "inherit",
//       fontWeight: "700",
//       position: "relative",
//       verticalAlign: "middle",

//       "&::before, &::after": {
//         boxSizing: "inherit",
//         content: "''",
//         position: "absolute",
//         width: "100%",
//         height: "100%",
//       },

//       draw: {
//         transition: "color 0.25s",
//         "&::before, &::after": {
//           border: "2px solid transparent",
//           width: 0,
//           height: 0,
//         },
//         "&::before": {
//           top: 0,
//           left: 0,
//         },
//         " &::after": {
//           bottom: 0,
//           right: 0,
//         },

//         "&:hover": {
//           color: " #60daaa",
//         },

//         "&::before, &::after": {
//           width: "100%",
//           height: "100%",
//         },
//         "&:hover::before": {
//           borderTopColor: " #60daaa",
//           borderRightColor: " #60daaa",
//           transition: "width 0.25s ease-out, height 0.25s ease-out 0.25s",
//         },

//         "&:hover::after": {
//           borderBottomColor: " #60daaa",
//           borderLeftColor: " #60daaa",
//           transition:
//             "border-color 0s ease-out 0.5s, width 0.25s ease-out 0.5s, height 0.25s ease-out 0.75s",
//         },
//       },
//     },
//   };
//   const RegisterButton = styled(Button)(({ theme }) => ({
//     color: "#ffff",
//     // ...styles.button,
//     "&:hover": {
//       ...styles.button.draw,
//     },

//     // "&::before, &::after": {
//     //   width: "100%",
//     //   height: "100%",
//     // },

//     // "&:hover::before": {
//     //   borderTopColor: " #60daaa",
//     //   borderRightColor: " #60daaa",
//     //   transition: "width 0.25s ease-out, height 0.25s ease-out 0.25s",
//     // },

//     // "&:hover::after": {
//     //   borderBottomColor: " #60daaa",
//     //   borderLeftColor: " #60daaa",
//     //   transition:
//     //     "border-color 0s ease-out 0.5s, width 0.25s ease-out 0.5s, height 0.25s ease-out 0.75s",
//     // },
//   }));
//   const getAnimation = (index) => {
//     switch (index) {
//       case 0:
//         return `${slideInFromLeft} 0.5s ease`;
//       case 1:
//         return `${slideInFromTop} 0.5s ease`;
//       case 2:
//         return `${slideInFromBottom} 0.5s ease`;
//       case 3:
//         return `${slideInFromRight} 0.5s ease`;
//       default:
//         return "none";
//     }
//   };
//   const handleCardClick = (index) => {
//     setSelectedCard((prevSelectedCard) =>
//       prevSelectedCard === index ? null : index
//     );
//   };

//   let icons = [icon1, icon2, icon3, icon4];

//   return (
//     <Box sx={{ backgroundColor: "#ffff" }}>
//       <Container maxWidth="xl">
//         <Grid container spacing={4}>
//           {cardList.map((d, i) => (
//               <Grid item xs={12} sm={6} md={3} key={i}>
//                 <MainBox
//                   py={8}
//                   onClick={() => handleCardClick(i)}
//                   isselected={selectedCard === i ? "true" : undefined}
//                   index={i}
//                 >
//                   <img
//                     src={icons[i]}
//                     style={{ height: 50, color: "black" }}
//                     alt="icon"
//                   />
//                   <Box ml={1} mt={2}>
//                     <Title variant="h6" mt={1}>{d.title}</Title>
//                     <Typography variant="body1" mt={1}>{d.desc}</Typography>
//                   </Box>
//                 </MainBox>
//               </Grid>
//           ))}
//         </Grid>

//         {selectedCard !== null && (
//           <SlidingBox isselected={selectedCard !== null ? "true" : "false"}>
//             <Grid container>
//               <Grid item xs={12} md={12} lg={12}>
//                 <Box ml={1} mt={1}>
//                   <Typography variant="h6" fontSize={15}>
//                     {cardList[selectedCard].heading}
//                   </Typography>
//                   <Typography variant="body1">
//                     {cardList[selectedCard].content}
//                   </Typography>
//                 </Box>
//               </Grid>
//               <Grid
//                 item
//                 xs={12}
//                 md={12}
//                 lg={12}
//                 display="flex"
//                 justifyContent="center"
//                 p={1}
//               >
//                 <Box sx={styles.button}>
//                   <RegisterButton variant="outlined">Register</RegisterButton>
//                 </Box>
//               </Grid>
//             </Grid>
//           </SlidingBox>
//         )}
//       </Container>
//     </Box>
//   );
// };

// export default HomeCard;

// // const RegisterButton = styled(Button)(() => ({
// //   color: "#ffff",
// //   border: "1px solid #ffff",
// //   "&:hover": {
// //     animation: `${shine} 1s linear infinite`,
// //     background:
// //       "linear-gradient(to right, #FFFF 20%, #FFFF 40%, #FFFF00 50%, #FFFF00 55%, #FFFF 70%, #FFFF 100%)",
// //     backgroundSize: "200% auto",
// //     color: "#009974",
// //     border: "1px solid #009974",
// //   },
// // }));

// // const shine = keyframes`
// //   to {
// //     background-position: 200% center;
// //   }
// // `;

// {
//   /* {selectedCard !== null && (
//           <SlidingBox isselected={selectedCard !== null ? "true" : "false"}>
//             <Grid container>
//               <Grid item xs={12} md={12} lg={12}>
//                 <Box ml={1} mt={1}>
//                   <Typography variant="h6" fontSize={15}>
//                     {cardList[selectedCard].heading}
//                   </Typography>
//                   <Typography variant="body1">
//                     {cardList[selectedCard].content}
//                   </Typography>
//                 </Box>
//               </Grid>
//             </Grid>
//           </SlidingBox>
//         )} */
// }

//===============previous code====================//

import React, { useState } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  keyframes,
  styled,
  Button,
} from "@mui/material";
import cardList from "./cardData";
import icon1 from "../../assets/Images/icon1.png";
import icon2 from "../../assets/Images/icon2.png";
import icon3 from "../../assets/Images/icon3.png";
import icon4 from "../../assets/Images/icon4.png";
import { Link } from "react-router-dom";
import { theme } from "../../theme";

const HomeCard = () => {
  const [selectedCard, setSelectedCard] = useState(null);
  const slideInFromLeft = keyframes`
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

  const slideInFromTop = keyframes`
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

  const slideInFromBottom = keyframes`
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

  const slideInFromRight = keyframes`
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

  const SlidingBox = styled(Container)(({ theme, isselected }) => ({
    width: "100%",
    // height: "200px",
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "5%",
    borderRadius: "5px",
    marginBottom: "5%",
    animation: isselected ? `${slideInFromBottom} 0.5s ease` : "none",
  }));

  const MainBox = styled(Box)(({ theme, isselected, index }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "#9e9e9e",
    // color: "#ffff",
    textAlign: "center",
    transition: "1s",
    border: isselected ? `1px solid ${theme.palette.primary.main}` : "none",
    animation: isselected ? getAnimation(index) : "none",

    "&:hover": {
      transform: "scale(1.1)",
      "& img": {
        filter:
          "brightness(0) saturate(100%) invert(71%) sepia(17%) saturate(7473%) hue-rotate(353deg) brightness(104%) contrast(104%)",
        transition: "all 0.3s ease",
      },
      "& h6": {
        color: theme.palette.primary.main,
        fontFamily: "Quicksand",
      },
    },
  }));

  const Title = styled(Typography)(() => ({
    fontSize: 19,
    fontFamily: "Quicksand",
    color: theme.palette.secondary.main,
    fontWeight: "bold",
  }));
  const styles = {
    button: {
      // border: "1px solid red",
      background: "none",
      border: "0",
      boxSizing: "border-box",
      margin: "1em",
      padding: "1em 2em",
      boxShadow: "inset 0 0 0 2px  #ffff",

      fontSize: "inherit",
      fontWeight: "700",
      position: "relative",
      verticalAlign: "middle",

      "&::before, &::after": {
        boxSizing: "inherit",
        content: "''",
        position: "absolute",
        width: "100%",
        height: "100%",
      },

      draw: {
        transition: "color 0.25s",
        "&::before, &::after": {
          border: "2px solid transparent",
          width: 0,
          height: 0,
        },
        "&::before": {
          top: 0,
          left: 0,
        },
        " &::after": {
          bottom: 0,
          right: 0,
        },

        "&:hover": {
          color: " #60daaa",
        },

        "&::before, &::after": {
          width: "100%",
          height: "100%",
        },
        "&:hover::before": {
          borderTopColor: " #60daaa",
          borderRightColor: " #60daaa",
          transition: "width 0.25s ease-out, height 0.25s ease-out 0.25s",
        },

        "&:hover::after": {
          borderBottomColor: " #60daaa",
          borderLeftColor: " #60daaa",
          transition:
            "border-color 0s ease-out 0.5s, width 0.25s ease-out 0.5s, height 0.25s ease-out 0.75s",
        },
      },
    },
  };
  const RegisterButton = styled(Button)(({ theme }) => ({
    color: "#ffff",
    // ...styles.button,
    "&:hover": {
      ...styles.button.draw,
    },

    // "&::before, &::after": {
    //   width: "100%",
    //   height: "100%",
    // },

    // "&:hover::before": {
    //   borderTopColor: " #60daaa",
    //   borderRightColor: " #60daaa",
    //   transition: "width 0.25s ease-out, height 0.25s ease-out 0.25s",
    // },

    // "&:hover::after": {
    //   borderBottomColor: " #60daaa",
    //   borderLeftColor: " #60daaa",
    //   transition:
    //     "border-color 0s ease-out 0.5s, width 0.25s ease-out 0.5s, height 0.25s ease-out 0.75s",
    // },
  }));
  const getAnimation = (index) => {
    switch (index) {
      case 0:
        return `${slideInFromLeft} 0.5s ease`;
      case 1:
        return `${slideInFromTop} 0.5s ease`;
      case 2:
        return `${slideInFromBottom} 0.5s ease`;
      case 3:
        return `${slideInFromRight} 0.5s ease`;
      default:
        return "none";
    }
  };
  const handleCardClick = (index) => {
    setSelectedCard((prevSelectedCard) =>
      prevSelectedCard === index ? null : index
    );
  };

  let icons = [icon1, icon2, icon3, icon4];

  return (
    <Box
      sx={{
        backgroundColor: "#ffff",
        padding: { xs: "0 0", sm: "0 0", md: "0 50px" },
      }}
    >
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          {cardList.map((d, i) => (
            <Grid item xs={12} sm={6} md={3} key={i}>
              <Link to={d.path} style={{ textDecoration: "none" }}>
                <MainBox
                  py={4}
                  onClick={() => handleCardClick(i)}
                  isselected={selectedCard === i ? "true" : undefined}
                  index={i}
                >
                  <img
                    src={icons[i]}
                    style={{ height: 50, color: "black" }}
                    alt="icon"
                  />
                  <Box ml={1} mt={2}>
                    <Title variant="h6" mt={1}>
                      {d.title}
                    </Title>
                    <Typography variant="body1">{d.content}</Typography>
                  </Box>
                </MainBox>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default HomeCard;
