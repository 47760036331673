import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  styled,
  Button,
} from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import FormSelect from "../../form/FormSelect";
import * as Yup from "yup";
import { useFormik } from "formik";
import { SelectClassroom } from "./SelectClassroom";
import { countriesData } from "countries-cities";
import { RequestModel } from "./RequestModel";
import { theme } from "../../theme";
import ProgramNames from "../../data/programmName";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "grey" : "#308fe8",
  },
}));

const Arrow = styled(Box)(() => ({
  height: 30,
  width: 30,
  border: "1px solid grey",
  borderRadius: "50%",
  backgroundColor: "#009974",
  color: "#fafafa",
  fontWeight: "bold",
}));

const ArrowBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  marginTop: -55,
  [theme.breakpoints.down("sm")]: {
    marginTop: -20,
  },
}));

const ArrowBack = styled(ArrowBackIcon)(() => ({
  marginLeft: 2,
  marginTop: 2,
  fontSize: "1.4rem",
}));
const ArrowForward = styled(ArrowForwardIcon)(({ theme }) => ({
  marginLeft: 3,
  marginTop: 2,
  fontSize: "1.4rem",
}));

const Salary = styled(Typography)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  fontSize: "0.9rem",
  color: "#009974",
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: -25,
  },
  [theme.breakpoints.down("md")]: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: -30,
  },
}));

const Date = styled(Typography)(({ theme }) => ({
  fontSize: 13,
}));

const Classroom = ({
  programmeName,
  classRoomInfo,
  openContact,
  handleClose,
  handleOpen,
  openRequest,
  handleOpenRequest,
  selectedCourse,
  courseTitle,
}) => {
  const [allCountries, setAllCountries] = useState(
    require("countries-cities").getCountries()
  );
  const [allCities, setAllcities] = useState([]);
  const [selectedCardIndex1, setSelectedCardIndex1] = useState(null);
  const [submittingForm, setSubmittingForm] = useState(false);

  const sliderRef = useRef(null);

  const selectState = useFormik({
    initialValues: {
      selectCountry: "",
      selectCity: "",
    },
    validationSchema: Yup.object({
      selectCountry: Yup.string().required().label("Select Country"),
      selectCity: Yup.string().required().label("Select City"),
    }),
    onSubmit: (values) => {
      console.log(values);
    },
    validateOnBlur: false,
  });

  useEffect(() => {
    setAllcities(
      require("countries-cities").getCities(selectState.values.selectCountry)
    );
  }, [selectState.values.selectCountry]);

  return (
    <>
      <Grid
        container
        spacing={2}
        p={2}
        // sx={{ padding: { xs: 3, md: 6 }, mt: { xs: 0, sm: -3, md: -10 } }}
      >
        {selectedCourse.programmeDates &&
        selectedCourse.programmeDates.includes("classRoom") ? (
          <>
            <Grid item xs={6} sm={6} md={6} lg={3}>
              <FormSelect
                formik={selectState}
                options={allCountries?.map((c) => ({ label: c, value: c }))}
                name="selectCountry"
                type="text"
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={3}>
              <FormSelect
                formik={selectState}
                options={allCities?.map((c) => ({ label: c, value: c }))}
                name="selectCity"
                type="text"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={6}
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                gap: 3,
              }}
            >
              <Typography variant="subtitle1" mt={1}>
                Can't find convenient schedule? Let Us Know
              </Typography>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  height: "fit-content",
                  mb: { xs: "20px", md: "0" },
                }}
                onClick={handleOpenRequest}
              >
                Request
              </Button>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <SelectClassroom
                programmeName={programmeName}
                classRoomInfo={classRoomInfo}
                openRequest={openRequest}
                openContact={openContact}
                handleOpen={handleOpen}
                handleClose={handleClose}
                courseTitle={courseTitle}
              />
            </Grid>
            <RequestModel
              openRequest={openRequest}
              handleClose={handleClose}
              selectState={selectState}
              programmeName={programmeName?.title}
            />
          </>
        ) : (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              fontFamily: "Inter",
              textAlign: "center",
              flexDirection: { xs: "column", md: "row" },
              marginTop: "40px",
            }}
          >
            <Typography sx={{ fontSize: 20 }}>
              {ProgramNames["Classroom"]} information is not available for this
              course
            </Typography>
          </Box>
        )}
      </Grid>
    </>
  );
};

export default Classroom;
