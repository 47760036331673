import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Box } from "@mui/material";
import { styled, ThemeProvider } from "@mui/material/styles";
import { HelmetProvider } from "react-helmet-async";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { theme } from "./theme";
import "animate.css/animate.min.css";
import "./globle.css";

import "react-toastify/dist/ReactToastify.css";

import AuthContext from "./auth/context";

// Pages
import Home from "./pages/Home";
import PageNotFound from "./pages/PageNotFound";
import Navbar from "./components/navbar/Navbar";
import OnlineSelf from "./pages/OnlineSelf";
import Classes from "./pages/selectUpcomingClass/Classes";
import Onsite from "./pages/Onsite";
import Classroom from "./pages/selectUpcomingClass/Classroom";
import OverView from "./pages/OverView";
import Footer from "./layouts/footer/Footer";
import AboutUs from "./pages/AboutUs";
import Contact from "./pages/Contact";
import TermsCondtions from "./pages/TermsCondtions";
import Details from "./components/Details/Details";
import PmpCertificate from "./pages/PmpCertificate";
import RefundPolicy from "./pages/RefundPolicy";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Faq from "./pages/Faq";
import InHouse from "./pages/InHouse";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MainAllClass } from "./pages/selectUpcomingClass/Main.components.allClass";
import { ContactBooknow } from "./pages/selectUpcomingClass/ContactBooknow";
import { BecomeInstructor } from "./pages/BecomeInstructor";
import { BecomeTrainingPartner } from "./pages/BecomeTrainingPartner";
import { ContactCourseInfo } from "./pages/selectUpcomingClass/ContactCourseInfo";
import { CourseContext, EnqueryBookdata } from "./context/CourseContext";
import AffiliateProgram from "./pages/AffiliateProgram";
import Login from "./components/loginPage";
import SignUp from "./components/signupPage";
// import ForgotPassworgotd from "./components/ForgotPassword";
import { PaymentFailure } from "./pages/payment/PaymentFailure";
import { PaymentSuccess } from "./pages/payment/PaymentSuccess";
import { ToastContainer } from "react-toastify";
import OnlineTraining from "./pages/OnlineTraining";
import OnlineClassroomTraining from "./pages/OnlineClassroomTraining";
import SelfLearning from "./pages/SelfLearning";
import ClassRoomTraining from "./pages/ClassroomTraining";
import CorporateTraining from "./pages/CorporateTraining";
import ForgotPassword from "./components/forgotPassword";
import WhatsApp from "./pages/WhatsApp";
import useCurrentCountry from "./hooks/useCurrentCountry";
import NavbarContext from "./context/NavbarContext";

const Screen = styled(Box)(() => ({
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
}));

function App() {
  const [user, setUser] = useState(null);
  const [isReady, setIsReady] = useState(false);
  const [courseId, setCourseId] = useState([]);
  const [enqueryBookdata, setEnqueryBookdata] = useState([]);
  const [navbarData, setNavbarData] = useState({
    show: false,
    title: "Hii",
  });

  // useCurrentCountry();

  // console.log(enqueryBookdata,"---llatte--enqueryBookdata");

  return (
    <>
      {/* <GoogleOAuthProvider clientId="<client-id>"> */}
      <NavbarContext.Provider value={{ navbarData, setNavbarData }}>
        <CourseContext.Provider value={{ courseId, setCourseId }}>
          <EnqueryBookdata.Provider
            value={{ enqueryBookdata, setEnqueryBookdata }}
          >
            <HelmetProvider>
              <ThemeProvider theme={theme}>
                <AuthContext.Provider value={{ user, setUser }}>
                  <ToastContainer />
                  <Screen>
                    <Router>
                      {/* <Navbar user={user} /> // create navbar and make it a global component*/}
                      <Navbar />
                      <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="*" element={<PageNotFound />} />
                        <Route path="/online-self" element={<OnlineSelf />} />
                        <Route path="/classroom" element={<Classroom />} />
                        <Route path="/onsite" element={<Onsite />} />
                        <Route path="/overview" element={<OverView />} />
                        <Route path="/about-us" element={<AboutUs />} />
                        <Route path="/contact-us" element={<Contact />} />
                        <Route
                          path="/terms&condition"
                          element={<TermsCondtions />}
                        />
                        <Route path="/login" element={<Login />} />
                        <Route path="/signup" element={<SignUp />} />
                        <Route
                          path="/forgotpassword"
                          element={<ForgotPassword />}
                        />
                        <Route path="/details" element={<Details />} />
                        <Route
                          path="/contactCourseInfo"
                          element={<ContactCourseInfo />}
                        />
                        {/* <Route path="/pmpcertificate" element={<PmpCertificate />} /> */}
                        <Route
                          path="/refundpolicy"
                          element={<RefundPolicy />}
                        />
                        <Route
                          path="/privacypolicy"
                          element={<PrivacyPolicy />}
                        />
                        <Route path="/faq" element={<Faq />} />
                        {/* <Route path="/inhouse" element={<InHouse />} /> */}

                        {/* =============== ===============================*/}
                        <Route path="/all-classes" element={<MainAllClass />} />
                        <Route path="/booknow" element={<ContactBooknow />} />
                        <Route
                          path="/become-an-instructor"
                          element={<BecomeInstructor />}
                        />
                        <Route
                          path="/become-training-partner"
                          element={<BecomeTrainingPartner />}
                        />
                        <Route
                          path="/affiliate-program"
                          element={<AffiliateProgram />}
                        />
                        <Route
                          path="/payment-failed"
                          element={<PaymentFailure />}
                        />
                        <Route
                          path="/payment-success"
                          element={<PaymentSuccess />}
                        />

                        <Route
                          path="/online-training"
                          element={<OnlineTraining />}
                        />
                        <Route
                          path="/onlineclassroom-training"
                          element={<OnlineClassroomTraining />}
                        />
                        <Route
                          path="/classroom-training"
                          element={<ClassRoomTraining />}
                        />

                        <Route
                          path="/corporate-training"
                          element={<CorporateTraining />}
                        />
                        {/* <Route
                          path="/self-learning"
                          element={<SelfLearning />}
                        /> */}
                      </Routes>
                      {/* <Footer />  // create footer and make it a global component*/}
                      <Footer />
                    </Router>
                    {/* <Box
                      sx={{
                        width: 50,
                        height: 50,
                        backgroundColor: "green",
                        position: "fixed",
                        bottom: 20,
                        right: 20,
                        borderRadius: 50,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <WhatsApp />
                    </Box> */}
                  </Screen>
                </AuthContext.Provider>
              </ThemeProvider>
            </HelmetProvider>
          </EnqueryBookdata.Provider>
        </CourseContext.Provider>
      </NavbarContext.Provider>
      {/* </GoogleOAuthProvider> */}
    </>
  );
}

export default App;
