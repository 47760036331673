import React, { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { theme } from "../theme";
import { post } from "../apis/apiMethods";
import urls from "../apis/urlContants";
import { useFormik } from "formik";
import FormInput from "../form/FormInput";
import * as Yup from "yup";
import { Box, Typography, keyframes, styled } from "@mui/material";

const fadeInAnimation = keyframes`
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      `;

const Form = styled(Box)(() => ({
  padding: 20,
  borderRadius: "5px",
  marginTop: 20,
  background: "white",
  border: `5px solid ${theme.palette.secondary.main}`,
}));

const Querys = styled(Box)(({ theme }) => ({
  width: "80%",
  animation: `${fadeInAnimation} 2s ease-out forwards`,
  marginTop: "40px",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));
const Text = styled(Typography)(({ theme }) => ({
  color: "black",
  lineHeight: "24px",
  display: "flex",
  fontFamily: "Inter",
  fontSize: 30,
  fontWeight: 700,
  justifyContent: "center",
  alignItems: "center",
  letterSpacing: "2px",
  marginBottom: "35px",
}));

function ContactDropusForm({ subject = "" }) {
  const [submittingForm, setSubmittingForm] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      contactNumber: "",
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required().label("Name").max(150),
      email: Yup.string().email().required().label("Email").max(150),
      contactNumber: Yup.number()
        .nullable()
        .required()
        .label("Contact Number")
        .min(1000000000, "Invalid contact number")
        .max(999999999999, "Invalid contact number"),
      message: Yup.string().required().label("Message"),
    }),
    onSubmit: async (values) => {
      try {
        const body = {
          subject: subject || "Drop Us a Query Details",
          customerDetails: {
            name: values.name,
            email: values.email,
            contactNumber: values.contactNumber,
          },
          enquiryDetails: {
            programmeDetails: {},
          },
          message: values.message,
        };
        setSubmittingForm(true);
        const { data } = await post(urls.enquiry.createForm, body);
        formik.resetForm();
      } catch (error) {
        console.error(error);
      }
      setSubmittingForm(false);
    },
    validateOnBlur: false,
    enableReinitialize: true,
  });
  return (
    <>
      <Querys>
        <Form component={"form"} onSubmit={formik.handleSubmit} boxShadow={4}>
          <Text my={2}>Drop Us a Query</Text>

          <FormInput
            formik={formik}
            name="name"
            type="text"
            label="Full Name"
          />
          <FormInput formik={formik} name="email" type="email" label="Email" />

          <FormInput
            formik={formik}
            name="contactNumber"
            label="Contact No."
            type="number"
          />

          <FormInput
            formik={formik}
            name="message"
            type="text"
            label="Your Message"
          />
          <LoadingButton
            disableElevation
            type="submit"
            variant="contained"
            loading={submittingForm}
            size="small"
            color="secondary"
            sx={{
              width: "40%",
              "&.MuiButtonBase-root": { borderRadius: 20 },
              my: 2,
            }}
          >
            Send
          </LoadingButton>
        </Form>
        <Box pb={2} />
      </Querys>
    </>
  );
}

export default ContactDropusForm;
