const urls = {
  course: {
    getCourse: "course/list",
    createCourse: "course/create",
    valueCourse: "course/getOnevalue",
    getCoursesNames: "course/getCoursesNames",
    list: "course/list",
    courseNameList: "course/courseNameList",
  },
  enquiry: {
    createForm: "/enquiry/create",
  },
  menu: {
    getMenuList: "/menu/list",
  },
  auth: {
    genOtp: "/account/generateResetPasswordOtp",
    resetPassword: "/account/resetForgotPassword",
    sendEmailConfirmationCode: "/account/sendEmailConfirmationCode",
    verifyEmailCode: "/account/verifyEmailCode",
  },
  payment: {
    order: "/payment/order",
    confirm: "/payment/confirmpp",
  },
  coupan: {
    list: "/coupan/list",
    checkCoupan: "/coupan/checkCoupan",
  },
};

export default urls;
