import { Box, Container, Grid, Typography, styled } from "@mui/material";
import React from "react";
import { BiLogoFacebook } from "react-icons/bi";
import { TiSocialLinkedin } from "react-icons/ti";
import { LiaInstagram } from "react-icons/lia";
import { LiaTwitter } from "react-icons/lia";
import { FaXTwitter } from "react-icons/fa6";
import { LiaYoutube } from "react-icons/lia";
import Divider from "@mui/material/Divider";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import Appstore from "../../assets/Images/appstore.png";
import GoogleStore from "../../assets/Images/googlestore.png";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Link } from "react-router-dom";
const FaceBook = styled(BiLogoFacebook)(() => ({
  height: 33,
  width: 33,
  borderRadius: "50%",
  border: "1px solid #353535 ",
  background: "#353535 ",
  color: "white",
  "&:hover": {
    backgroundColor: "#4c67a1",
  },
}));
const Twitter = styled(FaXTwitter)(() => ({
  height: 33,
  width: 33,
  padding: 4,
  borderRadius: "50%",
  border: "1px solid #353535 ",
  background: "#353535",
  color: "white",
  "&:hover": {
    backgroundColor: "black",
  },
}));
const Youtube = styled(LiaYoutube)(() => ({
  height: 33,
  width: 33,
  borderRadius: "50%",
  border: "1px solid #353535 ",
  background: "#353535 ",
  color: "white",
  "&:hover": {
    backgroundColor: "#de2925",
  },
}));
const LinkedIn = styled(TiSocialLinkedin)(() => ({
  height: 33,
  width: 33,
  borderRadius: "50%",
  border: "1px solid #353535 ",
  background: "#353535 ",
  color: "white",
  "&:hover": {
    backgroundColor: "#1b86bd",
  },
}));
const Telegram = styled(Box)(() => ({
  height: 33,
  width: 33,
  borderRadius: "50%",
  border: "1px solid #353535 ",
  background: "#353535 ",
  color: "white",

  "&:hover": {
    backgroundColor: "#1b86bd",
  },
}));
const Insta = styled(LiaInstagram)(() => ({
  height: 33,
  width: 33,
  borderRadius: "50%",
  border: "1px solid #353535 ",
  background: "#353535 ",
  color: "white",
  "&:hover": {
    background:
      "radial-gradient(circle at 23% 84%, #f9ed32, #f8d939 5%, #f5a54d 17%, #f0536c 33%, #ee2a7b 40%, #9e2aa7 56%, #5b2acd 72%, #2a2ae8 85%, #0b2af9 95%, #002aff)",
    color: "white",
  },
}));

const FooterTittle = styled(Typography)(({ theme }) => ({
  color: "#ffff",
  // fontFamily: "muli",
  fontFamily: "Quicksand",
}));

const IconsBox = styled(Box)(({ theme }) => ({
  gap: 10,
  display: "flex",
  marginTop: 15,
  marginLeft: -5,
}));

const Content = styled(Typography)(({ theme }) => ({
  color: "grey",
  marginTop: 15,
  fontSize: "15px",
  // fontFamily: "muli",
  fontFamily: "Inter",
}));

const RoboAppleContent = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  textAlign: "center",
  [theme.breakpoints.down("md")]: {
    justifyContent: "center",
    textAlign: "center",
    // width:'50%'
  },
  [theme.breakpoints.down("sm")]: {
    justifyContent: "center",
    textAlign: "center",
    // width:'50%'
  },
}));
const Border = styled(Box)(({ theme }) => ({
  // border: "1px solid grey",
  // borderRadius: "5px",
  display: "flex",
  flexDirection: "column",
  gap: 8,
  marginTop: 15,
  [theme.breakpoints.down("md")]: {
    justifyContent: "center",
    textAlign: "center",
  },
}));

const CopyRight = styled(Box)(() => ({
  backgroundColor: "whitesmoke",
}));

const footer = [
  {
    id: "1",
    heading: "Follow Us!",
  },
  {
    id: "2",
    heading: "Support",
    subhead: ["About Us", "FAQ", "Contact Us"],
  },
  {
    id: "3",
    heading: "Join us",
    subhead: [
      "Become a Training Partner",
      "Become an instructor",
      "Affiliate Program",
    ],
  },
  {
    id: "4",
    heading: "Services",
    subhead: ["Corporate training"],
  },
  {
    id: "5",
    heading: "Company",
    subhead: ["Terms & Condtions", "Privacy Policy", "Refund Policy"],
  },
  {
    id: "6",
    heading: "Learn on the Go!",
  },
];

const Footer = () => {
  return (
    <>
      <Box bgcolor="black">
        <Container maxWidth="xl">
          <Grid container spacing={5} p={5}>
            <Grid item xs={6} sm={3} lg={2}>
              <FooterTittle>Follow Us!</FooterTittle>
              <IconsBox>
                <a
                  href="https://www.facebook.com/profile.php?id=61554722904419"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaceBook />
                </a>
                <a
                  href="https://twitter.com/aphia_learning"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Twitter />
                </a>
                <Youtube />
              </IconsBox>
              <IconsBox>
                <LinkedIn />
                {/* <Telegram>
                  <img
                    height={20}
                    width={20}
                    style={{
                      marginTop: "5px",
                      marginLeft: "4px",
                    }}
                    src="https://www.simplilearn.com/ice9/assets/telegram_logo.svgz"
                    alt=""
                  />
                </Telegram> */}
                {/* <Insta /> */}
                <a
                  href="https://www.instagram.com/aphia_learning/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Insta />
                </a>
              </IconsBox>
            </Grid>
            <Grid item xs={6} sm={3} lg={2}>
              <Box>
                <FooterTittle>Support</FooterTittle>
                <Link
                  to="/about-us"
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <Content>About Us</Content>
                </Link>
                <Link
                  to="/faq"
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <Content>FAQ</Content>
                </Link>
                <Link
                  to="/contact-us"
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <Content>Contact Us</Content>
                </Link>
              </Box>
            </Grid>
            <Grid item xs={6} sm={3} lg={2}>
              <Box>
                <FooterTittle>Join us</FooterTittle>
                <Link
                  style={{
                    textDecoration: "none",
                  }}
                  to="/become-training-partner"
                >
                  <Content>Become a Training Partner</Content>
                </Link>
                <Link
                  style={{
                    textDecoration: "none",
                  }}
                  to="/become-an-instructor"
                >
                  <Content>Become an instructor</Content>
                </Link>

                <Link
                  style={{
                    textDecoration: "none",
                  }}
                  to="/affiliate-program"
                >
                  <Content>Affiliate Program</Content>
                </Link>
              </Box>
            </Grid>
            <Grid item xs={6} sm={3} lg={2}>
              <Box>
                <FooterTittle>Services</FooterTittle>
                <Link
                  style={{
                    textDecoration: "none",
                  }}
                  to="/corporate-training"
                >
                  <Content>Corporate training</Content>
                </Link>
                <Link
                  style={{
                    textDecoration: "none",
                  }}
                  to="/online-training"
                >
                  <Content>Online training</Content>
                </Link>
                <Link
                  style={{
                    textDecoration: "none",
                  }}
                  to="/onlineclassroom-training"
                >
                  <Content>Online Class Room training</Content>
                </Link>
                <Link
                  style={{
                    textDecoration: "none",
                  }}
                  to="/classroom-training"
                >
                  <Content>Class Room training</Content>
                </Link>
              </Box>
            </Grid>
            <Grid item xs={6} sm={3} lg={2}>
              <FooterTittle>Company</FooterTittle>
              <Link
                style={{
                  textDecoration: "none",
                }}
                to="/terms&condition"
              >
                <Content>Terms & Condtions</Content>
              </Link>
              <Link
                style={{
                  textDecoration: "none",
                }}
                to="privacypolicy"
              >
                <Content>Privacy Policy</Content>
              </Link>
              <Link
                style={{
                  textDecoration: "none",
                }}
                to="/refundpolicy"
              >
                <Content>Refund Policy</Content>
              </Link>
            </Grid>
            <Grid item xs={6} sm={3} lg={2}>
              <FooterTittle>Learn on the Go!</FooterTittle>
              <Border>
                <img height="20%" src={Appstore} alt="Loading..." />
                <img height="20%" src={GoogleStore} alt="Loading..." />
              </Border>
            </Grid>
          </Grid>
        </Container>
        <Box bgcolor="#363636" textAlign="center" p={1}>
          <Content>
            Disclaimer: "PMI®", "PMBOK®", "PMP®", "PMI-RMP®", "CAPM®" and
            "PMI-ACP®" are registered marks of the Project Management Institute,
            Inc. Copyrights © 2023. Aphia. All Right Reserved.
          </Content>
        </Box>
      </Box>
    </>
  );
};

export default Footer;
