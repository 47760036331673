import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import {
  Collapse,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuList,
  Popover,
  SwipeableDrawer,
  // Select,
  styled,
} from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import MenuIcon from "@mui/icons-material/Menu";
import MoreIcon from "@mui/icons-material/MoreVert";
import { Link, useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import CallIcon from "@mui/icons-material/Call";
import MailIcon from "@mui/icons-material/Mail";
import { BiLogoFacebook } from "react-icons/bi";
import { LiaTwitter } from "react-icons/lia";
import { FaXTwitter } from "react-icons/fa6";
import { TiSocialLinkedin } from "react-icons/ti";
import { ArrowRight, StarBorder } from "@mui/icons-material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Dropdown, DropdownMenuItem, DropdownNestedMenuItem } from "./Dropdown";
import logo from "../../assets/Images/Aphia new Logo.png";
import urls from "../../apis/urlContants";
import { get, post, put } from "../../apis/apiMethods";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { CourseContext } from "../../context/CourseContext";
import Select, { components } from "react-select";
// import { Search as SearchIcon } from "@mui/icons-material";
import { Autocomplete } from "@mui/material";
import { theme } from "../../theme";
import NavbarContext from "../../context/NavbarContext";
import icon1 from "../../assets/Images/icon1.png";
import icon2 from "../../assets/Images/icon2.png";
import icon3 from "../../assets/Images/icon3.png";
import icon4 from "../../assets/Images/icon4.png";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { right } from "@popperjs/core";
import CloseIcon from "@mui/icons-material/Close";

var getImg = [icon1, icon2, icon3, icon4];

const pages = [
  {
    id: "1",
    path: "/",
    name: "Home",
  },
  {
    id: "2",
    path: "/about-us",
    name: "About",
  },
  {
    id: "3",
    path: "/corporate-training",
    name: "Corporate Training",
  },
];

const mobileNavmenu = [
  {
    id: "1",
    path: "/",
    name: "Home",
  },
  {
    id: "2",
    path: "/about-us",
    name: "About",
  },
  {
    id: "3",
    path: "/corporate-training",
    name: "Corporate Training",
  },
  {
    id: "4",
    path: "/signup",
    name: "Register",
  },
  {
    id: "5",
    path: "/login",
    name: "Login",
  },
];

const FaceBook = styled(BiLogoFacebook)(() => ({
  height: 18,
  width: 18,
  borderRadius: "50%",
  border: "1px solid #ffff ",
  background: "#ffff",
  color: theme.palette.primary.main,
  scale: "0px",
  transition: "1s",
  "&:hover": {
    transform: "scale(1.2)",
  },
}));

const Twitter = styled(FaXTwitter)(() => ({
  height: 18,
  width: 18,
  borderRadius: "50%",
  border: "1px solid #ffff ",
  background: "#ffff",
  color: theme.palette.primary.main,
  scale: "0px",
  transition: "1s",
  "&:hover": {
    transform: "scale(1.2)",
  },
}));

const LinkedIn = styled(TiSocialLinkedin)(() => ({
  height: 18,
  width: 18,
  borderRadius: "50%",
  border: "1px solid #ffff ",
  background: "#ffff",
  color: theme.palette.primary.main,
  scale: "0px",
  transition: "1s",
  "&:hover": {
    transform: "scale(1.2)",
  },
}));

const Caller = styled(CallIcon)(() => ({
  color: theme.palette.primary.main,
  scale: "0px",
  fontSize: "20px",
  transition: "1s",
  "&:hover": {
    transform: "scale(1.2)",
  },
}));

const Mail = styled(MailIcon)(() => ({
  color: theme.palette.primary.main,
  scale: "0px",
  transition: "1s",
  fontSize: "20px",
  "&:hover": {
    transform: "scale(1.2)",
  },
}));

const PhoneNo = styled(Typography)(() => ({
  fontSize: "16px",
  fontFamily: "Quicksand",
}));

const Email = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontFamily: "Quicksand",
  fontWeight: "bold",
  paddingBottom: "3px",
  // color: "black",
}));

const AnchorBox = styled(Box)({
  fontFamily: "Quicksand",
  fontWeight: 700,
  "&:hover": {
    transition: "transform 0.35s ease",
  },

  "&::after": {
    content: '""',
    height: "3px",

    marginTop: "4px",
    // backgroundColor: "#fbc02d",
    display: "block",
    fontSize: "1rem",
    transform: "scale(1,1)",
    transition: "transform 0.35s ease",
  },
});

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  border: "1px solid grey",
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  // width:{xs:'20%',sm:'50%'},
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    // sx={{margin: {xs:'0 auto', md:"0" },}}
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "grey",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const Login = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: theme.palette.primary.main,
  padding: "4px 30px",
  fontFamily: "Quicksand",

  fontWeight: 700,

  "&:hover": {
    backgroundColor: theme.palette.primary.main,
  },
}));

const Register = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.main,
  padding: "4px 15px",
  fontFamily: "Quicksand",

  fontWeight: 700,

  "&:hover": {
    color: "white",
    background: theme.palette.primary.main,
  },
  [theme.breakpoints.down("md")]: {
    color: "white",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

const MenuItemsContainer = styled(Box)(() => ({
  backgroundColor: "white",
  width: "100%",
  maxWidth: "280px",
  borderRadius: "2px",
  height: "500px",
  border: "1px solid lightgray",
  overflowY: "auto",
  overflowX: "hidden",
  display: "flex",
  flexDirection: "column",
}));

const MenuItems = styled(Box)(() => ({
  cursor: "pointer",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  width: "100%",
  borderBottom: "1px solid whitesmoke",
  padding: "8px",
}));

// ==============menu style
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 280,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

function Navbar() {
  const navigate = useNavigate();
  const { setCourseId } = React.useContext(CourseContext);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [courseList, setCourseList] = useState([]);
  const [menuList, setMenuList] = useState([]);
  const [searchList, setSearchList] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const location = useLocation();
  const [searchCourse, setSearchCourse] = useState("");
  const { navbarData, setNavbarData } = React.useContext(NavbarContext);
  const { selectedCourseTitle } = location.state || {};
  const [isSticky, setSticky] = useState(false);

  // ========================================================
  const [openDropDown, setOpenDropDown] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState("");
  const [selectedCourses, setSelectedCourses] = useState([]);
  const navbarRef = React.useRef(null);
  const [containerTop, setContainerTop] = useState(0);
  const [containerLeft, setContainerLeft] = useState(0);

  const [selectedCourse, setSelectedCourse] = useState("");
  // const [state, setState] = useState({ right: false });
  const [state2, setState2] = useState({ right: false });
  const [state, setState] = useState({ right: false });
  const [openMenu, setOpenMenu] = useState(true);

  const handleClickMenu = (selectedMenu) => {
    if (openMenu && selectedMenu == openMenu) {
      setOpenMenu("");
    } else {
      setOpenMenu(selectedMenu);
    }
  };
  const toggleDrawer = (anchor, open) => (event) => {
    console.log(event, "event");
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const toggleDrawer2 = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState2({ ...state, [anchor]: open });
  };

  useEffect(() => {
    if (navbarRef.current) {
      const { top, height, left, width } =
        navbarRef.current.getBoundingClientRect();
      setContainerTop(height / 2 + top);
      setContainerLeft(left / 2 + width / 2);
    }
  }, [navbarRef]);

  useEffect(() => {
    if (selectedMenu) {
      setSelectedCourses(
        courseList.filter((c) => c?.menu?.name === selectedMenu)
      );
    }
  }, [selectedMenu]);

  const handleOutsideClick = (event) => {
    // Check if the clicked element is outside the box
    if (navbarRef.current && !navbarRef.current.contains(event.target)) {
      setOpenDropDown(false);
    }
  };

  useEffect(() => {
    // Add event listener for clicks on the document
    document.addEventListener("click", handleOutsideClick);

    // Clean up the event listener when the component is unmounted
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  // ========================================================

  const PATHS_TO_SHOW_NAVDATA = [
    "/online-training",
    "/corporate-training",
    "/onlineclassroom-training",
    "/classroom-training",
  ];

  const TITLES = {
    "/online-training": "Online Training",
    "/corporate-training": "Corporate Training",
    "/onlineclassroom-training": "Online Classroom Training",
    "/classroom-training": "Classroom Training",
  };

  const IMAGES = {
    "/online-training": icon1,
    "/corporate-training": icon2,
    "/onlineclassroom-training": icon3,
    "/classroom-training": icon4,
  };

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;

      // Change the value (e.g., 100) to set the scroll position where the component becomes sticky
      if (offset > 420 && PATHS_TO_SHOW_NAVDATA.includes(location.pathname)) {
        setNavbarData({ ...navbarData, show: true, title: location.pathname });
      } else {
        setNavbarData({ ...navbarData, show: false });
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location.pathname]);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      // color: "black",
      borderRadius: "25px",
      display: "flex",
      minWidth: "200px",
      marginLeft: { xs: "0px", sm: "0px", md: "20px" },
    }),
    input: (provided) => ({
      ...provided,
      flex: 2,
    }),
    menu: (provided, state) => ({
      ...provided,
      color: "black",
    }),
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props} style={{ background: "red" }}>
        <SearchIcon style={{ color: "gray" }} />
      </components.DropdownIndicator>
    );
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
    setState({ right: false });
  };

  // ============== menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const Menu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    getCourseHandler();
  }, []);

  const getCourseHandler = async () => {
    try {
      const data = await get(urls.course.getCourse);
      setCourseList(data.data.result.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getMenuHandler = async () => {
      try {
        const data = await get(`${urls.menu.getMenuList}`);
        const filter = data.data.result.data.filter(
          (m) => m.activated === true
        );
        setMenuList(filter);
      } catch (error) {
        console.log(error);
      }
    };
    getMenuHandler();
  }, []);

  // search courses
  const handleSearch = (inputValue) => {
    const filteredList = searchList.filter((item) =>
      item.label.toLowerCase().includes(inputValue.toLowerCase())
    );

    setSearchData(filteredList);
    setSearchCourse(inputValue);
  };

  const handleSearchClick = (selectedOption) => {
    const selectedCourse = searchList.find(
      (course) => course.value === selectedOption.value
    );

    if (selectedCourse) {
      window.localStorage.setItem("courseId", selectedCourse.value);

      setCourseId(selectedCourse.value);
      navigate(`/all-classes`, { state: { from: "selected" } });
      setSearchCourse("");
    }
  };

  useEffect(() => {
    const searchCourseHandler = async () => {
      try {
        const res = await get(`${urls.course.courseNameList}`);
        console.log(res, "ghghgh");
        setSearchList(
          res.data?.result?.data?.map((m) => ({
            label: m.title,
            value: m._id,
          }))
        );

        setSearchData(
          res.data?.result?.data?.map((m) => ({
            label: m.title,
            value: m._id,
          }))
        );
      } catch (error) {
        console.error(error);
      }
    };
    searchCourseHandler();
  }, []);

  const handleNavigateCourse = (id) => {
    const selectedCourse = courseList.find((course) => course._id === id);

    if (selectedCourse) {
      window.localStorage.setItem("courseId", selectedCourse._id);
      setState({ right: false });
      setState2({ right: false });
      setCourseId(selectedCourse._id);
      navigate(`/all-classes`, { state: { from: "selected" } });
    }
  };

  return (
    <>
      <AppBar position="sticky" sx={{ backgroundColor: "#ffff", zIndex: 1000 }}>
        {/* ======================== up navbar ======================= */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: theme.palette.secondary.main,
            flexDirection: { md: "row", sm: "row", xs: "column" },
            padding: "4px 10px",
          }}
        >
          <Box sx={{ display: "flex", columnGap: "8px" }}>
            <a
              href="https://www.facebook.com/profile.php?id=61554722904419"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaceBook />
            </a>
            <a
              href="https://twitter.com/aphia_learning"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Twitter />
            </a>
            <LinkedIn />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              columnGap: "8px",
            }}
          >
            {/* <Caller />
            <PhoneNo>123456789</PhoneNo> */}
            <Mail fontSize="small" />
            <Email>
              <a
                href="mailto:support@aphialearning.com"
                style={{ textDecoration: "none", color: "white" }}
              >
                support@aphialearning.com
              </a>
            </Email>
          </Box>
        </Box>

        {/* =========================down navbar====================== */}
        <Container maxWidth="xl">
          <Toolbar
            // disableGutters
            sx={{
              padding: { xs: "0 0", sm: "0 0", md: "0px 50px" },
            }}
          >
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                  color: "#212121",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "8px",
                  }}
                >
                  <img
                    src={logo}
                    alt="aphialogo"
                    width={200}
                    height="auto"
                    style={{ objectFit: "contain" }}
                  />
                </Box>
              </Link>

              <Button
                ref={navbarRef}
                sx={{
                  color: "grey",
                  fontSize: "16px",
                  textTransform: "capitalize",
                  "&:hover": { color: theme.palette.primary.main },
                  visibility: { xs: "hidden", md: "visible" },
                  borderRadius: 0,
                }}
                onClick={() => setOpenDropDown(!openDropDown)}
                endIcon={<ExpandMore />}
                disableFocusRipple
                disableTouchRipple
              >
                Browse Courses
              </Button>

              {!openDropDown ? null : (
                <Box
                  sx={{
                    position: "absolute",
                    left: `${containerLeft}px`,
                    right: 0,
                    top: `${containerTop}px`,
                    maxWidth: "560px",
                    borderRadius: "2px",
                    height: "500px",
                    display: "flex",
                    overflow: "hidden",
                  }}
                >
                  <MenuItemsContainer>
                    {menuList.map((m, i) => (
                      <MenuItems
                        sx={{ justifyContent: "space-between" }}
                        component="div"
                        onMouseEnter={() => setSelectedMenu(m.name)}
                        key={m._id}
                      >
                        <Typography
                          sx={{
                            color:
                              selectedMenu === m?.name
                                ? theme.palette.primary.main
                                : "black",
                            fontSize: "14px",
                          }}
                        >
                          {m.name}
                        </Typography>
                        {!courseList.filter((c) => c.menu._id === m._id)
                          .length ? null : (
                          <KeyboardArrowRightIcon
                            sx={{ color: "black" }}
                            fontSize="small"
                          />
                        )}
                      </MenuItems>
                    ))}
                  </MenuItemsContainer>
                  {selectedMenu && selectedCourses.length && openDropDown ? (
                    <MenuItemsContainer>
                      {selectedCourses.map((c, i) => (
                        <MenuItems
                          component="div"
                          onMouseEnter={() => setSelectedCourse(c._id)}
                          onMouseLeave={() => setSelectedCourse("")}
                          onClick={() => {
                            setOpenDropDown(false);
                            handleNavigateCourse(selectedCourse);
                          }}
                          key={c._id}
                        >
                          <Typography
                            sx={{
                              color:
                                selectedCourse === c._id
                                  ? theme.palette.primary.main
                                  : "black",
                              fontSize: "14px",
                            }}
                          >
                            {c?.title}
                          </Typography>
                        </MenuItems>
                      ))}
                    </MenuItemsContainer>
                  ) : null}
                </Box>
              )}
            </Box>

            <Box
              sx={{
                display: { xs: "none", sm: "none", md: "block" },
                ml: 2,
              }}
            >
              <Select
                type="text"
                inputProps={{ "aria-label": "search" }}
                options={searchData}
                placeholder="Find a Course"
                onInputChange={handleSearch}
                isSearchable
                value={searchCourse}
                styles={customStyles}
                components={{ DropdownIndicator }}
                onChange={handleSearchClick}
              />
            </Box>

            <Box sx={{ flexGrow: 1 }} />
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                marginTop: "8px",
              }}
            >
              {pages.map((page, i) => (
                <Link key={i} to={page.path} style={{ textDecoration: "none" }}>
                  <Typography
                    key={i}
                    // variant="h6"
                    onClick={handleCloseNavMenu}
                    sx={{
                      my: 2,
                      display: "block",
                      color:
                        page.path === window.location.pathname
                          ? theme.palette.primary.dark
                          : "grey",
                      borderBottom: `3px solid ${
                        page.path === window.location.pathname
                          ? theme.palette.secondary.main
                          : "transparent"
                      }`,
                      textTransform: "none",
                      margin: "0px 15px",
                      fontSize: "16px",
                      fontFamily: "Quicksand",
                      // borderBottom: "3px solid transparent",
                      transition: "border-color 0.3s ease-in-out",
                      "&:hover": {
                        // borderBottomColor: theme.palette.secondary.main,
                        color: theme.palette.primary.main,
                      },
                    }}
                  >
                    <AnchorBox>{page.name}</AnchorBox>
                  </Typography>
                </Link>
              ))}
            </Box>
            <Box sx={{ display: { xs: "none", md: "flex" }, gap: 1 }}>
              <Register variant="outlined" onClick={() => navigate(`/signup`)}>
                Register
              </Register>
              <Login variant="contained" onClick={() => navigate(`/login`)}>
                Login
              </Login>
            </Box>

            {/* MOBILE navbar start------------------------- */}
            <Box
              sx={{
                maxWidth: "100%",
                display: { xs: "flex", md: "none" },
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                // onClick={handleOpenNavMenu}
                onClick={toggleDrawer("right", true)}
              >
                <MenuIcon />
              </IconButton>
              <SwipeableDrawer
                anchor="right"
                open={state["right"]}
                onClose={toggleDrawer("right", false)}
                onOpen={toggleDrawer("right", true)}
              >
                <Box
                  role="presentation"
                  onKeyDown={toggleDrawer("right", false)}
                  sx={{
                    display: {
                      xs: "block",
                      md: "none",
                    },
                    width: 300,
                    top: "20px",
                  }}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                >
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      p: 2,
                    }}
                  >
                    <IconButton onClick={handleCloseNavMenu}>
                      <CloseIcon color="error" />
                    </IconButton>
                  </Typography>
                  <Divider />
                  {mobileNavmenu.map((page) => (
                    <Link
                      key={page.id}
                      to={page.path}
                      style={{
                        textDecoration: "none",
                        fontSize: 15,
                      }}
                    >
                      <MenuItem
                        onClick={handleCloseNavMenu}
                        sx={{
                          textAlign: "center",
                          "&:hover": {
                            backgroundColor: theme.palette.primary.main,
                            "& .MuiTypography-root": {
                              color: "white",
                            },
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            color:
                              window.location.pathname === page.path
                                ? theme.palette.primary.main
                                : "gray",
                          }}
                        >
                          {page.name}
                        </Typography>
                      </MenuItem>
                    </Link>
                  ))}

                  {/* ====================== */}
                  <MenuItem
                    onClick={() => {
                      setState({ right: false });
                      setState2({ right: true });
                    }}
                    sx={{
                      textAlign: "center",
                      "&:hover": {
                        backgroundColor: theme.palette.primary.main,
                        "& .MuiTypography-root": {
                          color: "white",
                        },
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        color: "grey",
                      }}
                    >
                      Browse Courses
                    </Typography>
                  </MenuItem>

                  {/* ======================== */}
                </Box>
              </SwipeableDrawer>
              <SwipeableDrawer
                anchor="right"
                open={state2["right"]}
                onOpen={toggleDrawer2("right", false)}
                onClose={toggleDrawer2("right", false)}
                onKeyDown={toggleDrawer2("right", false)}
              >
                <Box
                  onKeyDown={toggleDrawer2("right", false)}
                  role="presentation"
                  sx={{ width: 300 }}
                >
                  <List
                    sx={{
                      width: "100%",
                      maxWidth: 360,
                      bgcolor: "background.paper",
                    }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                      <ListSubheader
                        component="div"
                        id="nested-list-subheader"
                        sx={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "16px",
                          color: "grey",
                          "&:hover": {
                            color: theme.palette.primary.main,
                          },
                        }}
                        onClick={() => {
                          setState2({ right: false });
                          setState({ right: true });
                        }}
                      >
                        <ChevronLeftIcon />
                        Menu
                      </ListSubheader>
                    }
                  >
                    <Divider component="div" />
                    {menuList.map((menuName, i) => (
                      <>
                        <ListItemButton
                          key={menuName._id}
                          onClick={() => handleClickMenu(menuName._id)}
                          sx={{
                            "&:hover": {
                              color: theme.palette.primary.main,
                            },
                          }}
                        >
                          <ListItemText
                            primary={`${menuName.name} (${
                              courseList.filter(
                                (c) => c.menu._id == menuName._id
                              ).length
                            })`}
                          />
                          {courseList.filter((c) => c.menu._id == menuName._id)
                            .length ? (
                            openMenu === menuName._id ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )
                          ) : null}
                        </ListItemButton>
                        {courseList
                          .filter((c) => c.menu._id == menuName._id)
                          .map((course, i) => (
                            <Collapse
                              key={course._id}
                              in={openMenu === menuName._id}
                              timeout="auto"
                              unmountOnExit
                              onClick={() => handleNavigateCourse(course._id)}
                            >
                              <List component="div" disablePadding>
                                <ListItemButton
                                  sx={{
                                    fontSize: "14px",
                                    color: "grey",
                                    "&:hover": {
                                      color: theme.palette.primary.main,
                                    },
                                  }}
                                >
                                  <ChevronRightIcon />
                                  <ListItemText
                                    primary={course?.title}
                                    sx={{
                                      fontSize: "10px",
                                    }}
                                  />
                                </ListItemButton>
                              </List>
                            </Collapse>
                          ))}
                      </>
                    ))}
                  </List>
                </Box>
              </SwipeableDrawer>
            </Box>

            {/* end mobile menu ---------------------------- */}
          </Toolbar>
        </Container>

        {/* =============== */}
        {!navbarData.show ? null : (
          <Toolbar
            sx={{
              background: theme.palette.grey[200],

              // theme.palette.secondary.dark
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                columnGap: "10px",
                alignItems: "center",
                paddingLeft: "50px",
                // boxSadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
              }}
            >
              <img
                src={IMAGES[navbarData.title]}
                width={30}
                height={30}
                style={{ objectFit: "contain" }}
              />
              <Typography
                variant="h6"
                sx={{
                  animationDelay: "1s",
                  fontFamily: "Quicksand",
                  fontSize: "22px",
                  color: "#146213",
                  fontWeight: 700,
                  padding: "10 0 10 30",
                  // fontSize: { xs: "24px", sm: "36px", md: "52px" },
                  //padding: { xs: "20px 0", sm: "20px 0", md: "0 80px" },
                }}
              >
                {TITLES[navbarData.title]}
              </Typography>{" "}
            </Box>
          </Toolbar>
        )}
      </AppBar>
    </>
  );
}
export default Navbar;
