import {
  Box,
  Button,
  Container,
  Divider,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import CountUp from "react-countup";
import { theme } from "../theme";

const TrainingDetails = () => {
  const Heading = styled(Typography)(({ theme }) => ({
    fontSize: "35px",
    fontWeight: "700",
    color: "#ffff",
    textAlign: "center",
    marginBottom: "45px",
    marginTop: 10,
    fontFamily: "Quicksand",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      marginBottom: 40,
      marginTop: 10,
    },
  }));

  const Content = styled(Typography)(({ theme }) => ({
    fontSize: "33px",
    color: "#ffff",
    margin: "10px 0",
    marginLeft: "50px",

    [theme.breakpoints.down("sm")]: {
      fontSize: "28px",
      marginLeft: "0",
      textAlign: "center",
    },
  }));

  const ContentBox = styled(Box)(() => ({
    marginTop: 35,
  }));

  const Button1 = styled(Button)(() => ({
    border: "1px solid #ffff",
    borderRadius: "3px",
    backgroundColor: "#ffff",
    color: theme.palette.primary.main,
    marginTop: "-20px",
    marginLeft: "50px",
    textTransform: "none",
    padding: "0px 5px",
    "&:hover": {
      backgroundColor: "#ffff",
      color: theme.palette.primary.main,
    },

    "@media (max-width: 600px)": {
      marginLeft: "0",
      marginTop: "0px",
      width: "100%",
    },
  }));

  const style = {
    width: "60%",
    padding: 2,
    borderRadius: "30% 10%",

    "@media (max-width: 600px)": {
      width: "100%",
    },
  };

  return (
    <Box bgcolor={theme.palette.primary.main}>
      <Container maxWidth="xl">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "30px",
          }}>
          <Box sx={style} className="block">
            <Heading>Figures We're Proud Of</Heading>
            <Box>
              <Divider color="white" />
              <Button1>We Offer</Button1>

              <Content>
                <CountUp end={200} start={0} duration={3} delay={0} /> +
                <span style={{ fontSize: "17px" }}> Subjects</span>
              </Content>
            </Box>
            <ContentBox>
              <Divider color="white" />
              <Button1>We've Trained</Button1>
              <Content>
                <CountUp end={15000} start={1000} duration={3} delay={0} /> +
                <span style={{ fontSize: "17px" }}> Clients</span>
              </Content>
            </ContentBox>
            <ContentBox>
              <Divider color="white" />
              <Button1>We Provide Training In</Button1>
              <Content>
                <CountUp end={190} start={0} duration={3} delay={0} /> +
                <span
                  style={{
                    fontSize: "17px",
                  }}>
                  {" "}
                  Countries
                </span>
              </Content>
            </ContentBox>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default TrainingDetails;
