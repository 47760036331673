import apiClient from "./apiClient";

export const get = async (url, options) => {
  const res = await apiClient.get(url, options);
  return res;
};
export const post = async (url, body, options) => {
  const res = await apiClient.post(url, body, options);
  return res;
};
export const del = async (url) => {
  const res = await apiClient.delete(url);
  return res;
};
export const put = async (url, body, options) => {
  const res = await apiClient.put(url, body, options);
  return res;
};



