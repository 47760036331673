import React from "react";
import { v4 as uuidv4 } from "uuid";
import Carousel from "./Carroussel";
import {
  Box,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
  styled,
  Card,
  Rating,
} from "@mui/material";
import image from "../assets/Images/become-in.png";
import sap from "../assets/Images/image (9).png";
import quality from "../assets/Images/image (11).png";
import project from "../assets/Images/image (10).png";
import business from "../assets/Images/image (12).png";
import Cards from "./Card";
import { useState } from "react";
import { get } from "../apis/apiMethods";
import urls from "../apis/urlContants";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
// import { Link, Navigate } from "react-router-dom";

const Time = styled(Box)(() => ({
  position: "absolute",
  top: "10px",
  right: "0",
  backgroundColor: "#fb8c00",
  borderTopLeftRadius: "5px",
  borderBottomLeftRadius: "5px",
  width: "130px",
  display: "flex",
  justifyContent: "center", // Center the text horizontally
  alignItems: "center", // Center the text vertically
  padding: "10px 0",
  height: "50px",
  boxShadow: "0px 8px 6px -6px black",
  clipPath: "polygon(15% 50%, 0% 0%, 100% 0%,100% 100%,  0% 100%)",
}));

const Star = styled(Rating)(() => ({
  fontSize: "20px",
  margin: "0px 10px",
}));

function TrendingCourse() {
  let cards = [
    {
      key: uuidv4(),
      content: <Cards />,
    },
    {
      key: uuidv4(),
      content: <Cards />,
    },
    {
      key: uuidv4(),
      content: <Cards />,
    },
    {
      key: uuidv4(),
      content: <Cards />,
    },
    {
      key: uuidv4(),
      content: <Cards />,
    },
  ];

  const [courseList, setCourseList] = React.useState([]);
  const [averageRatings, setAverageRatings] = React.useState({});

  React.useEffect(() => {
    const ratings = {};
    courseList?.forEach((course) => {
      if (course.feedbacks && Array.isArray(course.feedbacks)) {
        const totalRatings = course.feedbacks.reduce(
          (sum, feedback) => sum + Number(feedback.rating),
          0
        );
        const averageRating = totalRatings / (course.feedbacks.length || 1);

        ratings[course._id] = averageRating;
      } else {
        ratings[course._id] = 0;
      }
    });

    setAverageRatings(ratings);
  }, [courseList]);

  const getCourseList = async () => {
    const res = await get(`${urls.course.list}`);
    const product = res?.data?.result?.data.filter((course) => course.isTread);
    setCourseList(product);
    console.log(product, "ppppppppppppppuuuuuuuuuuuu");
  };

  React.useEffect(() => {
    getCourseList();
  }, [window.localStorage.getItem("courseId")]);

  const navigate = useNavigate();

  const handleNavigateCourse = (id, course) => {
    // console.log(course, id, "hhhhh");
    const selectedCourse = courseList.find((course) => course._id === id);
    // console.log(selectedCourse, "=======");

    if (selectedCourse) {
      window.localStorage.setItem("courseId", selectedCourse._id);

      // setCourseId(selectedCourse._id);
      // setCourseId(course);
      console.log(id, course, "----usha----navbbar");
      navigate(`/all-classes`);
    }
  };

  return (
    <Container maxWidth="xl">
      <Grid
        container
        sx={{ padding: { xs: "0 0", sm: "0 0", md: "25px 50px" } }}>
        <Grid item xs={12} md={6} my={2}>
          <Typography
            sx={{
              fontSize: 35,
              fontFamily: "Quicksand",
              fontWeight: 700,
              display: "flex",
              justifyContent: {
                xs: "center",
                sm: "center",
                md: "flex-start",
                lg: "flex-start",
              },
            }}>
            Trending Category!
          </Typography>
        </Grid>
        <ScrollAnimation
          animateIn="animate__slideInUp"
          duration={1}
          animateOnce={false}>
          <Grid container spacing={2} mt={3}>
            <Grid item xs={12} sm={12} md={3}>
              <img
                src={sap}
                alt="SAP"
                style={{ width: "100%", height: "100%" }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <img
                src={project}
                alt="SAP"
                style={{ width: "100%", height: "100%" }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <img
                src={business}
                alt="SAP"
                style={{ width: "100%", height: "100%" }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <img
                src={quality}
                alt="SAP"
                style={{ width: "100%", height: "100%" }}
              />
            </Grid>
          </Grid>
        </ScrollAnimation>

        {/* ==========Dynamic CARD NEED later DONOT remove this commented code============== */}
        {/* =============================================================================== */}
        {/* <Grid container spacing={4} sx={{ display: "flex" }} mt={8}> */}
        {/* {courseList.slice(0, 4).map((courseItem, i) => (
            <Grid item xs={12} sm={12} md={3} lg={3} key={i}>
              <Card
                sx={{ borderRadius: "2px" }}
                onClick={() => handleNavigateCourse(courseItem._id, courseItem)}
              >
                <CardActionArea>
                  <CardMedia
                    height={200}
                    component="img"
                    image={
                      courseItem.thumbnailImage
                        ? courseItem.thumbnailImage
                        : image
                    }
                    alt="green iguana"
                  />
                  <CardContent sx={{ padding: "15px 15px" }}>
                     <Time>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: "16px",
                          display: "flex",
                          flexDirection: "column-reverse",
                          color: "#fff",
                        }}
                      >
                        {courseItem.isPaid === true
                          ? `₹${
                              dayjs(Date.now()).diff(
                                new Date(
                                  courseItem.selfPacedInfo.earlyBirdEndDate
                                ),
                                "minutes"
                              ) < 0
                                ? courseItem.selfPacedInfo.earlyBirdPrice
                                : courseItem.selfPacedInfo.standardPrice
                            }`
                          : "Free"}
                        {courseItem.isPaid === true ? (
                          dayjs(Date.now()).diff(
                            new Date(courseItem.selfPacedInfo.earlyBirdEndDate),
                            "minutes"
                          ) < 0 ? (
                            <s
                              style={{
                                color: "#fff",
                                fontSize: "12px",
                                fontWeight: "normal",
                              }}
                            >
                              {`₹${courseItem.selfPacedInfo.standardPrice}`}
                            </s>
                          ) : null
                        ) : (
                          ""
                        )}
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{ fontSize: "14px", color: "#fff" }}
                      >
                        Trending
                      </Typography>
                    </Time>
                    <Typography
                      gutterBottom
                      variant="h5"
                      sx={{
                        color: "#009974",
                      }}
                    >
                      {courseItem.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      style={{
                        display: "-webkit-box",
                        overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                        textOverflow: "ellipsis",
                        whiteSpace: "normal",
                        height: 40,
                      }}
                    >
                      <div>{courseItem.description}</div>
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        marginTop: "10px",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ display: "flex" }}>
                        <Typography color="#009974">
                          {averageRatings[courseItem._id]}
                        </Typography>
                        {averageRatings[courseItem._id] === 0 ||
                        averageRatings[courseItem._id] ? (
                          <Star
                            id={courseItem._id}
                            name="simple-controlled"
                            value={averageRatings[courseItem._id]}
                            fontSize="20px"
                            readOnly
                          />
                        ) : null}
                      </Box>
                    </Box>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))} */}
        {/* </Grid> */}
      </Grid>
    </Container>
  );
}

export default TrendingCourse;
