import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { post, put, get } from "../../src/apis/apiMethods";
import urls from "../apis/urlContants";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import authentication from "../apis/authentication";
import css from "../styles/pages/login.css";
import { isValidEmail, objPropertyCheck } from "../utils/utils";
import { LoadingButton } from "@mui/lab";
import useScrollToTopOnNavigation from "../hooks/useScrollToTopNavigation";
import { theme } from "../theme";

const SignUp = () => {
  useScrollToTopOnNavigation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [formError, setFormError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [userRegOtp, setUserRegOtp] = useState(false);
  const [otp, setOtp] = useState("");
  const [resError, setResError] = useState("");
  const [settings, setSettings] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [verifyLoading, setVerifyLoading] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFormData({ receivedCode: "" });
  };
  useEffect(() => {
    // const logoURL = require("./image/logo3.jpg");
    // setSettings({ ...settings, logo: { link: logoURL } });
  }, []);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // console.log(formData, "formdataSingup");
  const handleSendCode = async (e) => {
    setLoading(true);
    e.preventDefault();
    console.log(formData.name, "hjhjhjh");
    try {
      const sendCode = await post(`${urls.auth.sendEmailConfirmationCode}`, {
        name: formData.name,
        email: formData.email,
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  const handleVerifyCode = async () => {
    setVerifyLoading(true);
    // e.preventDefault();
    try {
      const sendCode = await post(`${urls.auth.verifyEmailCode}`, {
        receivedCode: formData.receivedCode,
        email: formData.email,
      });
      if (sendCode.data.responseCode === "OK") {
        handleSubmit();
      }
      console.log(sendCode, "message");
      setVerifyLoading(false);
      setFormData({ receivedCode: "" });
    } catch (error) {
      console.log(error);
    }
    setVerifyLoading(false);
  };
  const handleSubmit = async () => {
    // setLoading(true);
    // e.preventDefault();
    try {
      if (
        objPropertyCheck(formData, ["email", "password", "mobile", "name"]) &&
        isValidEmail(formData.email)
      ) {
        const sendOtp = await authentication.signUp({
          name: formData.name,
          email: formData.email,
          mobile: formData.mobile,
          password: formData.password,
        });
        setFormData({});

        navigate("/");
      } else {
        setFormError(true);
      }
      // setLoading(false);
    } catch (error) {
      setResError(error.response.data.message);
    }
    // setLoading(false);
  };
  return (
    <div className="registerContainer" id="signup">
      <div className="flexContainer">
        {/* <div className="header">Sign Up</div>
        <div className="subHeader">
          Already have a account? Login <Link to={"/login"}>here</Link>
        </div>
        <div className="error">{resError && resError}</div>
        <div className="formContainer">
          <form onSubmit={handleSubmit}>
            <div className="formHolder">
              <TextField
                size="small"
                required
                error={
                  formError &&
                  (!formData.email || !isValidEmail(formData.email))
                }
                fullWidth
                onChange={handleFormChange}
                value={formData.email || ""}
                name="email"
                label="Email"
                variant="outlined"
                sx={{
                  backgroundColor: "#F5F5F5",
                }}
              />
              {formError && !formData.email && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
              {formError && !isValidEmail(formData.email) && (
                <span style={{ color: "red" }}>Provide a valid email</span>
              )}
            </div>
            <div className="formHolder">
              <TextField
                size="small"
                error={formError && !formData.name}
                fullWidth
                onChange={handleFormChange}
                value={formData.name || ""}
                name="name"
                label="Name"
                variant="outlined"
                sx={{
                  backgroundColor: "#F5F5F5",
                }}
              />
              {formError && !formData.name && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
            </div>
            <div className="formHolder">
              <TextField
                type="number"
                size="small"
                error={formError && !formData.mobile}
                fullWidth
                onChange={handleFormChange}
                value={formData.mobile || ""}
                name="mobile"
                label="Mobile Number"
                variant="outlined"
                sx={{
                  backgroundColor: "#F5F5F5",
                }}
              />
              {formError && !formData.mobile && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
            </div>

            <div className="formHolder">
              <TextField
                type={showPassword ? "text" : "password"}
                size="small"
                error={formError && !formData.password}
                fullWidth
                onChange={handleFormChange}
                value={formData.password || ""}
                name="password"
                label="Password"
                variant="outlined"
                sx={{
                  backgroundColor: "#F5F5F5",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {formError && !formData.password && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
            </div>
            <div className="textBtnContainer">
              <div className="btn" style={{ width: "100%" }}>
                <Button variant="contained" type="submit">
                  Sign Up
                </Button>
              </div>
            </div>
          </form>
        </div> */}
        <Box
          component={"form"}
          onSubmit={handleSendCode}
          //   boxShadow={4}
          p={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: { xs: 340, sm: 390, md: 420, lg: 420 },
            border: `1px solid ${theme.palette.primary.main}`,
            backgroundColor: "#fff",
            borderRadius: "8px",
          }}
        >
          <Typography
            variant="h5"
            textAlign="center"
            color={theme.palette.primary.main}
          >
            Sign Up
          </Typography>
          <Typography
            variant="body"
            textAlign="center"
            mt={2}
            sx={{
              fontSize: { xs: "13px", sm: "14px", md: "16px" },
            }}
          >
            {/* Already have a account? */}
            {/* <Link to="/login"
             style={{
              color: "#F5B901",
            }}>Login here</Link> */}
          </Typography>
          <div className="error">{resError && resError}</div>
          <div className="formHolder">
            <TextField
              size="small"
              required
              error={
                formError && (!formData.email || !isValidEmail(formData.email))
              }
              fullWidth
              onChange={handleFormChange}
              value={formData.email || ""}
              name="email"
              label="Email"
              variant="outlined"
            />
            {formError && !formData.email && (
              <span style={{ color: "red" }}>Email required</span>
            )}
            {formError && !isValidEmail(formData.email) && (
              <span style={{ color: "red" }}>Provide a valid email</span>
            )}
          </div>
          <div className="formHolder">
            <TextField
              size="small"
              error={formError && !formData.name}
              fullWidth
              onChange={handleFormChange}
              value={formData.name || ""}
              name="name"
              label="Name"
              variant="outlined"
            />
            {formError && !formData.name && (
              <span style={{ color: "red" }}>name required</span>
            )}
          </div>

          <div className="formHolder">
            <TextField
              type="number"
              size="small"
              error={formError && !formData.mobile}
              fullWidth
              onChange={handleFormChange}
              value={formData.mobile || ""}
              name="mobile"
              label="Mobile Number"
              variant="outlined"
            />
            {formError && !formData.mobile && (
              <span style={{ color: "red" }}>Mobile Number is required</span>
            )}
          </div>
          <div className="formHolder">
            <TextField
              type={showPassword ? "text" : "password"}
              size="small"
              error={formError && !formData.password}
              fullWidth
              onChange={handleFormChange}
              value={formData.password || ""}
              name="password"
              label="Password"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {formError && !formData.password && (
              <span style={{ color: "red" }}>Password is required</span>
            )}
          </div>
          <LoadingButton
            disableElevation
            type="submit"
            variant="contained"
            size="small"
            sx={{
              width: "40%",
              marginBottom: "15px",
              marginTop: "15px",
              "&.MuiButtonBase-root": { borderRadius: "5px" },
            }}
            loading={loading}
            onClick={handleClickOpen}
          >
            Sign Up
          </LoadingButton>
        </Box>
      </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Verification Code Send to your Email</DialogTitle>
        <DialogContent>
          <TextField
            label="Code Verification"
            fullWidth
            variant="standard"
            name="receivedCode"
            type="number"
            onChange={handleFormChange}
            value={formData.receivedCode || ""}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="small"
            sx={{
              backgroundColor: "maroon",
              "&:hover": {
                backgroundColor: "red",
              },
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            size="small"
            loading={verifyLoading}
            onClick={handleVerifyCode}
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SignUp;
