import {
  Box,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { PmpCertificateData } from "../helpers/Commun.hellper";
import { Stack } from "react-bootstrap";
import { PMP_CERTIFICATE } from "../helpers/pmp-certificate/PMP-Certificate";
import urls from "../apis/urlContants";
import { get } from "../apis/apiMethods";

const PmpHeading = styled(Typography)(({ theme }) => ({
  fontSize: "30px",
  fontWeight: "bold",
  // marginBottom: "20px",
  [theme.breakpoints.down("sm")]: {
    marginLeft: "15%",
    // textAlign: 'center',
    fontSize: "20px",
  },
}));

const Details = styled(Box)(({ theme }) => ({
  display: "flex",
  marginTop: "20px",
  marginLeft: "20px",
  minHeight: 200,
  [theme.breakpoints.down("sm")]: {
    marginLeft: 30,
    textAlign: "center",
  },
}));

const PathNames = styled(Typography)(({ theme }) => ({
  color: "#9e9e9e",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    marginLeft: 0,
    textAlign: "center",
    fontSize: "14px",
  },
}));

const PmpCertificate = ({ selectCourse, menu }) => {
  return (
    <>
      <Box bgcolor="whitesmoke">
        <Container maxWidth="xl">
          {/* {selectCourse?.map((ele, i) => ( */}
          <Grid
            container
            spacing={2}
            sx={{
              padding: { xs: "20px 0", sm: "20px 0", md: "50px 50px" },
              background: "whitesmoke",
            }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                textAlign: { xs: "center", md: "start" },
                justifyContent: {
                  xs: "center",
                  md: "flex-start",
                },
              }}
            >
              <Grid item xs={12} sm={12} md={12}>
                <PathNames>
                  <Stack direction="row" spacing={2}>
                    Home{" "}
                    <ChevronRightIcon style={{ verticalAlign: "middle" }} />
                    {menu}
                    <ChevronRightIcon
                      style={{ verticalAlign: "middle" }}
                    />{" "}
                    {selectCourse?.title}
                  </Stack>
                </PathNames>
              </Grid>
              <Grid item xs={12} sm={12} md={12} mt={2}>
                <PmpHeading>{selectCourse?.title}</PmpHeading>
              </Grid>

              <Grid item xs={12} sm={12} md={12} mt={1}>
                {/* {ele.highlights.map((item, index) => (
                    <Details key={index} gap={1}>
                      <ChevronRightIcon
                        sx={{
                          color: "#009974",
                        }}
                      />
                      <Typography gutterBottom>{item}</Typography>
                    </Details>
                  ))} */}
                <Details
                  sx={{
                    justifyContent: { xs: "center", sm: "center", md: "start" },
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: selectCourse?.highlights,
                    }}
                  />
                </Details>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              sx={{
                display: "flex",
                justifyContent: {
                  xs: "center",
                  md: "flex-end",
                  marginBottom: "30px",
                },
              }}
            >
              <PMP_CERTIFICATE selectCourse={selectCourse} menu={menu} />
            </Grid>
            {/* 
            {/* <Grid
              item
              xs={12}
              sm={12}
              md={6}
              sx={{
                display: "flex",
                justifyContent: {
                  xs: "center",
                  md: "flex-end",
                  marginBottom: "30px",
                },
              }}
            > */}{" "}
            {/* <Box
                  sx={{
                    width: 350,
                    height: 300,
                    p: 3,
                    position: "absolute",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundImage: `url(${pmp})`,
                  }}
                >
                  <Typography variant="h5" textAlign="center" mt={1}>
                  <img style={{ height: 30 }} src={logo} alt="Lodding..." />
                  </Typography>
                  <Typography variant="h5" textAlign="center" mt={2}>
                  Black Belt
                  </Typography>
                  <Typography variant="h5" textAlign="center" mt={8} component="div" color="#fff">
                  Lean
                  </Typography>
                  <Typography variant="h5" textAlign="center" component="div" color="#fff">
                  Six Sigma
                  </Typography>
                </Box> */}
            {/* <img style={{ height: 200 }} src={ele.pmp.img} alt="" /> */}
            {/* </Grid> */}
          </Grid>
          {/* ))} */}
        </Container>
      </Box>
    </>
  );
};

export default PmpCertificate;
