// import {
//   Box,
//   Container,
//   Divider,
//   Grid,
//   Paper,
//   Typography,
//   styled,
// } from "@mui/material";
// import React from "react";
// import QrCode from "../assets/Images/QrCode.png";
// import Appstore from "../assets/Images/appstore.png";
// import Googlestore from "../assets/Images/googlestore.png";
// import Phone from "../assets/Images/mobileapp1.png";

// const Heading = styled(Typography)(({ theme }) => ({
//   color: "#ffff",
//   fontWeight: "bold",
//   fontSize: "48px",
//   fontFamily: "Quicksand",
//   [theme.breakpoints.down("sm")]: {
//     fontSize: "25px",
//   },
// }));

// const Text = styled(Typography)(() => ({
//   color: "#ffff",
//   fontSize: "18px",
//   fontFamily: "Quicksand",
// }));

// const AppStore = styled(Box)(({ theme }) => ({
//   marginTop: "2%",
//   [theme.breakpoints.down("md")]: {
//     marginTop: "0px",
//     marginLeft: "10px",
//   },
// }));

// const GoogleStore = styled(Box)(({ theme }) => ({
//   marginTop: "2%",
//   [theme.breakpoints.down("md")]: {
//     marginTop: "0px",
//     marginLeft: "10px",
//   },
// }));
// const PhoneImage = styled(Box)(({ theme }) => ({
//   display: "flex",
//   justifyContent: "flex-end",
//   alignItems: "center",
//   margin: "30px 0",
//   position: "relative",
//   backgroundSize: "cover",
//   backgroundPosition: "center",
//   [theme.breakpoints.down("sm")]: {
//     margin: "0px",
//   },
// }));

// const Line = styled(Box)(({ theme }) => ({
//   display: "flex",
//   gap: 2,
//   justifyContent: "center",
//   color: "white",
//   [theme.breakpoints.down("md")]: {
//     display: "none",
//   },
// }));

// const QrCodeBox = styled(Box)(({ theme }) => ({
//   display: "flex",
//   justifyContent: "center",
//   alignItems: "center",
//   flexDirection: "column",
//   [theme.breakpoints.down("md")]: {
//     marginTop: "20px",
//   },
// }));

// const Scanner = () => {
//   return (
//     <Box bgcolor="#009974">
//       <Container maxWidth="xl">
//         <Grid
//           container
//           py={6}
//           spacing={3}
//           justifyContent="center"
//           alignItems="center"
//         >
//           <Grid item xs={12} sm={12} md={12} lg={6} bgcolor="red">
//             <Heading>Get The Mobile App Now And Learn Free</Heading>
//             <Grid container spacing={3}>
//               <Grid item xs={12} sm={12} md={12} lg={6}>
//                 {/* ===================== QR start */}
//                 <Box mt={6}>
//                   <QrCodeBox>
//                     <img height={318} src={QrCode} alt="QR Code" />
//                     <Text>Scan the QR code to download the App</Text>
//                   </QrCodeBox>
//                 </Box>
//               </Grid>
//               <Grid item xs={12} sm={12} md={6} lg={6}>
//                 <Box
//                   gap={{ sm: 1, md: 4 }}
//                   sx={{
//                     marginLeft: { xs: "10px", lg: "130px" },
//                     display: "flex",
//                     justifyContent: "space-evenly",
//                   }}
//                 >
//                   <Line md={{ display: "none" }}>
//                     <Typography className="span" variant="span" mt={0.7}>
//                       Or
//                     </Typography>
//                   </Line>

//                   <Grid
//                     gap={6}
//                     display="flex"
//                     flexDirection="column"
//                     justifyContent="center"
//                     mx={6}
//                     sx={{
//                       marginTop: { xs: "50px" },
//                       md: "1px",
//                     }}
//                   >
//                     <AppStore>
//                       <img height={92} src={Appstore} alt="App Store" />
//                     </AppStore>
//                     <GoogleStore>
//                       <img height={92} src={Googlestore} alt="Google Play" />
//                     </GoogleStore>
//                   </Grid>
//                 </Box>
//               </Grid>
//             </Grid>
//           </Grid>

//           <Grid
//             item
//             xs={12}
//             sm={6}
//             md={6}
//             lg={6}
//             display="flex"
//             justifyContent="end"
//             sx={{ "&.MuiGrid-item": { paddingTop: "0px" } }}
//           >
//             <PhoneImage>
//               <img height={517} width={400} src={Phone} alt="Loading..." />
//             </PhoneImage>
//           </Grid>
//         </Grid>
//       </Container>
//     </Box>
//   );
// };

// export default Scanner;

import {
  Box,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import QrCode from "../assets/Images/aphia-qr.png";
import Appstore from "../assets/Images/appstore.png";
import Googlestore from "../assets/Images/googlestore.png";
import Phone from "../assets/Images/mobileapp1.png";
import { keyframes } from "@emotion/react";
import { theme } from "../theme";

const Heading = styled(Typography)(({ theme }) => ({
  color: "#ffff",
  fontWeight: 600,
  fontSize: "45px",
  fontFamily: "Quicksand",
  [theme.breakpoints.down("sm")]: {
    fontSize: "25px",
  },
}));

const Text = styled(Typography)(() => ({
  fontSize: "16px",
  fontFamily: "Quicksand",
}));

const AppStore = styled(Box)(({ theme }) => ({
  marginTop: "65px",
  [theme.breakpoints.down("md")]: {
    marginTop: "10px",
    marginLeft: "10px",
  },
}));

const GoogleStore = styled(Box)(({ theme }) => ({
  marginTop: "2%",
  [theme.breakpoints.down("md")]: {
    margin: "0 0 30px 10px",
  },
}));
const PhoneImage = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  margin: "30px 0",
  position: "relative",
  backgroundSize: "cover",
  backgroundPosition: "center",
  [theme.breakpoints.down("sm")]: {
    margin: "0px",
  },
}));

const Line = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  color: "white",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const QrCodeBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "60px",
  flexDirection: "column",
  [theme.breakpoints.down("md")]: {
    marginTop: "20px",
  },
}));

const Image = styled("img")(({ theme }) => ({
  width: 300,
  height: 517,
  [theme.breakpoints.down("md")]: {
    width: 450,
  },
  [theme.breakpoints.down("sm")]: {
    width: 350,
  },
}));

const fadeInAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const Scanner = () => {
  return (
    <Box bgcolor={theme.palette.primary.main}>
      <Grid
        container
        pt={4}
        spacing={3}
        justifyContent="space-between"
        sx={{
          animation: `${fadeInAnimation} 2s ease-out forwards`,
        }}
        // alignItems="center"
        // sx={{ padding: { xs: "0 0", sm: "0 0", md: "0 50px" } }}
      >
        <Grid item xs={12} sm={12} md={12} lg={8}>
          <Heading
            sx={{
              textAlign: "center",
            }}
          >
            Get The Mobile App Now And Learn Free
          </Heading>
          <Grid container spacing={3} mt={3}>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              {/* ===================== QR start */}
              <QrCodeBox>
                <img height={260} src={QrCode} alt="QR Code" />
                <Text color="white">Scan the QR code to download the App</Text>
              </QrCodeBox>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={2}>
              <Box
                gap={{ sm: 1, md: 1 }}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Line md={{ display: "none" }}>
                  <Typography className="span" variant="span">
                    Or
                  </Typography>
                </Line>

                <Box
                  gap={6}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  mx={{ lg: 3, md: 0 }}
                >
                  <AppStore>
                    <img height={70} src={Appstore} alt="App Store" />
                  </AppStore>
                  <GoogleStore>
                    <img height={70} src={Googlestore} alt="Google Play" />
                  </GoogleStore>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={4}
          display="flex"
          justifyContent={{
            xs: "center",
            sm: "center",
            md: "center",
          }}
          sx={{
            "&.MuiGrid-item": { paddingTop: "40px" },
          }}
        >
          <PhoneImage>
            <Image src={Phone} alt="Loading..." />
          </PhoneImage>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Scanner;
