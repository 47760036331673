// import { Typography, styled } from '@mui/material'
// import React from 'react'
// const Para = styled(Typography)(({ theme }) => ({
//     color: "#555",
//     lineHeight: "24px",
//     margin: "15px 0",
//     fontSize: "15px",
//     textAlign: "justify",
//   }));
// const WhoShouldAttend = () => {
//   return (
//    <>
//    <Para>
//     <li>  Anyone who wants to learn SAP build / configuration and implementation</li>
//     <li>  SAP Users who want to extend their knowledge to SAP build / configuration and implementation</li>
//     <li>  Other ERP Consultants who want to learn SAP build / configuration and implementation</li>
//     <li>   SAP Consultants</li>
//     <li>SAP Beginners</li>
//     <li>Freshers / graduate hires / Students aspiring to know and implement SAP</li>
//     <li>Decision makers, SAP Consultants, Delivery Managers, CXOs, Entrepreneurs, Agile Coaches, Managers, Directors, VPs, Architects, Application Consultants, Business Analysts, Business Process Owners/Team Leads/Project Leads/Power Users, project team members, and professionals closely associated with Agile SAP S/4 HANA projects who want to build good proficiency in the entire process of SAP S/4HANA project development. Knowing basic programming concepts would be good, although not mandatory.</li>
//    </Para>

//    </>
//   )
// }

// export default WhoShouldAttend

import { Box, Typography, styled } from "@mui/material";
import React from "react";
import { PmpCertificateData } from "../helpers/Commun.hellper";

const Para = styled(Typography)(({ theme }) => ({
  // color: "#555",
  lineHeight: "24px",
  margin: "15px 0",
  fontSize: "16px",
  textAlign: "justify",
  fontFamily: "Inter",
}));
const WhoShouldAttend = ({whoShouldAttend}) => {
  return (
    <Box>
      {/* {PmpCertificateData.map((items, i) => ( */}
          <Para>
            {/* {items.wsa.list.map((list, i) => (
              <div key={i}>
                <li>{list}</li>
              </div>
            ))} */}
            <div dangerouslySetInnerHTML={{__html: whoShouldAttend}} />
          </Para>
      {/* ))} */}
    </Box>
  );
};

export default WhoShouldAttend;
