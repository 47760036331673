import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Drawer,
  Grid,
  IconButton,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import React, { useState, useRef } from "react";
import EastIcon from "@mui/icons-material/East";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { blue } from "@mui/material/colors";
import { SelectForEnquery } from "./SelectForEnquery";
import urls from "../../apis/urlContants";
import { get } from "../../apis/apiMethods";
import moment from "moment";
import { ContactCourseInfo } from "./ContactCourseInfo";
import { RequestModel } from "./RequestModel";
import { theme } from "../../theme";
import ProgramNames from "../../data/programmName";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "grey" : "#308fe8",
  },
}));

const CardBox1 = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  // flexDirection: "column",
}));

const CardWeek = styled(Box)(({ theme }) => ({
  display: "flex",
  // textAlign: "start",
  alignItems: "center",
  justifyContent: "space-between",
  // marginTop: "15px",
  // flexDirection: "column",
}));

const CardBox2 = styled(Box)(({ theme }) => ({
  display: "flex",
  // textAlign: "start",
  alignItems: "center",
  justifyContent: "space-between",
  p: 2,
}));

const EastArrow = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));

const Sal = styled(Typography)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  // flexDirection: "column",
  color: theme.palette.primary.main,
  // marginTop: -24,
}));

const DateContainer = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  fontSize: 16,
  // padding:2,
  [theme.breakpoints.down("sm")]: {
    fontSize: 14,
  },
  [theme.breakpoints.between(279, 281)]: {
    fontSize: 12,
  },
}));

const ArrowBox = styled(Box)(({ theme }) => ({
  display: "flex",
  textAlign: "center",
  alignItems: "center",
  background: "red",
  padding: "0 40px",
  gap: "20px",

  // [theme.breakpoints.down("sm")]: {
  //   marginTop: -10,
  //   display: "none",
  // },
}));

const Arrow = styled(Box)(() => ({
  height: 40,
  width: 40,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "50%",
  backgroundColor: theme.palette.primary.main,
  color: "#fafafa",
}));

const ArrowBack = styled(ArrowBackIcon)(({ theme }) => ({
  // marginLeft: 5,
  color: "#fff",
  // marginTop: 4,
  fontSize: "1.8rem",
  "&:hover": {
    opacity: 0.5,
  },
}));

const ArrowForward = styled(ArrowForwardIcon)(({ theme }) => ({
  // marginLeft: 5,
  // marginTop: 2,
  color: "#fff",
  fontSize: "1.8rem",
  "&:hover": {
    opacity: 0.5,
  },
}));

export const NewCourseOutline = ({
  onlineInstructor,
  programmeName,
  handleOpen,
  openContact,
  handleClose,
  openRequest,
  handleOpenRequest,
  selectedCourse,
  courseTitle,
}) => {
  const [state, setState] = React.useState(false);
  const [courseData, setCourseData] = React.useState();
  const sliderRef = useRef();

  const toggleDrawer = (anchor, open, ele) => (event) => {
    setState({ ...state, [anchor]: open });
    setCourseData(ele);
  };

  // ================= Card Slider start
  const CardSlider = ({ classdata }) => {
    const [selectedCardIndex1, setSelectedCardIndex1] = useState(0);

    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      showArrows: false,
    };

    const cardsPerSlide = 6;

    //================= Function to generate the card components
    const generateCards = (startIndex, endIndex) => {
      return classdata?.schedules
        .filter((s) => s.activated)
        ?.slice(startIndex, endIndex)

        .map((ele, i) => (
          <Grid item xs={12} sm={12} md={12} lg={6} key={startIndex + i}>
            <Card
              ele={ele}
              i={startIndex + i}
              selectedCardIndex1={selectedCardIndex1}
              onClick={(index) => setSelectedCardIndex1(index)}
            />
          </Grid>
        ));
    };

    // ==================Calculate the number of slides needed
    const numSlides = Math.ceil(classdata?.schedules?.length / cardsPerSlide);

    // ==================Use the generateCards function for each slide
    const sliderContent = Array.from({ length: numSlides }, (_, slideIndex) => (
      <>
        <Box
          key={slideIndex}
          sx={{ padding: { xs: "30px, 20px", md: "30px 50px" } }}>
          <Grid container spacing={2} sx={{ display: "flex" }}>
            {generateCards(
              slideIndex * cardsPerSlide,
              (slideIndex + 1) * cardsPerSlide
            )}
          </Grid>
        </Box>
      </>
    ));

    return (
      <Slider ref={sliderRef} {...settings}>
        {sliderContent}
      </Slider>
    );
  };

  // =========== Reusable Card start
  const Card = ({ ele, i, selectedCardIndex1, onClick }) => {
    return (
      <>
        <Box
          // md={6}
          sx={{
            p: i % 2 === 0 ? 2.5 : 2.3,
            // height: { xs: "100%", md: "150px" },
            backgroundColor: "#ffff",
            // backgroundColor: i === selectedCardIndex1 ? "#F5B901" : "#ffff",
            // color: i === selectedCardIndex1 ? "#212121" : "grey",
            borderRadius: "5px",
            boxShadow: 5,
            "&:hover": {
              backgroundColor: theme.palette.secondary.main,
              color: "#212121",
            },
          }}
          // onClick={toggleDrawer("right", true, ele)}
          onClick={toggleDrawer("right", true, ele)}>
          {/* {ele?.weekType === "WeekEnd" ? (
            <>
              <CardBox1>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <DateContainer
                    sx={{
                      // fontWeight: selectedCardIndex1 === i ? "bold" : "normal",
                      fontWeight: "normal",
                    }}
                  >
                    {moment(ele?.startDate).format("ddd, Do MMM YYYY")}
                  </DateContainer>
                  <EastArrow>
                    <EastIcon />
                  </EastArrow>
                  <DateContainer
                  // sx={{
                  //   fontWeight: selectedCardIndex1 === i ? "bold" : "normal",
                  // }}
                  >
                    {moment(ele?.endDate).format("ddd, Do MMM YYYY")}
                  </DateContainer>
                </Box>
                <Sal gap={2}>
                  <s
                    style={{
                      color: "gray",
                      marginRight: "10px",
                      fontSize: "18px",
                    }}
                  >
                    ₹ {onlineInstructor?.standardPrice}
                  </s>
                  <Typography variant="h5">
                    ₹ {onlineInstructor?.earlyBirdPrice}
                  </Typography>
                </Sal>
              </CardBox1>
              <CardWeek>
                <DateContainer
                // sx={{
                //   fontWeight: selectedCardIndex1 === i ? "bold" : "normal",
                // }}
                >
                  Duration: {ele?.hoursPerDay} hrs/day
                </DateContainer>
                <Box
                  backgroundColor={theme.palette.primary.main}
                  color="#fff"
                  p={1}
                  borderRadius="3px"
                >
                  {ele?.weekType} - {ele?.startTime} to {ele?.endTime}
                </Box>
              </CardWeek>
            </>
          ) : (
            <>
              <CardBox1>
                <Box sx={{ display: "flex", alignItems: "center" }} gap={1}>
                  <DateContainer
                    sx={{
                      fontWeight: selectedCardIndex1 === i ? "bold" : "normal",
                    }}
                  >
                    {moment(ele?.startDate).format("ddd, Do MMM YYYY")}
                  </DateContainer>
                  <EastArrow>
                    <EastIcon />
                  </EastArrow>
                  <DateContainer
                    sx={{
                      fontWeight: selectedCardIndex1 === i ? "bold" : "normal",
                    }}
                  >
                    {moment(ele?.endDate).format("ddd, Do MMM YYYY")}
                  </DateContainer>
                </Box>
                <Sal gap={2}>
                  <s
                    style={{
                      color: "gray",
                      marginRight: "10px",
                      fontSize: "18px",
                    }}
                  >
                    ₹ {onlineInstructor?.standardPrice}
                  </s>
                  <Typography variant="h5">
                    ₹ {onlineInstructor?.earlyBirdPrice}
                  </Typography>
                </Sal>
              </CardBox1>

              <CardBox1>
                <DateContainer
                  sx={{
                    fontWeight: selectedCardIndex1 === i ? "bold" : "normal",
                  }}
                >
                  Duration: {ele?.hoursPerDay} hrs/day
                </DateContainer>
                <Box
                  backgroundColor={theme.palette.primary.main}
                  color="#fff"
                  borderRadius="3px"
                  p={1}
                >
                  {ele?.weekType} - {ele?.startTime} to {ele?.endTime}
                </Box>
              </CardBox1>
            </>
          )} */}

          <>
            <CardBox1>
              <Box sx={{ display: "flex", alignItems: "center" }} gap={1}>
                <DateContainer
                  sx={{
                    fontWeight: "bold",
                  }}>
                  {moment(ele?.startDate).format("ddd, Do MMM YYYY")}
                </DateContainer>
                <EastArrow>
                  <EastIcon />
                </EastArrow>
                <DateContainer
                  sx={{
                    fontWeight: "bold",
                  }}>
                  {moment(ele?.endDate).format("ddd, Do MMM YYYY")}
                </DateContainer>
                <br />
              </Box>
              <Sal gap={2}>
                <Box sx={{ display: "flex" }}>
                  <s
                    style={{
                      color: "gray",
                      marginRight: "10px",
                      fontSize: "18px",
                    }}>
                    ₹
                  </s>
                  <s
                    style={{
                      color: "gray",
                      marginRight: "10px",
                      fontSize: "18px",
                    }}>
                    {onlineInstructor?.standardPrice}
                  </s>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Typography variant="h5">₹</Typography>
                  <Typography variant="h5">
                    {onlineInstructor?.earlyBirdPrice}
                  </Typography>
                </Box>
              </Sal>
            </CardBox1>
            {/* <Typography variant="body1" fontWeight={600}></Typography> */}
            <CardBox1>
              <DateContainer
                sx={{
                  fontWeight: "bold",
                }}>
                Duration: {ele?.hoursPerDay} hrs/day
              </DateContainer>

              <Box
                backgroundColor={theme.palette.primary.main}
                color="#fff"
                borderRadius="3px"
                p={1}>
                {ele?.weekType.toLowerCase()} - {ele?.startTime} to{" "}
                {ele?.endTime}
              </Box>
            </CardBox1>
            <Typography
              variant="body"
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                fontSize: "14px",
                color: "gray",
                mt: "5px",
              }}>
              *Applicable taxes may apply
            </Typography>
          </>
        </Box>
      </>
    );
  };

  return (
    <>
      {/* ============call the card */}
      {selectedCourse.programmeDates &&
      selectedCourse.programmeDates.includes("onlineInstructorLed") ? (
        <div>
          <Box
            sx={{
              display: "flex",
              width: "95%",
              justifyContent: "space-between",
              alignItems: "center",
              fontFamily: "Inter",
              textAlign: "center",
              flexDirection: { xs: "column", md: "row" },
            }}>
            <Typography sx={{ fontSize: 24, padding: "10px 50px" }}>
              Select an Upcoming class
            </Typography>
            <Stack
              direction={{ xs: "column", md: "row" }}
              gap={2}
              mb={1}
              textAlign="center"
              justifyContent="center">
              <Typography variant="subtitle1" mt={1}>
                Can't find a convenient schedule? Let Us Know
              </Typography>
              <Button
                variant="contained"
                type="submit"
                onClick={handleOpenRequest}
                sx={{ mb: { xs: "20px", md: "0" } }}>
                Request
              </Button>
            </Stack>
          </Box>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            {onlineInstructor?.schedules.filter((s) => s.activated).length >
              6 && (
              <ArrowBox>
                <Arrow onClick={() => sliderRef.current.slickPrev()}>
                  <ArrowBack />
                </Arrow>
                <Arrow onClick={() => sliderRef.current.slickNext()}>
                  <ArrowForward />
                </Arrow>
              </ArrowBox>
            )}

            <CardSlider classdata={onlineInstructor} />
          </Grid>

          {/* =============Right model online instructor-led  start */}
          <Drawer
            anchor={"right"}
            open={state["right"]}
            onClose={toggleDrawer("right", false)}>
            <Box sx={{ p: 4 }}>
              <SelectForEnquery
                state={state}
                setState={setState}
                getEnquery={courseData}
                courseId={onlineInstructor?._id}
                earlyBirdPrice={onlineInstructor?.earlyBirdPrice}
                standardPrice={onlineInstructor?.standardPrice}
                programmeName={programmeName?.title}
                handleOpen={handleOpen}
                courseTitle={courseTitle}
              />
            </Box>
          </Drawer>
          <ContactCourseInfo
            openContact={openContact}
            handleClose={handleClose}
            handleOpen={handleOpen}
            courseId={onlineInstructor?._id}
            programmeName={programmeName?.title}
            getEnquery={courseData}
          />
          <RequestModel
            openRequest={openRequest}
            handleClose={handleClose}
            programmeName={programmeName?.title}
          />
        </div>
      ) : (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",

            flexDirection: { xs: "column", md: "row" },
            marginTop: "30px",
          }}>
          <Typography sx={{ fontSize: 20 }}>
            {ProgramNames["Online Instructor-Led"]} information is not available
            for this course
          </Typography>
        </Box>
      )}
    </>
  );
};
