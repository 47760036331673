// import { Box, Container, Grid, Typography, styled } from "@mui/material";
// import Terms from "../assets/Images/privacurefund2.jpg";

// import React from "react";

// const Content = styled(Typography)(() => ({
//   color: "#555",
//   lineHeight: "24px",
//   margin: "15px 0",
//   fontSize: "15px",
//   textAlign: "justify",
//   fontFamily:"Inter"
// }));
// const ContainerBox = styled(Box)(({ theme }) => ({
//   backgroundColor: "whitesmoke",
//   // padding: theme.spacing(2),
// }));

// const Ul = styled("ul")(() => ({
//   color: "#555",
//   lineHeight: "24px",
//   margin: "15px 15px",
//   fontSize: "15px",
//   textAlign: "justify",
// }));

// const Title = styled(Typography)(() => ({
//   fontFamily: "Quicksand",
//   fontSize: 25,
//   fontWeight: 700,
// }));
// const TermsCondtions = () => {
//   let a = 1;
//   return (
//     <>
//       <ContainerBox>
//         <Box
//           sx={{
//             // backgroundImage: `url(${Terms})`,
//             backgroundImage:
//           `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
//           url(${Terms})`,
//             backgroundSize: "cover",
//             backgroundRepeat: "no-repeat",
//           }}
//         >
//           <Typography
//             sx={{
//               textAlign: "center",
//               fontSize: "50px",
//               fontWeight: "bold",
//               color: "#fff",
//               padding: "5% 0",
//               fontFamily: "Quicksand",
//             }}
//           >
//             Terms & Conditions
//           </Typography>
//         </Box>
//         <Container>
//           <Grid container py={4}>
//             <Grid item xs={12} md={12} p={2}></Grid>

//             <Grid item xs={12} sm={12} md={12} lg={12}>
//               <Title>Intellectual Property Rights</Title>

//               <Ul>
//                 <li>
//                   The Service, the Website, and all the information and content
//                   available on the Website (referred to as the "Content") are
//                   safeguarded by international copyright, trademark, and other
//                   legal provisions. They are the property of Aphia Learning or
//                   its licensors, partners, affiliates, or third parties. You are
//                   allowed to utilize the Website, the Service, and the Content
//                   for your personal, non-commercial purposes exclusively. You
//                   can download and print the materials provided on the Website
//                   solely for your personal, non-commercial use. However, you are
//                   required to obtain written consent from Aphia learning before
//                   downloading, copying, reproducing, displaying, distributing,
//                   transmitting, publishing, or transferring any Content or
//                   information acquired from the Site or the Service, or creating
//                   derivative works based on such Content or information. It is
//                   not permissible to replicate or frame the Website's home page
//                   or any other pages on other websites or web pages.
//                   Establishing "deep links" to the Website, i.e., generating
//                   links that bypass the home page or other segments of the
//                   Website, is prohibited. Using any of the trademarks or logos
//                   featured on the Website without the explicit written
//                   permission of the trademark owner is not allowed, except as
//                   authorized by applicable laws.
//                 </li>
//                 <li>
//                   You are allowed to download and print the available materials
//                   solely for your personal, non-commercial use. However, you
//                   must refrain from downloading, copying, reproducing,
//                   displaying, distributing, transmitting, publishing, or
//                   transferring any Content or information from the Site or the
//                   Service, or creating derivative works based on them, without
//                   obtaining prior written permission from Aphia Learning.
//                   Additionally, it is not permissible to replicate or frame the
//                   Site's home page or any other pages on external websites or
//                   web pages. Establishing "deep links" to the Site, meaning
//                   creating links that circumvent the home page or other sections
//                   of the Site, is strictly prohibited. Using any of the
//                   trademarks or logos featured on the Site without explicit
//                   written consent from the trademark owner is also not allowed,
//                   except as permitted by applicable laws.
//                 </li>
//               </Ul>
//             </Grid>
//             <Grid item xs={12} md={12} lg={12}>
//               <Title>Transaction Terms</Title>
//               <Ul>
//                 <li>
//                   Transactions involving the purchase or sale of goods or
//                   services through the Site will be subject to the Terms of Use,
//                   unless expressly specified otherwise in a specific section of
//                   the Site. Any additional or differing terms found in purchase
//                   orders, documents, transmissions, or other communications will
//                   not be considered binding on Aphia Learning unless Aphia
//                   Learning agrees to them in writing. Aphia Learning retains the
//                   right, at its sole discretion and without prior notice, to
//                   restrict the quantity of items ordered and to decline service
//                   to any individual.
//                 </li>
//                 <li>
//                   As a merchant, we bear no responsibility for any loss or
//                   damage, whether direct or indirect, arising from the rejection
//                   of authorization for any Transaction due to the Cardholder
//                   exceeding the preset limit agreed upon between us and our
//                   acquiring bank from time to time.
//                 </li>
//               </Ul>
//             </Grid>
//             <Grid item xs={12} md={12} lg={12}>
//               <Title>Pricing Disclaimer</Title>
//               <Content>
//                 Aphia Learning retains the right to make adjustments to prices,
//                 products, and offers in response to changing market conditions,
//                 course discontinuations, provider's price modifications,
//                 advertising errors, and other exceptional circumstances.
//                 Nevertheless, the price you paid at the moment of purchase will
//                 remain valid for you. Please be aware that information presented
//                 on Aphia Learning may be modified at any time without prior
//                 notice or obligation.
//               </Content>
//             </Grid>
//           </Grid>
//         </Container>
//       </ContainerBox>
//     </>
//   );
// };

// export default TermsCondtions;

//=================previous code=====================//

import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  styled,
} from "@mui/material";
import Terms from "../assets/Images/privacurefund2.jpg";

import React from "react";
import useScrollToTopOnNavigation from "../hooks/useScrollToTopNavigation";

const Content = styled(Typography)(() => ({
  color: "#555",
  lineHeight: "24px",
  margin: "15px 0",
  fontSize: "15px",
  textAlign: "left",
  fontFamily: "Inter",
}));
const ContainerBox = styled(Box)(({ theme }) => ({
  backgroundColor: "whitesmoke",
  // padding: theme.spacing(2),
}));

const Ul = styled("ul")(() => ({
  color: "#555",
  lineHeight: "24px",
  margin: "15px 15px",
  fontSize: "15px",
  textAlign: "justify",
}));

const Ol = styled("ol")(() => ({
  color: "#555",
  lineHeight: "24px",
  margin: "15px 15px",
  fontSize: "15px",
  textAlign: "justify",
}));

const Title = styled(Typography)(() => ({
  fontFamily: "Quicksand",
  fontSize: 25,
  fontWeight: 700,
}));

const data = [
  {
    content:
      "Except as mentioned below, all information, content, material, trademarks, services marks, trade names, and trade secrets including but not limited to the software, text, images, graphics, video, script, and audio, contained in the Application, Website, Services and products are proprietary property of the Company (“Proprietary Information”). No Proprietary Information may be copied, downloaded, reproduced, modified, republished, uploaded, posted, transmitted, or distributed in any way without obtaining prior written permission from the Company and nothing on this application/website/services/products shall be or products deemed to confer a license of or any other right, interest or title to or in any of the intellectual property rights belonging to the Company, to the User. You may own the medium on which the information, content, or materials reside, but the Company shall at all times retain full and complete title to the information, content, or materials and all intellectual property rights inserted by the Company on such medium. Certain contents on the website may belong to third parties. Such contents have been reproduced after obtaining prior consent from said party and all rights relating to such content will remain with such third party. Further, you recognize and acknowledge that the ownership of all trademarks, copyrights, logos, service marks, and other intellectual property owned by any third party shall continue to vest with such party and You are not permitted to use the same without the consent of the respective third party.",
  },
  {
    content:
      "Your use of our application/website/services/products is solely for Your personal and non-commercial use. Any use of the application/website/services/products or their contents other than for personal purposes is prohibited. Your personal and non-commercial use of this application/website/services/products shall be subjected to the following restrictions:",
    subcontent: [
      "You may not decompile, reverse engineer, or disassemble the contents of the application and /or our website and/or services/ products or modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information or software obtained from the application/website/services/products, or remove any copyright, trademark registration, or other proprietary notices from the contents of the application and/or our website and/or services/products.",
      "You will not (a) use this application/website/services/products for commercial purposes of any kind, or (b) advertise or sell the application/website/ services/products or domain names or otherwise (whether or not for profit), or solicit others (including, without limitation, solicitations for contributions or donations) or use any public forum for commercial purposes of any kind, or (c) use the application/website/services/products in any way that is unlawful, or harms the Company or any other person or entity as determined by the Company.",
      "No User shall be permitted to perform any of the following prohibited activities while availing our Services:",
    ],
    n: [
      "Stalking, intimidating, and/or harassing another and/or inciting other to commit violence;",
      "Making available any content that is misleading, unlawful, harmful, threatening, abusive, tortious, defamatory, libelous, vulgar, obscene, child-pornographic, lewd, lascivious, profane, invasive of another’s privacy, hateful, or racially, ethnically or otherwise objectionable;",
      "Interfering with any other person’s use or enjoyment of the application/website/services/products;",
      "Transmitting material that encourages anyone to commit a criminal offense, that results in civil liability or otherwise breaches any relevant laws, regulations, or code of practice;",
      "Making, transmitting, or storing electronic copies of materials protected by copyright without the permission of the owner, committing any act that amounts to the infringement of intellectual property, or making available any material that infringes any intellectual property rights or other proprietary rights of anyone else;",
      "Post, transmit, or make available any material that contains viruses, trojan horses, worms, spyware, time bombs, cancelbots, or other computer programming routines, code, files, or such other programs that may harm the Application/services, interests, or rights of other users or limit the functionality of any computer software, hardware or telecommunications, or that may harvest or collect any data or personal information about other Users without their consent;",
      "Make available any content or material that You do not have a right to make available under any law or contractual or fiduciary relationship unless You own or control the rights thereto or have received all necessary consents for such use of the content;",
      "Intentionally or unintentionally interfere with or disrupt the services or violate any applicable laws related to the access to or use of the Application/Website/Services/products, violate any requirements, procedures, policies or regulations of networks connected to the Application/Website/Services/products, or engage in any activity prohibited by these Terms;",
      "Impersonate any person or entity, or falsely state or otherwise misrepresent Your affiliation with a person or entity;",
      "Access or use the Application/Website/Services/products in any manner that could damage, disable, overburden, or impair any of the Application’s/Website’s servers or the networks connected to any of the servers on which the Application/Website is hosted;",
      "Use deep-links, page-scrape, robot, spider, or other automatic device, program, algorithm or methodology, or any similar or equivalent manual process, to increase traffic to the application/website/services/products, to access, acquire, copy or monitor any portion of the application/website/services/products, or in any way reproduce or circumvent the navigational structure or presentation of the Application, or any content, to obtain or attempt to obtain any content, documents or information through any means not specifically made available through the application/website/services/products;",
      "Interfere with, or inhibit any user from using and enjoying access to the application/website/services/products, or other affiliated sites, or engage in disruptive attacks such as denial of service attack on the application/website/services/products;",
      "Disrupt or interfere with the security of, or otherwise cause harm to, the application/website/services/products, materials, systems resources, or gain unauthorized access to user accounts, passwords, servers, or networks connected to or accessible through the application/website/services/products or any affiliated or linked sites;",
      "Alter or modify any part of the services;",
      "Use the services for purposes that are not permitted by: (i) these Terms; and (ii) any applicable law, regulation, or generally accepted practices or guidelines in the relevant jurisdiction; or",
      "Violate any of the terms specified under the Terms for the use of the application/website/services/products.",
    ],
  },
  {
    content:
      "By submitting content on or through the Services (your “Material”), you grant us a worldwide, non-exclusive, royalty-free license (with the right to sublicense) to use, copy, reproduce, process, adapt, modify, publish, transmit, display and distribute such Material in any and all media or distribution methods (now known or later developed) and to associate your Material with you, except as described below. You agree that others may use Your Material in the same way as any other content available through the Services. Other users of the Services may fork, tweak, and repurpose your Material in accordance with these Terms. If you delete your user account your Material and name may remain available through the Services",
  },
  {
    content:
      "In the preparation of the application/website/services/products and contents therein, every effort has been made to offer the most current, correct, and clearly expressed information possible. Nevertheless, inadvertent errors may occur. In particular, but without limiting anything here, the Company disclaims any responsibility for any errors and accuracy of the information that may be contained in the application. Any feedback from the User is most welcome to make the application and contents thereof error-free and user-friendly. The company also reserves the right and discretion to make any changes/corrections or withdraw/add content at any time without notice. Neither the Company nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness, or suitability of the information and materials found or offered on the application/website/services/products for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.",
  },
  {
    content:
      "Our website provides users with access to compiled educational information and related sources. Such information is provided on an As-Isbasis and we assume no liability for the accuracy, completeness, use, or non-obsolescence of such information. We shall not be liable to update or ensure the continuity of such information contained on the website. We would not be responsible for any errors that might appear in such information, which is compiled from third party sources, or for any unavailability of such information. From time to time the website may also include links to other websites. These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s). You may not create a link to the website from another website or document without the Company’s prior written consent.",
  },
  {
    content:
      "The contents of the application/website/services/products are developed on the concepts covered in the structured training syllabus prescribed for trainees/students of various courses. The usage of the application/website/services/products is not endorsed as a substitution for the curriculum-based education provided by the educational institutions. The Company acknowledges that there are various means of delivering trainingprograms and inclusion of methods in the application/website/services/products does not imply endorsement of any particular methodnor exclusion implies disapproval. Subscription to the application, training, or usage of our application/website/services/products does not in any manner guarantee admission to any educational institutions, employment, passing of any exams, or achievement of any specified percentage of marks in any examinations.",
  },
  {
    content:
      "Certain contents in the application/website/services/products (in particular relating to assistance in preparations for administrative services) may contain opinions and views. The Company shall not be responsible for such opinions or any claims resulting from them. Further, the Company makes no warranties or representations whatsoever regarding the quality, content, completeness, or adequacy of such information and data.",
  },
  {
    content:
      "Some parts of the services are interactive, and we encourage contributions by users, which may or may not be subject to editorial control prior to being posted. The company accepts no responsibility or liability for any material communicated by third parties in this way. The company reserves the right at its sole discretion to remove, review, edit, or delete any content. Similarly, the company will not be responsible or liable for any content uploaded by users directly on the website, irrespective of whether we have certified any answer uploaded by the user. The company would not be responsible for verifying whether such questions/answers or contents placed by any user contain infringing materials or not.",
  },
  {
    content:
      "The Company (including but not limited to its subsidiaries/affiliates) may, based on any form of access to the application (including free download/trials) or services or website or registrations through any source whatsoever, contact the user through sms, email and call, to give information about their offerings and products as well as notifications on various important updates and/or to seek permission for demonstration of its products. The user expressly grants such permission to contact him/her through telephone, SMS, e-mail and holds the company (including but not limited to its subsidiaries/affiliates) indemnified against any liabilities including financial penalties, damages, expenses in case the user’s mobile number is registered with Do not Call (DNC) database. By registering yourself, you agree to make your contact details available to Our employees, associates, subsidiaries,affiliates, and partners so that you may be contacted for education information, offerings, and promotions through telephone, SMS, email etc.",
  },
  {
    content:
      "While the company may, based on the user’s confirmation, facilitate the demonstration of its products at the location sought by the user, the user acknowledges that he/she has not been induced by any statements or representations of any person with respect to the quality or conditions of the products and that user has relied solely on the investigations, examinations, and inspections as the user has chosen to make and that the company has afforded the user the opportunity for full and complete investigations, examinations and inspections.",
  },
  {
    content:
      "Upon registration through any means whatsoever, the Company may contact You through the registered mobile number or e-mail or any other mobile number or contact number or email provided by You to enable the effective provision of services. The user expressly permits the company to contact him/her and the trainee/ studentutilising the services, through the above-mentioned means at any time post-registration. Further, the Company shall have the right to monitor the download and usage of the application/website/services/products and the contents thereof by the user/trainee/student, to analyze such usage and discuss the same with the user/trainee/student to enable effective and efficient usage of the services. The user expressly permits the Company to clear the doubts of the student using the application/website/services/online portal by answering the questions placed before it, providing study plans, informing of the progress, providing feedback, communicating with the trainee and mentoring the trainee/student through telephone or e-mail on the express consent of the legal guardian/parent of the user or through any other forum.",
  },
  {
    content:
      "While the Company has made efforts to train the personnel engaged in the sales and services relating to its products to enable quality control, it makes no warranties or representations whatsoever regarding the quality and competence of such personnel and would not be responsible for any deviant behaviour of any such personnel. Any feedback from the user relating to the same is most welcome and the company reserves the right and discretion to take any action in this regard.",
  },
  {
    content:
      "Access to certain elements of the services including doubt clearance, mentoring services etc may be subject to separate terms, conditions, and fair usage policy. The company reserves the right to determine the criteria for the provision of various elements of Services to the different categories of users based on its policies. Hence, subscription to the Application/products or registrations do not automatically entitle the user to any and all elements of services provided by the company and the company shall be entitled to exercise its discretion while providing access to and determining continuity of certain elements of services. We reserve the right to extend, cancel, discontinue, prematurely withdraw, or modify any of our services at our discretion.",
  },
  {
    content:
      "The Company’s products and/orservices, including the application and content, are compatible only with certain devices/tablets/instruments/hardware. The company shall not be obligated to provide workable products and/or services for any instruments that are not recognized by the company or those instruments that may be purchased from any third party that are not compatible with the Company's products and Services. The company reserves the right to upgrade the tablet/ type of compatible devices as required from time to time.",
  },
  {
    content:
      "The Company shall have no responsibility for any loss or damage caused to the tablet or any other hardware and/or software and/or instrument, including loss of data or effect on the processing speed, resulting from your use of our products and services.",
  },
  {
    content:
      "To encourage Customer Retention and Loyalty, encourages its Customers, Technology Partners, and other stakeholders to refer customers to buy Aphia’sproducts &services. The company at its option can pay a referral bonus subject to applicable laws. Since this is an optional selective policy of the Company, the decision of the company is final and the same cannot be either disputed or challenged. It should be noted with abundant caution that no customer, technology Partner, or other stakeholder shall do business with the Company in anticipation of such Bonus. The company shall not be held responsible if some customer has done business with the objective of getting a referral bonus as the Bonus paid by the Company is purely dependent on its own discretion based on internal parameters and various demographic and economic factors.",
  },
  {
    content:
      "You have to specify the address to which the shipment has to be made at the time of purchase. All product(s) shall be delivered directly to the address as specified at the point of ordering and you cannot, under any circumstances whatsoever, change the address after the order is processed. In case of any change in the address, you need to specify the same to us in writing well in advance of the shipping date. Any inconsistencies in name or address will result in non-delivery of the product(s).",
  },
  {
    content:
      "(a) For the return of product(s) damaged at the time of delivery, the shipping charges shall be borne by the company. However, for the return of any of the product(s) for any other reasons, it shall be the responsibility of the user to arrange for the return of such cancelled product(s) and the shipping charges shall be borne by such User. (b)We request you not to accept any product package that seems to be tampered with, opened, or damaged at the time of delivery. The products must be returned in the same condition as delivered by the Company. Any products returned showing signs of any use or damage in any manner shall not be accepted for return. (c)All requests for the return of products have to be placed within 15 (fifteen) days from the date of delivery. Please note that no refunds shall be claimed or will be entertained post 15 (fifteen) days from the date of delivery.",
  },
  {
    content:
      "You acknowledge that the Company is not the manufacturer of the instrument/medium/hardware and hence, any defect relating to the same shall be reported to the manufacturer whose details shall be specified on the packaging and the Company shall not be in any manner responsible for the same. The Company does not provide any guarantee or warranty relating to the instrument/medium/hardware.",
  },
  {
    content:
      "In order to access the Services and to avail the use of the application/ website/services/products, you shall be required to register yourself with the application/website/services/productsand maintain an account with the application/website/services/products. You will be required to furnish certain information and details, including your name, mobile number, e-mail address, residential address, grade/class/degree of the student, school/college/university name, payment information (credit/debit card details) if required, and any other information deemed necessary by the application. With respect to the provision of information, the following may be noted:-",
    subcontent: [
      "	It is the user’s sole responsibility to ensure that the account information provided by you is accurate, complete, and latest.",
      "	User shall be responsible for maintaining the confidentiality of the account information and for all activities that occur under the user’s account. You agree to (a) ensure that you successfully log out from your account at the end of each session; and (b) immediately notify the company of any unauthorized use of your account. If there is reason to believe that there is likely to be a breach of security or misuse of your account, we may request you to change the password or we may suspend your account without any liability to the Company, for such period of time as we deem appropriate in the circumstances. We shall not be liable for any loss or damage arising from your failure to comply with this provision.",
      "	You acknowledge that your ability to use your account is dependent upon external factors such as internet service providers and internet network availability and the company cannot always guarantee accessibility to the application. In addition to the disclaimers set forth in the Terms, the company shall not be liable to you for any damages arising from your inability to log into your account and access the services of the application at any time.",
    ],
  },
  {
    content:
      "Persons who are “competent/capable” of contracting within the meaning of the Indian Contract Act, 1872 shall be eligible to register for the application and all our products or Services. Persons who are minors, un-discharged insolvents etc. are not eligible to register for our products or services. As a minor, if you wish to use our products or services, such use shall be made available to you by your legal guardian or parents, who has agreed to these Terms. In the event a minor utilizes the application/website/services/products, it is assumed that he/she has obtained the consent of the legal guardian or parents and such use is made available by the legal guardian or parents. The company will not be responsible for any consequence that arises as a result of misuse of any kind of our application or any of our products or services that may occur by virtue of any person including a minor registering for the services/products provided. By using the products or services you warrant that all the data provided by you is accurate and complete and that the trainee/student using the application has obtained the consent of parent/legal guardian (in case of minors). The company reserves the right to terminate your subscription and/or refuse to provide you with access to the products or services if it is discovered that you are under the age of 18 (eighteen) years and the consent to use the products or Services is not made by your parent/legal guardian or any information provided by you is inaccurate. You acknowledge that the company does not have the responsibility to ensure that you conform to the aforesaid eligibility criteria. It shall be your sole responsibility to ensure that you meet the required qualifications. Any persons under the age of 18 (eighteen) should seek the consent of their parents/legal guardians before providing any data/information about themselves or their parents and other family members on the application.",
  },
  {
    content:
      "You agree to defend, indemnify and hold harmless the Company, its officers, directors, employees, and agents, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney’s fees) arising from: (i) your violation of any term of these Terms or any other policy of the company; (ii) your use of and access of the application/website/services/products; (iii) any claim that your use of the application/website/services/products has caused damage to a third party; or (iv)your violation of any third party right, including without limitation, any copyright, property, or privacy right. This defense and indemnification obligation will survive these Terms.",
  },
  {
    content:
      "In no event shall the company, its directors,partners, officers, employees, or agents be liable to you or any third party for any special, incidental, indirect, consequential, or punitive damages whatsoever, including those resulting from loss of use, data or profits or any other claim arising out, of or in connection with, Your use of, or access to, the application.",
  },
  {
    content:
      "In the event of your breach of these Terms, you agree that the company will be irreparably harmed and may not have an adequate remedy in money or damages. The company, therefore, shall be entitled in such event to obtain an injunction against such a breach from any court of competent jurisdiction. The Company’s right to obtain such relief shall not limit its right to obtain other remedies.",
  },
  {
    content:
      "Any violation by you of the terms of this Clause may result in immediate suspension or termination of Your Accounts apart from any legal remedy that the company can avail. In such instances, the company may also disclose Your Account data/information if required by any Governmental or legal authority. You understand that the violation of these Terms could also result in civil or criminal liability under applicable laws.",
  },
  {
    content:
      "The Terms shall be governed by and construed in accordance with the laws of India, without regard to conflict of law principles. Further, the Terms shall be subject to the exclusive jurisdiction of the competent courts located in Kolkata and you hereby accede to and accept the jurisdiction of such courts.",
  },
  {
    content:
      "The Company has the right to change modify, suspend, or discontinue and/or eliminate any aspect(s), features, or functionality of the application or the services as it deems fit at any time without notice. Further, the company has the right to amend these Terms from time to time without prior notice to you. The company makes no commitment, express or implied, to maintain or continue any aspect of the application. You agree that the Company shall not be liable to you or any third party for any modification, suspension, or discontinuance of the application/services. All prices are subject to change without notice.",
  },
  {
    content:
      "Disclaimer: This Website, the application, and the services are provided on an “AS IS” basis with all faults any without any warranty of any kind. The company hereby disclaims all warranties and conditions with regard to the website, application/website/services/products and the services, including without limitation, all implied warranties and conditions of merchantability, fitness for a particular purpose, title, accuracy, timeliness, performance, completeness, suitability, and non-infringement. Additionally, the company shall not be liable for any damages arising out of or in connection with the use or performance of this site, or the application or the services. Your use of any information or materials on this website/application/services/products is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that the services provided by us meet your specific requirements.",
  },
];

const data1 = [
  {
    title: "Notice:",
    linkPresent: true,
    component: () => (
      <Typography component="span" style={{ fontSize: "15px" }}>
        All notices served by the Company shall be provided via email to your
        account or as a general notification on the Application. Any notice to
        be provided to the Company should be sent to{" "}
        <a
          href="mailto:support@aphialearning.com"
          style={{ textDecoration: "none" }}
        >
          {" "}
          support@aphialearning.com
        </a>
      </Typography>
    ),
    text: "All notices served by the Company shall be provided via email to your account or as a general notification on the Application. Any notice to be provided to the Company should be sent to support@aphialearning.com.",
  },
  {
    title: "Entire Agreement:",
    text: "The Terms, along with the Privacy Policy, and any other guidelines made applicable to the Application from time to time, constitute the entire agreement between the company and you with respect to your access to or use of the application, website, and the services thereof.",
  },
  {
    title: "Assignment:",
    text: "You cannot assign or otherwise transfer your obligations under the Terms, or any right granted hereunder to any third party. The Company’s rights under the Terms are freely transferable by the Company to any third parties without the requirement of seeking Your consent.",
  },
  {
    title: "Severability:",
    text: "If, for any reason, a court of competent jurisdiction finds any provision of the Terms, or portion thereof, to be unenforceable, that provision shall be enforced to the maximum extent permissible so as to give effect to the intent of the parties as reflected by that provision, and the remainder of the Terms shall continue in full force and effect.",
  },
  {
    title: "Waiver:",
    text: "Any failure by the Company to enforce or exercise any provision of the Terms, or any related right, shall not constitute a waiver by the Company of that provision or right.",
  },
  {
    title: "Relationship:",
    text: "You acknowledge that your participation in the application does not make you an employee, agency, partnership, joint venture, or franchise of the company.",
  },
  {
    text: "The Company provides these Terms so that you are aware of the terms that apply to your use of the application, website, services, and products. You acknowledge that, the Company has given you a reasonable opportunity to review these Terms and that you have agreed to them.",
  },
];

const data2 = [
  {
    content:
      "Any feedback You provide with respect to the application shall be deemed to be non-confidential. The application shall be free to use such information on an unrestricted basis. Further, by submitting the feedback, you represent and warrant that (i) Your feedback does not contain confidential or proprietary information of you or of third parties; (ii) the company is not under any obligation of confidentiality, express or implied, with respect to the feedback; (iii) the application may have something similar to the feedback already under consideration or in development; and (iv) You are not entitled to any compensation or reimbursement of any kind from the company for the feedback under any circumstances unless specified.",
  },
  {
    content:
      "Under no circumstances shall the company be held responsible in any manner for any content provided by other users even if such content is offensive, hurtful, or offensive. Please exercise caution while accessing the application.",
  },
];

const TermsCondtions = () => {
  useScrollToTopOnNavigation();
  let a = 29;
  let listItemNumber = a;
  const combinedData = data.concat(
    data1.map((item, index) => ({ ...item, index }))
  );
  return (
    <>
      <ContainerBox>
        <Box
          sx={{
            backgroundImage: `url(${Terms})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            height: 300,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              fontSize: { xs: "35px", md: "50px" },
              fontWeight: "bold",
              color: "black",
              padding: "5% 0",
              fontFamily: "Quicksand",
              marginBottom: "14px",
            }}
          >
            Terms & Conditions
          </Typography>
        </Box>

        <Container maxWidth="xl">
          <Grid
            container
            py={4}
            sx={{ padding: { xs: "0 0", sm: "0 0", md: "0 50px" } }}
          >
            <Grid item xs={12} md={12}>
              <Content>
                These Terms & Conditions (“Terms”) of (a) use of our website
                www.aphialearning.com (“Website”), our applications
                (“Application”), or any products or services in connection with
                the application/website/services/products (“Services”) or (b)
                any modes of registrations or usage of products, including
                through SD cards, tablets/ mobile phones/ computers or other
                storage/transmitting devices are between Aphia Learning Private
                Limited (“Company/we/us/our”) and its users (“user/you/your”).
              </Content>
              <Content>
                These Terms constitute an electronic record in accordance with
                the provisions of the Information Technology Act, 2000, and the
                Information Technology (Intermediaries guidelines) Rules, 2011
                thereunder, as amended from time to time.
              </Content>
              <Content>
                Please read the Terms and the privacy policy of the Company
                (“Privacy Policy”) with respect to registration with us, and use
                of the application, website, services, and products carefully
                before using the application, website, services, or products. In
                the event of any discrepancy between the Terms and any other
                policies with respect to the
                application/website/services/products, the provisions of the
                Terms shall prevail.
              </Content>
              <Content>
                Your use/access/browsing of the
                application/website/services/products or registration (with or
                without payment/with or without subscription) through any means
                shall signify Your acceptance of the Terms and Your agreement to
                be legally bound by the same.
              </Content>

              <Content>
                If you do not agree with the terms or the Privacy Policy, please
                do not use our application/website/services/products. Any access
                to out application/website/services/products through reqistraion
                or subscription is nontansferable
              </Content>
              <Content></Content>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Ol>
                {data.map((text, i) => (
                  <li style={{ color: "black", fontWeight: "bold" }} key={i}>
                    <Content>{text.content}</Content>
                    {text.subcontent && (
                      <Ul>
                        {text.subcontent.map((sub, index) => (
                          <li style={{ fontWeight: "normal" }} key={index}>
                            {sub}
                          </li>
                        ))}
                      </Ul>
                    )}
                    {text.n && (
                      <Ol
                        style={{ fontWeight: "lighter", color: "black" }}
                        type="I"
                      >
                        {text.n.map((item, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </Ol>
                    )}
                  </li>
                ))}
              </Ol>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
                General Provisions:
              </Typography>
              {data1.map((text, i) => (
                <Content key={i}>
                  {text.title}
                  {text.linkPresent ? text.component() : text.text}
                </Content>
              ))}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
                Feedback:
              </Typography>
              <Ul>
                {data2.map((text, i) => (
                  <li key={i}>{text.content}</li>
                ))}
              </Ul>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
                Customer Care:
              </Typography>
              <Content>
                We will make all best endeavors to provide you with a pleasant
                experience. In the unlikely event that you face any issues,
                please contact us at{" "}
                <a
                  href="mailto:support@aphialearning.com"
                  style={{ textDecoration: "none" }}
                >
                  support@aphialearning.com
                </a>{" "}
              </Content>
            </Grid>
          </Grid>
        </Container>
      </ContainerBox>
    </>
  );
};

export default TermsCondtions;
