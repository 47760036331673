import { Box, Container, Grid, Typography, styled } from "@mui/material";
import React from "react";
import RefundPolicyimg from "../assets/Images/RefundPolicy.png";
import useScrollToTopOnNavigation from "../hooks/useScrollToTopNavigation";

const Ul = styled("ul")(() => ({
  color: "#555",
  lineHeight: "24px",
  margin: "15px 25px",
  fontSize: "15px",
  textAlign: "justify",
}));
const Content = styled(Typography)(() => ({
  // color: "#555",
  lineHeight: "24px",
  margin: "10px 0",
  fontSize: "15px",
  textAlign: "justify",
}));
const Title = styled(Typography)(() => ({
  fontFamily: "Quicksand",
  fontSize: 20,
  fontWeight: 700,
}));
const RefundPolicy = () => {
  useScrollToTopOnNavigation();
  return (
    <Box bgcolor="whitesmoke">
      <Box
        sx={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), 
          url(${RefundPolicyimg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          height: 300,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: { xs: "block", md: "none" } }}>
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "50px",
              fontWeight: "bold",
              color: "#fff",
              padding: "5% 0",
              fontFamily: "Quicksand",
            }}
          >
            Refund Policy
          </Typography>
        </Box>
      </Box>
      <Container maxWidth="xl">
        <Grid
          container
          sx={{
            padding: { xs: "0 0", sm: "0 0", md: "20px 50px" },
          }}
        >
          <Grid item xs={12} md={12} lg={12} m="10px 0">
            <Title> 1. Eligibility for Refunds</Title>
            <Content>
              We offer refunds for app-based courses and subscriptions in
              certain circumstances.
            </Content>
            <Content fontWeight={600}>
              Refunds are typically considered in the following situations:
            </Content>
            <Content>
              <i style={{ fontWeight: "bold" }}>Technical issues: </i>If a
              technical problem with our app prevents you from accessing the
              content.
            </Content>
            <Content>
              <i style={{ fontWeight: "bold" }}>Misrepresentation: </i> If the
              course content substantially differs from what was advertised.
            </Content>
            <Content>
              <i style={{ fontWeight: "bold" }}>Duplicate purchase: </i>
              If you accidentally purchase the same course or subscription more
              than once.
            </Content>
            <Content>
              Cancelation during the cooling-off period (where applicable).
            </Content>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Title>2. Refund Request Process</Title>
            <Content>
              To request a refund, contact our customer support team within 15
              days of your purchase or subscription renewal.
            </Content>
            <Content>
              Contact our customer support team through{" "}
              <a
                href="mailto:support@aphialearning.com"
                style={{ textDecoration: "none" }}
              >
                support@aphialearning.com
              </a>{" "}
            </Content>
            <Content>
              Please provide the following information in your refund request:
            </Content>
            <Content>Your name and contact information.</Content>
            <Content>The reason for your refund request.</Content>
            <Content>
              Your purchase or subscription details (e.g., order number).
            </Content>
            <Content>
              Any relevant proof, such as screenshots, error messages, or other
              documentation.
            </Content>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Title>3. Refund Decisions</Title>
            <Content>
              We will review your refund request within 30 business days.
            </Content>
            <Content>
              Refunds will be issued at our discretion based on the eligibility
              criteria outlined in section 1.
            </Content>
            <Content>
              Refunds may be provided in the form of the original payment method
              or as credit for future purchases, as determined by our team.
            </Content>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Title>4. Refund Amounts</Title>
            <Content>The refund will typically cover as following:</Content>
            <Content pl={2}>
              (i) 90% of the purchase price if the request is made within 15
              days of purchase or renewal,
            </Content>
            <Content pl={2}>
              (ii) 75% of the purchase price if the request is made within 16
              days to 30 days of purchase or renewal.
            </Content>
            <Content>
              Any fees associated with the original transaction may not be
              refundable (e.g., processing fees by payment providers).
            </Content>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Title>5. Cooling-Off Period</Title>
            <Content>
              If you are in a jurisdiction where a cooling-off period is
              mandated by law, you have 15 days to request a refund after your
              initial purchase.
            </Content>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Title>6. No Refund Policy Exceptions</Title>
            <Content>
              We generally do not offer refunds in the following situations:
            </Content>
            <Content>
              If you have consumed a substantial portion of the course content.
            </Content>
            <Content>
              If you have violated our terms of service or policies.
            </Content>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Title>7. Changes to the Refund Policy</Title>
            <Content>
              We reserve the right to modify this refund policy at any time, and
              any changes will be effective upon posting the updated policy on
              our website.
            </Content>
          </Grid>

          {/* <Grid item xs={12} sm={12} md={12}>
            <Typography color="#555">
              Aphia's, your own ed-tech company, has the following refund
              policies:{" "}
            </Typography>
            <Typography color="#555">Aphia’s Learning App</Typography>
            <Content>
              You can cancel your subscription within 15 days of purchase for a
              full refund. After 15 days, there are no refunds, but you can
              cancel your subscription at any time.
            </Content>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Title>Refund requests</Title>
            <Content>
              A refund request is valid if it is made through an email to{" "}
              <a
                href="mailto:support@aphialearning.com"
                style={{ textDecoration: "none" }}>
                support@aphialearning.com
              </a>{" "}
              within 15 days from the purchase or renewal of the course.
            </Content>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Title>Refund eligibility</Title>
            <Content>
              You are eligible for a full refund within 15 days after the
              purchase or renewal date or activation date, or orientation date
              (whichever is earlier).
            </Content>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Title>Mode of refund</Title>
            <Content>
              All applicable refunds are paid through cheques or online transfer
              within 30 calendar days from the date on which a written
              confirmation is provided to the customer.
            </Content>
          </Grid> */}
        </Grid>
      </Container>
    </Box>
  );
};

export default RefundPolicy;
