import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  Typography,
  styled,
} from "@mui/material";
import affiliateprogram from "../assets/Images/affiliateprogram.jpg";
import FormInput from "../form/FormInput";
import { Form, useFormik } from "formik";
import * as Yup from "yup";
import urls from "../apis/urlContants";
import { post } from "../apis/apiMethods";
import whiteSirt from "../assets/Images/whiteSirt.png";
import smileGirl from "../assets/Images/smileGirl.png";

import useScrollToTopOnNavigation from "../hooks/useScrollToTopNavigation";
// icons
import { LoadingButton } from "@mui/lab";
import { theme } from "../theme";

const affiiliateProgram = [
  {
    heading: "Commitment to Excellence",
    subHeading:
      "We uphold a reputation for delivering high-quality education, providing cutting-edge resources, and fostering an environment conducive to learning and growth.",
  },
  {
    heading: "High-Quality Education",
    subHeading:
      "Partner with a trusted education provider that offers top-notch courses and resources designed to empower learners of all levels.",
  },
  {
    heading: "Lucrative Commissions",
    subHeading:
      "Earn generous commissions for every customer you refer to us. The more you refer, the more you earn!",
  },
  {
    heading: "Flexibility and Accessibility",
    subHeading:
      "Our Affiliate Program offers flexibility in terms of marketing strategies and accessibility, allowing you to leverage various platforms and reach a wider audience.",
  },
];

const Heading = styled(Typography)(() => ({
  color: "#000",
  marginBottom: "20px",
  // marginTop: "15px",
  fontFamily: "Quicksand",
  fontSize: 20,
  fontWeight: 600,
}));

function AffiliateProgram() {
  useScrollToTopOnNavigation();
  const [submittingForm, setSubmittingForm] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      contactNumber: "",
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required().label("Name").max(150),
      email: Yup.string().email().required().label("Email").max(150),
      contactNumber: Yup.number()
        .nullable()
        .required()
        .label("Contact Number")
        .min(1000000000, "Invalid contact number")
        .max(999999999999, "Invalid contact number"),
      message: Yup.string().required().label("yourselef"),
    }),
    onSubmit: (values) => {
      try {
        const body = {
          subject: "Affiliate Program",
          customerDetails: {
            name: values.name,
            email: values.email,
            contactNumber: values.contactNumber,
          },
          enquiryDetails: {
            programmeDetails: {},
          },
          message: values.message,
        };
        setSubmittingForm(true);
        const { data } = post(urls.enquiry.createForm, body);
        formik.resetForm();
      } catch (error) {
        console.error(error);
      }
      setSubmittingForm(false);
    },
    validateOnBlur: false,
    enableReinitialize: true,
  });

  return (
    <>
      <Box
        sx={{
          backgroundImage: `url(${affiliateprogram})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "100%",
          padding: { xs: "100px 0", sm: "100px 0", md: "170px 50px" },
        }}
      >
        <Container maxWidth="xl">
          <Typography
            variant="h2"
            sx={{
              fontFamily: "Quicksand",
              fontSize: "3.7rem",
              color: "black",
              fontSize: { xs: "24px", sm: "36px", md: "52px" },
              fontWeight: 700,
            }}
          >
            Affiliate Program
          </Typography>
        </Container>
      </Box>
      <Box bgcolor="whitesmoke">
        <Container maxWidth="xl">
          <Grid
            container
            spacing={2}
            sx={{
              padding: { xs: "20px 0", sm: "20px 0", md: "0px 50px" },
            }}
            my={2}
          >
            <Grid item xs={12} sm={12} md={8}>
              <Heading variant="body1">
                Why Choose Our Affiliate Program{" "}
              </Heading>
              <Typography variant="body1">
                Our Affiliate Program stands out as an exceptional opportunity
                for those seeking a rewarding partnership in the realm of
                education and professional development. Here's why you should
                consider choosing our Affiliate Program:
              </Typography>

              {affiiliateProgram.map((affiiliateProgramItem, i) => (
                <Typography variant="body1" mt={2} key={i}>
                  <Typography variant="span" fontWeight="bold">
                    {affiiliateProgramItem.heading}{" "}
                  </Typography>
                  {affiiliateProgramItem.subHeading}
                </Typography>
              ))}
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              sx={{
                display: "flex",
                justifyContent: { xs: "center", sm: "center", md: "end" },
              }}
            >
              <img
                style={{
                  width: "80%",
                  borderRadius: 5,
                }}
                src={whiteSirt}
                alt="Lodding..."
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Heading variant="body1" mt={2} mb={5}>
                Joining our affiliate program is quick and easy. Simply fill out
                the application form, and we'll review your application.
              </Heading>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <img
                style={{
                  width: "60%",
                  borderRadius: 5,
                }}
                src={smileGirl}
                alt="Loading..."
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Box
                component={"form"}
                onSubmit={formik.handleSubmit}
                //   boxShadow={4}
                p={3}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  borderRadius: "5px",
                  height: "100%",
                  border: `1px solid ${theme.palette.primary.main}`,
                  backgroundColor: "transparant",
                }}
              >
                <Typography variant="h6" textAlign="center" mb={1}>
                  Affiliate Program
                </Typography>
                <FormInput
                  formik={formik}
                  name="name"
                  type="text"
                  label="Full Name"
                />
                <FormInput
                  formik={formik}
                  name="email"
                  type="email"
                  label="Email"
                />

                <FormInput
                  formik={formik}
                  name="contactNumber"
                  label="Contact No."
                  type="number"
                />

                <FormInput
                  formik={formik}
                  name="message"
                  type="text"
                  label="Tell us about yourself"
                />
                <LoadingButton
                  loading={submittingForm}
                  disableElevation
                  type="submit"
                  variant="contained"
                  size="small"
                  sx={{
                    width: "20%",
                    marginBottom: "20px",
                  }}
                >
                  Send
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default AffiliateProgram;
