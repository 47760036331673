import { Box, Container, Grid, Typography, styled } from "@mui/material";
import React from "react";
import { logo } from "../components/Homepage/CourseSubData";

const Heading = styled(Typography)(() => ({
  fontSize: 35,
  fontWeight: 700,
  fontFamily: "Quicksand",
  padding: "20px 0",
}));

const Main = styled(Box)(() => ({
  whiteSpace: "nowrap",
  width: "100%",
  padding: "10px 20px",
}));

const Partners = () => {
  return (
    <>
      <Container
        maxWidth="xl"
        sx={{
          display: "flex",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            padding: { xs: "0 0", sm: "0 0", md: "20px 0px" },
          }}
        >
          {/* <Heading my={2}>Our Recruitment Partners!</Heading> */}
          <Main>
            <div className="slide-animation">
              {logo.map((ele, i) => (
                <img
                  key={i}
                  height={100}
                  width={150}
                  src={ele.image}
                  alt="Loding..."
                  style={{
                    margin: "0 30px 30px 0",
                    objectFit: "contain",
                  }}
                />
              ))}
              {/* Duplicate the images to ensure continuous sliding */}
              {logo.map((ele, i) => (
                <img
                  key={i}
                  height={100}
                  width={150}
                  src={ele.image}
                  alt="Loding..."
                  style={{
                    margin: "0 30px 30px 0",
                    objectFit: "contain",
                  }}
                />
              ))}
              {logo.map((ele, i) => (
                <img
                  key={i}
                  height={100}
                  width={150}
                  src={ele.image}
                  alt="Loding..."
                  style={{
                    margin: "0 30px 30px 0",
                    objectFit: "contain",
                  }}
                />
              ))}
            </div>
          </Main>
        </Box>
      </Container>
    </>
  );
};

export default Partners;
