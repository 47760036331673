import { post, put, get } from "./apiMethods";
const authentication = {
  signUp: (data) => post("/account/create", data),
  regOtp: (body) => post(`/account/registrationOtp`, body),
  login: (data) => {
    console.log(data, "bssssssssssody");
    return post("/account/login", data);
  },
};

export default authentication;
