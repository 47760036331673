import React, { useEffect } from "react";
import Classes from "./Classes";
import PmpCertificate from "../PmpCertificate";
import OverView from "../OverView";
import Details from "../../components/Details/Details";
import urls from "../../apis/urlContants";
import { get } from "../../apis/apiMethods";
import { useContext } from "react";
import { CourseContext, EnqueryBookdata } from "../../context/CourseContext";
import { useLocation, useNavigate } from "react-router-dom";
import useScrollToTopOnNavigation from "../../hooks/useScrollToTopNavigation";
import { Container } from "@mui/material";

export const MainAllClass = () => {
  useScrollToTopOnNavigation();
  const [selectCourse, setSelectCourseData] = React.useState([]);
  const navigation = useLocation();
  const navigate = useNavigate();
  let courseId = "";

  // console.log(selectCourse, "hhhhhhhhhhhh");

  React.useEffect(() => {
    courseId = window.localStorage.getItem("courseId");
    // console.log(courseId, "---icourseId");

    return () => {
      if (!navigation.state) {
        window.localStorage.setItem("courseId", "");
      }
    };
  }, [window.localStorage.getItem("courseId")]);

  // ------------------------Client API
  const getCourseValue = async () => {
    if (!courseId) {
      // console.log(courseId, "---uu");
      return navigate("/");
    }

    const res = await get(`${urls?.course?.valueCourse}/${courseId}`);
    // console.log(res?.data, "///////////////");
    setSelectCourseData(res?.data?.result?.data);
  };

  React.useEffect(() => {
    getCourseValue();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [window.localStorage.getItem("courseId")]);

  return (
    <>
      <PmpCertificate
        selectCourse={selectCourse}
        menu={selectCourse?.menu?.name}
      />
      <Classes
        selectCourse={selectCourse}
        onlineInstructor={selectCourse?.onlineInstructorLedInfo}
        onlineSelfPaced={selectCourse?.selfPacedInfo}
        classRoomInfo={selectCourse?.classRoomInfo}
        courseTitle={selectCourse?.title}
      />
      <Details selectCourse={selectCourse} />
    </>
  );
};
