import { Box, Typography, styled } from "@mui/material";
import React from "react";
import { PmpCertificateData } from "../helpers/Commun.hellper";

const Para = styled(Typography)(({ theme }) => ({
  // color: "#555",
  lineHeight: "24px",
  margin: "15px 0",
  fontSize: "16px",
  textAlign: "justify",
  fontFamily: "Inter",
}));
const CourseOutline = ({courseOutline}) => {
  return (
    <Box>
      <Para>
        {/* {PmpCertificateData.map((desc, i) => ( */}
          
          <div dangerouslySetInnerHTML={{__html: courseOutline}} />
        {/* ))} */}
      </Para>
    </Box>
  );
};

export default CourseOutline;
