let cardList = [
  {
    id: 1,
    path: "/classroom-training",
    title: "CLASSROOM TRAINING",
    desc: "Lorem ipsum dolor sit amet adipisicing elit.dolor sit elit. elit.dolor sit elit.",
    heading: "Live classes",
    content:
      "Join a scheduled class with a live instructor and other delegates",
  },
  {
    id: 2,
    path: "/onlineclassroom-training",
    title: "ONLINE CLASSROOM TRAINING",
    desc: "Lorem ipsum dolor sit amet adipisicing elit.dolor sit elit. elit.dolor sit elit.",
    heading: "Fully interactive",
    content:
      "Ask questions, share documents, interact with whiteboards, ask live questions and communicate with your trainer and peers.",
  },
  {
    id: 3,
    // path: "/self-learning",
    // path: "/onlineclassroom-training",
    path: "/online-training",
    title: "ONLINE TRAINING",
    desc: "Lorem ipsum dolor sit amet adipisicing elit.dolor sit elit. elit.dolor sit elit.",
    heading: "No travel or accommodation costs",
    content: "An affordable way to boost your career.",
  },
  {
    id: 4,
    path: "/corporate-training",
    title: "CORPORATE TRAINING",
    desc: "Lorem ipsum dolor sit amet adipisicing elit.dolor sit elit. elit.dolor sit elit.",
    heading: "Access the best pool of trainers, wherever you are",
    content:
      "We use the same pool of expert trainers for our Online Instructor-led courses, so no need to leave where you live or work.",
  },
];

export default cardList;
