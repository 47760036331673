import React, { useRef } from "react";
import { useInView } from "react-intersection-observer";
import { useTheme } from "@mui/system";
import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  styled,
} from "@mui/material";
import { keyframes } from "@emotion/react";
import ScrollAnimation from "react-animate-on-scroll";
import useScrollToTopOnNavigation from "../hooks/useScrollToTopNavigation";
import { Link } from "react-router-dom";
import ImgBlueShirt from "../assets/Images/d.png";
import aboutimg from "../assets/Images/aboutus.jpg";
import videoaphia from "../assets/Video/videoaphia.mp4";
import ReactPlayer from "react-player";
import MyVedio from "../assets/Video/MyVedio.mp4";

const slideInFromLeftAnimation = keyframes`
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
`;

const slideInFromTop = keyframes`
  0% {
    transform: translateY(-50%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;
const fadeInAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
const letterByLetterAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const slideInFromRight = keyframes`
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const Heading = styled(Typography)(() => ({
  fontSize: "32px",
  fontWeight: "bold",
  color: "#000",
  marginBottom: "15px",
  fontFamily: "Quicksand",
  marginLeft: "30px",
  // animation: inView
  //   ? `${slideInFromLeftAnimation} 1s ease-out forwards`
  //   : "none",
}));

const Para = styled(Typography)(({ theme }) => ({
  color: "#555",
  lineHeight: "24px",
  margin: "15px 0",
  fontSize: "14px",
  textAlign: "justify",
  animationDelay: "0.5s",
  marginLeft: "30px",
  fontFamily: "Inter",
  fontWeight: 600,
}));

const ContainerBox = styled(Box)(() => ({
  backgroundColor: "whitesmoke",
}));
const HomePage = () => {
  useScrollToTopOnNavigation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  return (
    <Box
      sx={{
        backgroundImage: `url(${aboutimg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundPosition: "center",
        height: 300,
        display: "flex",
        alignItems: "center",
        padding: { xs: "0 40px", sm: "0 40px", md: "0 80px" },
        // width: "100%",
        // padding: { xs: "0 0", sm: "0 0", md: "0 50px" },
      }}
    >
      <Container maxWidth="xl">
        <Box
          ref={ref}
          sx={{
            marginTop: { xs: "20%", md: "10%", lg: "10%" },
            color: "#ce901b",
            animation: `${fadeInAnimation} 1s ease-out forwards`,
            animationDelay: "1s",
          }}
        >
          <Typography
            variant="h2"
            sx={{
              opacity: 0,
              animation: `${fadeInAnimation} 1s ease-out forwards`,
              animationDelay: "1s",
              fontFamily: "Quicksand",
              fontWeight: 700,
              fontSize: { xs: "24px", sm: "36px", md: "52px" },
              TextShadow:
                "3px 3px 0 #000,-3px 3px 0 #000,-3px -3px 0 #000, 3px -3px 0 #000;",
            }}
          >
            Welcome To Aphia
          </Typography>
          <Typography
            variant="h6"
            sx={{
              // whiteSpace: "nowrap",
              overflow: "hidden",
              animation: inView
                ? `${letterByLetterAnimation} 0.5s ease-out forwards`
                : "none",
              animationDelay: "2s",
              animationFillMode: "both",
            }}
          >
            with an aim of enlightening the world through online learning
          </Typography>
        </Box>
        <Box sx={{ marginBottom: { xs: "15%", md: "10%", lg: "10%" } }} />
      </Container>
    </Box>
  );
};

const ClassDetails = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 1,
  });

  const Heading = styled(Typography)(() => ({
    fontSize: "32px",
    fontWeight: "bold",
    color: "#000",
    marginBottom: "15px",
    fontFamily: "Quicksand",
    // animation: inView
    //   ? `${slideInFromLeftAnimation} 1s ease-out forwards`
    //   : "none",
  }));

  const Para = styled(Typography)(({ theme }) => ({
    color: "#555",
    lineHeight: "24px",
    margin: "25px 0",
    fontSize: "15px",
    textAlign: "justify",
    animationDelay: "0.5s",
    fontFamily: "Inter",
  }));

  return (
    <Container maxWidth="xl">
      <Grid
        container
        mt={5}
        sx={{
          animation: inView
            ? `${slideInFromLeftAnimation} 1s ease-out forwards`
            : "none",
          animation: `${fadeInAnimation} 2s ease-out forwards`,
          padding: { xs: "0 0", sm: "0 0", md: "0 50px" },
        }}
      >
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Para
            sx={{
              textIndent: 50,
              "::first-letter": {
                color: "Black",
                fontSize: "25px",
                fontWeight: 600,
              },
            }}
          >
            Welcome to Aphia, where knowledge meets innovation. Our online
            learning app, virtual instructor led interactive training and
            classroom training are designed to revolutionize how you
            learn,acquire and implement new skills. Whether you're a student
            striving for academic success, a professional looking to upskill, or
            someone eager to explore and implement new interests, we're here to
            empower your educational journey.Aphia is a leading training and
            Support Services provider, enabling learners across the globe by
            providing various employable digital skills, SAP, SAP S4HANA, Cloud
            Base SAP Module courses, RPA (Robotics Process Automation), Quality
            Management, Business Management, Agile Management, Project
            Management, Personal Development, Soft Skills Development, Change
            Management, Master Class (Industry / Technology / Domain /
            Customized organization specific), Transition of Freshers into IT,
            Competitive Exams and Other niche skills business value added
            Courses, through innovative practical approaches for fulfilling
            market demands and get employment to youth generation with practical
            skill sets under guidance of real time industry experts
          </Para>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          display="flex"
          justifyContent={{ sm: "center", md: "end" }}
          alignItems="center"
          ref={ref}
          // sx={{
          //   animation: inView
          //     ? `${slideInFromLeftAnimation} 1s ease-out forwards`
          //     : "none",
          // }}
        >
          <img
            style={{
              borderRadius: "50%",
              width: "400px",
              height: "400px",
            }}
            // src={ImgBlueShirt}
            src="https://www.learnfly.com/images/about-1.jpg"
            alt=""
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Para>
            All our trainers are certified subject matter experts with plentiful
            industry knowledge, and real time working consultant on various kind
            of projects in various MNC's, who have great experience in
            implementing and supporting range of multinational SAP and other
            technologies project. We share our knowledge and experience with
            students who have great deal of aspiration to know about industry.
            Our domain experts at Aphia have collaborated and worked with
            corporates like IBM, Accenture, TCS, HP, Infosys, Dell, Microsoft,
            Oracle, Tech Mahindra, E&Y, PWC, Deloitte, KPMG, L&T, Bank of
            America, Standard Chartered Bank, Wipro, Satya Paul, Airtel,
            Ranbaxy, Vodafone, Glenmark, Bank of Baroda, Bombay Dyeing Ltd,
            NIIT, Modi Xerox, HDFC Bank, Viom Networks Ltd (Tata Group
            subsidiary), Corporation Bank, etc. and leading names in the
            Academia like Amity University – Noida , IMT- Ghaziabad, Bennett
            University – Greater Noida, GD Goenka World Institute – in
            collaboration with Lancaster University, UK, etc.
          </Para>
        </Grid>
      </Grid>
    </Container>
  );
};

const OurMission = () => {
  const theme = useTheme();
  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          padding: { xs: "0 0", sm: "0 0", md: "0 50px" },
        }}
      >
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",

            border: "2px solid #F5B901",
            // borderRadius: "120px",
            borderRadius: {
              xs: "30px",
              sm: "50px",
              md: "120px",
            },
            padding: "10px",
          }}
          my={2}
        >
          <Grid
            item
            sx={{
              height: "200px",
              width: "100%",
              maxWidth: "200px",
              display: "flex",
              justifyContent: " center",
              alignItems: "center",
              margin: "auto",
              textAlign: "center",
              padding: { sm: "2px", md: "2px" },
            }}
            xs={12}
            sm={12}
            md={1.7}
          >
            <Box
              sx={{
                height: "200px",
                width: "100%",
                maxWidth: "200px",
                backgroundColor: "#146213",
                borderRadius: "50%",
                display: "flex",
                textAlign: "center",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                ...(theme.breakpoints.up("md") && {
                  height: "170px",
                  width: "170px",
                  maxWidth: "170px",
                }),
              }}
            >
              <Typography
                sx={{
                  fontSize: "40px",
                  fontWeight: "bold",
                  textAlign: "center",
                  color: "white",
                }}
              >
                OUR
              </Typography>
              <Typography
                sx={{
                  fontSize: "25px",
                  fontWeight: "bold",
                  textAlign: "center",
                  color: "white",
                }}
              >
                MISSION
              </Typography>
            </Box>
          </Grid>
          <Grid
            sx={{
              // backgroundColor: "red",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "auto",
              textAlign: "center",
              padding: { md: "10px", sm: "10px", xs: "2px" },
            }}
            item
            sm={12}
            md={10.3}
          >
            <Typography sx={{ color: "black" }} mx={2}>
              At Aphia, our mission is to make learning accessible, engaging,
              and effective for everyone. We believe that education is the key
              to personal and professional growth. With our app, we aim to
              bridge the gap between traditional and digital learning, ensuring
              that you can learn at your own pace, in your own space, and with
              the support you need. We transform consultants, leaders, and
              students by empowering them via business value-added digital
              skills.
            </Typography>
          </Grid>
        </Grid>
      </Box>
      {/* <Grid
        container
        sx={{
          padding: { xs: "0 0", sm: "0 0", md: "0 50px" },
        }}
        my={4}
      >
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Heading sx={{ paddingLeft: "15px" }}>Our Mission</Heading>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "column", md: "row" },
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "5px",
              marginBottom: { xs: 2, sm: 2, md: 0 },
              // background: "rgba(14, 20, 107,0.6)",
              display: "flex",
              padding: "10px",
              border: "2px solid #F5B901",
              borderRadius: "120px",
              borderRadius: { xs: "5px", md: "200px" },
              alignItems: "center",
              // height: { xs: "100%", md: "80%" },
              flexDirection: { xs: "column", md: "row" },
              p: { xs: "20px", md: "0" },
            }}>
            <img height="100%" src={mission} alt="Loading..." />
            <Box
              sx={{
                height: "200px",
                width: "620px",
                backgroundColor: "#009974",
                borderRadius: "100px",
                display: "flex",
                flexDirection: "column",
              }}
            ></Box>
            <Typography sx={{ color: "black" }} mx={2}>
              At Aphia, our mission is to make learning accessible, engaging,
              and effective for everyone. We believe that education is the key
              to personal and professional growth. With our app, we aim to
              bridge the gap between traditional and digital learning, ensuring
              that you can learn at your own pace, in your own space, and with
              the support you need. We transform consultants, leaders, and
              students by empowering via business value-added digital skills.
            </Typography>
          </Box>
        </Grid>
      </Grid> */}
    </Container>
  );
};

const LatestSkills = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const Heading = styled(Typography)(() => ({
    fontSize: " 32px",
    fontWeight: "bold",
    color: "#000",
    marginBottom: "15px",
    fontFamily: "Quicksand",
  }));

  const Subheading = styled(Typography)(() => ({
    fontSize: " 20px",
    margin: " 13px 0",
    color: "black",
    fontWeight: 500,
    fontFamily: "Quicksand",
    fontWeight: 700,
  }));

  const Content = styled(Typography)(() => ({
    color: " #555",
    fontSize: "15px",
  }));

  const data = [
    {
      title: "Comprehensive Courses",
      content:
        " We offer a wide range of courses in the field of technical, functional and domain knowledge. Experts in their respective fields create our content.",
      headding: "Our Experienced Trainers",
      para: " We choose certified subject matter experts with plentiful industry knowledge, and real-time working consultants on various kinds of projects in various MNCs who have great experience in implementing and supporting a range of multinational SAP and other technologies projects, and who know how to make their topics engaging to create a memorable and valuable learning experience.",
    },
    {
      title: "Interactive Learning",
      content:
        "Learning is more than just reading or watching videos. Apart from virtual instructor led interactive training and classroom training, our app features interactive elements such as quizzes, assignments, and discussions will enhance your learning experience.",
      headding: "Certification",
      para: " We will provide training completion and project completion certificates. We also provide guidance for the completion of SAP and other technology certifications and full support for exam preparation with study materials under the guidance of certified and real-time work experience industry expert trainers.",
    },
    {
      title: "Flexibility",
      content:
        " Life can be busy, but that shouldn't hinder your quest for knowledge. Aphia is designed for learners with diverse schedules. You can access our content 24/7, from anywhere.",
      headding: "Job Support",
      para: " Aphia will provide job support for each candidate after successfully completing SAP Training and other technologies training and providing 100 % job support until successfully placed the candidate and providing guidance for CV/resume preparation, interview tips, suggestions for improvement if any candidate, communication skills development, personality development guidance, etc for the successful placement of each candidate.",
    },
    {
      title: "Personalized Learning",
      content:
        " We understand that everyone's learning journey is unique. Our app adapts to your progress, providing recommendations and tailored content to help you achieve your goals.",
      headding: "Consultancy",
      para: "Our strategic consultancy services provide expert advice and training for organizations to upskill their employees and encourage change through learning in the workplace. We can help you identify training needs and create fully customized programs for training, to be delivered by either our training specialists or staff from your own department. ",
    },
    {
      title: "Community & Support",
      content:
        " Connect with fellow learners through our community forums and receive guidance from instructors and mentors. Learning is a collaborative effort at Aphia. Our Community is open to everyone interested in connecting, learning, and sharing new ideas, points of view, and proven concepts.",
      headding: "Reviews",
      para: " We take pride in the positive feedback we receive from our learners. The testimonials, awards, and recognitions we have garnered reflect our commitment to excellence and the value we bring to our customers.",
    },
    {
      title: "Affordable Learning",
      content:
        " Quality education should be accessible to all. We offer competitive pricing and even have free courses to ensure that knowledge is within reach.",
    },
  ];

  return (
    <Container maxWidth="xl">
      {/* <ScrollAnimation
        animateIn="animate__slideInRight"
        duration={3}
        animateOnce={true}
      > */}
      <Grid
        container
        sx={{
          padding: { xs: "20px 0", sm: "20px 0", md: "20px 50px" },
        }}
      >
        <Grid item xs={12} md={6} lg={6}>
          <Grid item xs={12} md={12} lg={12}>
            <Heading variant="h3">Why Choose Aphia?</Heading>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <List>
              {data.map((text, index) => (
                <ListItem
                  alignItems="flex-start"
                  sx={{ paddingLeft: 0, textAlign: "justify" }}
                  key={index}
                >
                  <ListItemText
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{
                            display: "inline",
                            fontWeight: "bold",
                            color: "black",
                          }}
                        >
                          {text.title}:
                        </Typography>
                        {text.content}
                      </React.Fragment>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          display="flex"
          justifyContent="center"
          alignItems="center"
          ref={ref}
        >
          <img
            width="80%"
            src={ImgBlueShirt}
            // src="https://www.learnfly.com/images/choose_bg.png"
            alt="Loading..."
            style={{
              borderRadius: "5px",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <List>
            {data.slice(0, data.length - 1).map((text, index) => (
              <ListItem
                alignItems="flex-start"
                sx={{ paddingLeft: 0, textAlign: "justify" }}
                key={index}
              >
                <ListItemText
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{
                          display: "inline",
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        {text.headding}:
                      </Typography>
                      {text.para}
                    </React.Fragment>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
      {/* </ScrollAnimation> */}
    </Container>
  );
};

const LearningCommunity = () => {
  return (
    <Container maxWidth="xl">
      <Grid
        container
        spacing={2}
        sx={{
          padding: { xs: "20px", sm: "20px", md: "20px 50px" },
        }}
        my={2}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={4}
          mb={3}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <ReactPlayer
            url={MyVedio}
            playing={true}
            controls={true}
            autoPlay={true}
            loop={true}
            width="100%"
            height="100%"
            style={{ borderRadius: "10px", overflow: "hidden" }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={8}>
          <Heading variant="h3">Join Our Learning Community Today</Heading>
          <Para>
            Discover the endless opportunities for growth and learning that
            Aphia has to offer. Start your technical learning with us and unlock
            your full potential. Whether you're pursuing a degree, mastering a
            skill, or satisfying your curiosity, we're here to support you every
            step of the way. Our Mission is to bring the best content to the
            industry and foster networking, learning, and sharing of
            information, knowledge, and skills.
          </Para>
          <Para sx={{ mt: 1 }}>
            To start your new journey of learning,
            <Link to="/signup"> sign up </Link> today and be a part of the Aphia
            family.
          </Para>
        </Grid>
        {/* <Grid item xs={12} sm={12} md={12} lg={8}></Grid> */}
      </Grid>
    </Container>
  );
};

const AboutUs = () => {
  return (
    <>
      <HomePage />
      <ClassDetails />
      <OurMission />
      <LatestSkills />
      <LearningCommunity />
    </>
  );
};

export default AboutUs;
