import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import css from "../styles/pages/login.css";
import { post } from "../apis/apiMethods";
import urls from "../apis/urlContants";
import { toast } from "react-toastify";
import { objHas } from "../utils/utils";
import useScrollToTopOnNavigation from "../hooks/useScrollToTopNavigation";
import { theme } from "../theme";

const ForgotPassword = () => {
  useScrollToTopOnNavigation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [formError, setFormError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [submittedEmail, setSubmittedEmail] = useState(false);
  const [otp, setOtp] = useState("");
  const [header, setHeader] = useState("");
  const [settings, setSettings] = useState(null);

  useEffect(() => {
    // const logoURL = require("./image/logo3.jpg");
    // setSettings({ ...settings, logo: { link: logoURL } });
  }, []);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data, status } = await post(`${urls.auth.resetPassword}`, {
        email: formData.email,
        password: formData.password,
        mobile: formData.mobile,
      });
      if (status > 199 && status < 299) {
        setHeader("login again with new password");
        toast.success("Password reset successful");
      }
      setFormData({});
      navigate("/login");
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="loginContainer">
      <div className="flexContainer">
        {/* <div>
          {settings && (
            <img
              src={settings && objHas(settings, "logo.link", "")}
              alt="logo"
              style={{
                width: "260px",
                marginLeft: "-5px",
                marginTop: "-10px",
              }}
            />
          )}
        </div>
        <div style={{ marginTop: "100px" }}>
          <div className="header">Forgot Password</div>
          <div className="subHeader">
            {!header && `Please enter your email, We will send you otp`}
          </div>
          <div className="formContainer">
            <form onSubmit={handleSubmit}>
              <div>
                <div className="formHolder">
                  <TextField
                    helperText={formError && !formData.email && "Provide field"}
                    error={formError && !formData.email}
                    fullWidth
                    onChange={handleFormChange}
                    value={formData.email || ""}
                    name="email"
                    label="Email"
                    variant="outlined"
                    sx={{
                      backgroundColor: "#F5F5F5",
                    }}
                  />
                </div>
                <div className="formHolder">
                  <TextField
                    helperText={
                      formError && !formData.mobile && "Provide field"
                    }
                    error={formError && !formData.mobile}
                    fullWidth
                    onChange={handleFormChange}
                    value={formData.mobile || ""}
                    name="mobile"
                    label="Mobile"
                    variant="outlined"
                    type="number"
                    sx={{
                      backgroundColor: "#F5F5F5",
                    }}
                  />
                </div>
              </div>
              <div>
                <div className="formHolder">
                  <TextField
                    type={showPassword ? "text" : "password"}
                    helperText={
                      formError && !formData.password && "Provide field"
                    }
                    error={formError && !formData.password}
                    fullWidth
                    onChange={handleFormChange}
                    value={formData.password || ""}
                    name="password"
                    label="New Password"
                    variant="outlined"
                    sx={{
                      backgroundColor: "#F5F5F5",
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
              <div className="textBtnContainer">
                <div className="forgotText">
                  Remember Password?<Link to="/login">Login</Link>
                </div>
                <div className="btn" style={{ width: "100%" }}>
                  <Button variant="contained" type="submit">
                    submit
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div> */}
        <Box
          component={"form"}
          onSubmit={handleSubmit}
          //   boxShadow={4}
          p={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: { xs: 340, sm: 390, md: 420, lg: 420 },
            border: `1px solid ${theme.palette.primary.main}`,
            backgroundColor: "#fff",
            borderRadius: "8px",
          }}>
          <Typography
            variant="h5"
            textAlign="center"
            color={theme.palette.primary.main}>
            Forgot Password
          </Typography>
          <Typography
            variant="body"
            textAlign="center"
            mt={2}
            sx={{
              fontSize: { xs: "13px", sm: "14px", md: "16px" },
            }}>
            Please enter your email, We will send you otp
          </Typography>
          <div className="formHolder">
            <TextField
              helperText={formError && !formData.email && "Provide field"}
              error={formError && !formData.email}
              fullWidth
              onChange={handleFormChange}
              value={formData.email || ""}
              name="email"
              label="Email"
              variant="outlined"
              sx={{
                backgroundColor: "#F5F5F5",
              }}
            />
          </div>
          <div className="formHolder">
            <TextField
              helperText={formError && !formData.mobile && "Provide field"}
              error={formError && !formData.mobile}
              fullWidth
              onChange={handleFormChange}
              value={formData.mobile || ""}
              name="mobile"
              label="Mobile"
              variant="outlined"
              type="number"
              sx={{
                backgroundColor: "#F5F5F5",
              }}
            />
          </div>
          <div className="formHolder">
            <TextField
              type={showPassword ? "text" : "password"}
              helperText={formError && !formData.password && "Provide field"}
              error={formError && !formData.password}
              fullWidth
              onChange={handleFormChange}
              value={formData.password || ""}
              name="password"
              label="New Password"
              variant="outlined"
              sx={{
                backgroundColor: "#F5F5F5",
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <Typography variant="body" color="gray">
            Remember Password?{" "}
            <Link
              to="/login"
              style={{
                color: theme.palette.secondary.main,
              }}>
              Login
            </Link>
          </Typography>
          <Button
            disableElevation
            type="submit"
            variant="contained"
            size="small"
            sx={{
              width: "40%",
              marginBottom: "15px",
              marginTop: "20px",
              "&.MuiButtonBase-root": { borderRadius: "5px" },
            }}>
            submit
          </Button>
        </Box>
      </div>
    </div>
  );
};

export default ForgotPassword;
