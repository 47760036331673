import Axios from "axios";

import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_API_ENDPOINT;

const apiClient = Axios.create({
  baseURL: BASE_URL,
  headers: { "Access-Control-Allow-Origin": "*" },
});

apiClient.interceptors.request.use(
  function (config) {
    const token = window.localStorage.getItem("token");

    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },

  function (error) {
    toast.error(error.response.data.message);
    return Promise.reject(error);
  }
);

//

// operate on response before handling the response

apiClient.interceptors.response.use(
  function (response) {
    if (
      (response.status === 200 &&
        ["POST", "PUT", "DELETE"].includes(
          response.config.method.toUpperCase()
        )) ||
      (response.status === 201 &&
        ["POST", "PUT", "DELETE"].includes(
          response.config.method.toUpperCase()
        ))
    ) {
      toast.success(response.data.message.split("_").join(" "));

      return response;
    } else {
      return response;
    }
  },

  function (error) {
    // console.log(error,"reserrorr")
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    toast.warn(error?.response?.data?.message);
  }
);

export default apiClient;
