import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  keyframes,
  styled,
} from "@mui/material";
import React from "react";
import aphia from "../assets/Images/aphia.png";
import students from "../assets/Images/students.png";
import blueGirl from "../assets/Images/blueGirl.png";
import classroomtraining from "../assets/Images/mainhome.jpg";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import useScrollToTopOnNavigation from "../hooks/useScrollToTopNavigation";
import * as Yup from "yup";
import { useFormik } from "formik";
import { post } from "../apis/apiMethods";
import urls from "../apis/urlContants";
import FormInput from "../form/FormInput";
import { Link } from "react-router-dom";
import { theme } from "../theme";
import ContactDropusForm from "./Contact.DropusForm";

const fadeInAnimation = keyframes`
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    `;

const style = {
  // color: "#555",
  lineHeight: "24px",
  fontFamily: "inter",
  fontSize: "15px",
};

const Querys = styled(Box)(({ theme }) => ({
  width: "80%",
  animation: `${fadeInAnimation} 2s ease-out forwards`,
  marginTop: "40px",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const Text = styled(Typography)(({ theme }) => ({
  color: "black",
  lineHeight: "24px",
  display: "flex",
  fontFamily: "Inter",
  fontSize: 30,
  fontWeight: 700,
  justifyContent: "center",
  alignItems: "center",
  letterSpacing: "2px",
  marginBottom: "20px",
}));

const Form = styled(Box)(() => ({
  padding: 20,
  borderRadius: "5px",
  marginTop: 20,
  background: "white",
  border: `5px solid ${theme.palette.secondary.main}`,
}));

const ClassRoomTraining = () => {
  useScrollToTopOnNavigation();

  return (
    <>
      <Box
        sx={{
          backgroundImage: `radial-gradient(at bottom left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.0)), url(${classroomtraining})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "100%",
          padding: { xs: "100px 0", sm: "100px 0", md: "170px 50px" },
          // margin: "5px 5px",
        }}
      >
        <Container maxWidth="xl">
          <Typography
            variant="h6"
            sx={{
              opacity: 0,
              animation: `${fadeInAnimation} 1s ease-out forwards`,
              animationDelay: "1s",
              fontFamily: "Quicksand",
              fontSize: { xs: "24px", sm: "36px", md: "52px" },
              // color: "#ce901b",
              fontWeight: 700,
              WebkitTextFillColor: "#fff",
              color: "#fff",
              TextShadow:
                "3px 3px 0 #000,-3px 3px 0 #000,-3px -3px 0 #000, 3px -3px 0 #000;",
            }}
          >
            ClassRoom Training <br />
          </Typography>
        </Container>
      </Box>
      <Box bgcolor="whitesmoke">
        <Container maxWidth="xl">
          <Grid
            container
            spacing={2}
            py={6}
            sx={{
              padding: { xs: "20px 0", sm: "20px 0", md: "50px 50px" },
            }}
          >
            {/* <Grid item xs={12} md={6} lg={6} p={2}>
              <Box>
                <img
                  style={{
                    width: "80%",
                    height: "340px",
                  }}
                  src={image12}
                  alt=""
                />
              </Box>
            </Grid> */}
            {/* <Grid item xs={12} md={6} lg={6}>
              <Typography
                sx={{
                  fontWeight: "bold",
                }}
              >
                We share knowledge with the world:
              </Typography>
              <Typography sx={style}>
                Education is root for life. In today’s challenging business
                world, take a chance to stand out with exceptional skills,
                knowledge in a global market by pursuing ‘Online Programme and
                Certification Courses’ from Aphia's learning App, designed
                especially for individuals seeking to enhance their strategic,
                personal and professional intelligence. The curriculum is
                created by Industry experts and SMEs, and it is taught by
                competent faculty / facilitator, ensuring that students receive
                the highest quality education.
              </Typography>
            </Grid> */}

            <Grid item xs={12} sm={12} md={12}>
              <Typography sx={style}>
                Aphia Classroom training has been the foundation of client
                education. Many participants learn best and have the greatest
                opportunity for retention when they learn from a live instructor
                in a classroom setting. Classroom training affords the
                opportunity to incorporate various learning principles,
                including lectures, demonstrations, hands-on workshops, and
                retention quizzes. Aphia gives you the best experience on our
                Classroom training, depending on the training subject, learners
                may thrive best in an environment where collaboration is easy
                and peer insight is bountiful. Getting away from the office can
                often be beneficial for employees: They can focus on learning
                without work distractions and also build collaboration and
                rapport with other users that can result in valuable knowledge
                sharing relationships in the future. In addition, participants
                can work with industry colleagues to solve problems, gain &
                share knowledge and even learn tips and tricks.
              </Typography>
            </Grid>

            {/* 
            <Grid item xs={12} sm={12} md={12} p={2}>
              <Box>
                <img
                  style={{
                    width: "100%",
                    height: "420px",
                  }}
                  src={class room training}
                  alt=""
                />
              </Box>
            </Grid> */}
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Typography
                sx={{ fontWeight: 600, fontSize: 25, fontFamily: "Quicksand" }}
              >
                Advantages of our Classroom training{" "}
              </Typography>

              <Typography sx={style} mt={1}>
                <FiberManualRecordIcon
                  style={{
                    width: "10px",
                    height: "10px",
                    color: "black",
                  }}
                ></FiberManualRecordIcon>{" "}
                Industry experts and SMEs are hands-on to give you on spot
                practical help and answer real-life questions
              </Typography>
              <Typography sx={style} mt={1}>
                <FiberManualRecordIcon
                  style={{
                    width: "10px",
                    height: "10px",
                    color: "black",
                  }}
                ></FiberManualRecordIcon>{" "}
                Opportunities to tackle subjects and questions outside our
                knowledge domain
              </Typography>
              <Typography sx={style} mt={1}>
                <FiberManualRecordIcon
                  style={{
                    width: "10px",
                    height: "10px",
                    color: "black",
                  }}
                ></FiberManualRecordIcon>{" "}
                Immediate help and support with correcting mistakes and getting
                it right
              </Typography>
              <Typography sx={style} mt={1}>
                <FiberManualRecordIcon
                  style={{
                    width: "10px",
                    height: "10px",
                    color: "black",
                  }}
                ></FiberManualRecordIcon>{" "}
                Networking with other learners, sharing experiences and social
                collaboration
              </Typography>
              <Typography sx={style} mt={1}>
                <FiberManualRecordIcon
                  style={{
                    width: "10px",
                    height: "10px",
                    color: "black",
                  }}
                ></FiberManualRecordIcon>{" "}
                Real, practical and hands on use of the best practices, tools,
                accelerators, process, methodologies and techniques
              </Typography>
              <Typography sx={style} mt={1}>
                <FiberManualRecordIcon
                  style={{
                    width: "10px",
                    height: "10px",
                    color: "black",
                  }}
                ></FiberManualRecordIcon>{" "}
                Expert facilitators who make the learning experience
                interacting, engaging, interesting, value added and enjoyable
              </Typography>
              <Typography sx={style} mt={1}>
                <FiberManualRecordIcon
                  style={{
                    width: "10px",
                    height: "10px",
                    color: "black",
                  }}
                ></FiberManualRecordIcon>{" "}
                Quickly Inspect and Adapt based on your lessons learnt
              </Typography>
              <Typography sx={style} mt={1}>
                <FiberManualRecordIcon
                  style={{
                    width: "10px",
                    height: "10px",
                    color: "black",
                  }}
                ></FiberManualRecordIcon>{" "}
                Dedicated time out of the office hours to really focus on your
                needs
              </Typography>
              <Typography sx={style} mt={1}>
                <FiberManualRecordIcon
                  style={{
                    width: "10px",
                    height: "10px",
                    color: "black",
                  }}
                ></FiberManualRecordIcon>{" "}
                Multi-sensory experience that appeals to more of your multiple
                intelligences
              </Typography>
              <Typography sx={style} mt={1}>
                <FiberManualRecordIcon
                  style={{
                    width: "10px",
                    height: "10px",
                    color: "black",
                  }}
                ></FiberManualRecordIcon>{" "}
                Valuable feedback from the trainer and others in the group.
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Box>
                <img
                  style={{
                    width: "100%",
                  }}
                  src={students}
                  alt="Loading..."
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontFamily: "Quicksand",
                  fontSize: 25,
                }}
              >
                Join Our Learning Community Today
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Typography sx={style}>
                Discover the endless opportunities for growth and learning that
                Aphia has to offer. Start your technical learning with us and
                unlock your full potential. Whether you're pursuing a degree,
                mastering a skill, or satisfying your curiosity, we're here to
                support you every step of the way. Our Mission is to bring the
                best content to the industry and foster networking, learning and
                sharing of information, knowledge, and skills.
                <Typography
                  variant="subtitle1"
                  mt={1}
                  mb={2}
                  fontFamily="inter"
                >
                  Email us:
                  <a
                    href="mailto:support@aphialearning.com"
                    style={{ textDecoration: "none" }}
                  >
                    support@aphialearning.com
                  </a>{" "}
                  to discuss more about your training needs.
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} display="flex">
              {/* ============================DROPUS CONTACT COMPONENTS==================== */}
              <ContactDropusForm subject="More details about classroom training" />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Box>
                <img
                  style={{
                    width: "95%",
                  }}
                  src={blueGirl}
                  alt="Loading..."
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Typography>
                To start your new journey of learning,
                <Link to="/signup"> sign up </Link> today and be a part of the
                Aphia family.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};
export default ClassRoomTraining;
