import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormInput from "../../form/FormInput";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  ListItem,
  ListItemIcon,
  ListItemText,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import {
  PhonelinkLockOutlined,
  ArrowForwardIosRounded,
} from "@mui/icons-material";
import { Stack } from "react-bootstrap";
import { get, post } from "../../apis/apiMethods";
import urls from "../../apis/urlContants";
import { CourseContext, EnqueryBookdata } from "../../context/CourseContext";
import FormSelect from "../../form/FormSelect";
import { useState } from "react";
import { getStates, getCountry } from "country-state-picker";
// import countriecode from 'countries-code';
import { useEffect } from "react";
import PayPalButton from "../PayPalButton";
import { BASE_URL } from "../../apis/apiClient";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import { theme } from "../../theme";
import { toast } from "react-toastify";
import useScrollToTopOnNavigation from "../../hooks/useScrollToTopNavigation";
import moment from "moment";
import ProgramNames from "../../data/programmName";

const Form = styled(Box)(() => ({
  padding: "20px 40px",
  borderRadius: "5px",
  // marginTop: 20,
  background: "white",
  width: "100%",
  margin: "0 auto",
  fontFamily: "Inter",
  boxShadow:
    "box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
}));

const Phone = styled(PhoneInput)(() => ({
  ".PhoneInputInput": {
    padding: "11px 11px",
    borderRadius: 20,
    border: "1px solid #e0e0e0",
    marginght: "10px",
    marginBottom: "25px",
    width: "70px",
  },
  ".PhoneInputCountry": {
    marginBottom: "20px",
  },
  ".PhoneInputInput:focus-visible": {
    outline: `1px solid ${theme.palette.primary.main}`,
  },
}));

const Lable = styled(Stack)(() => ({
  color: "#000",
  fontFamily: "Inter",
  marginTop: "10px",
  fontSize: "14px",
  fontWeight: 700,
}));

const ProgramName = styled(Stack)(() => ({
  background: theme.palette.secondary.main,
  fontFamily: "Inter",
  marginTop: "10px",
  fontSize: "18px",
  padding: "10px",
  borderRadius: "5px",
  fontWeight: 700,
}));

const TextDetails = styled(Stack)(() => ({
  fontFamily: "Inter",
  fontSize: "16px",
  fontWeight: 600,
  marginTop: "10px",
  color: `${theme.palette.grey[700]}`,
}));

const styles = {
  text: {
    fontWeight: "bold",
    fontSize: "14px",
    color: "black",
    paddingLeft: "10px",
  },
};

const programmes = {
  "Online Instructor-Led": "intructorLedSchedule",
  Classroom: "classRoomSchedule",
};

export const ContactBooknow = () => {
  useScrollToTopOnNavigation();
  const navigate = useNavigate();
  const [rendered, setRendered] = useState(false);
  const [paywith, setPaywith] = useState("paypal");
  const [value, setValue] = React.useState();
  const { enqueryBookdata } = React.useContext(EnqueryBookdata);
  const [allCountries, setAllCountries] = React.useState(
    require("countries-cities").getCountries()
  );
  const [countriesQuery, setCountriesQuery] = React.useState(
    require("countries-code")
  );

  const [countryWithCode, setCoutryWithCode] = React.useState(
    countriesQuery.allCountriesList()
  );
  const [states, setStates] = React.useState([]);

  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [orderID, setOrderID] = useState("");
  const [coupanType, setCoupanType] = useState("");
  const [coupanValue, setCoupanValue] = useState("");
  const [coupan, setCoupan] = useState([]);
  const [couponCodeEntered, setCouponCodeEntered] = useState(true);
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [gst, setGst] = useState("");
  const [subTotal, setSubTotal] = useState("");
  const [totalCost, setTotalCost] = useState("");

  const [paypalPaymentId, setPaypalPaymentId] = useState("");

  useEffect(() => {
    setRendered(true);
  }, []);

  // coupon
  const handleLogin = () => {
    setLoading(true);
    navigate("/login");
  };
  const HandleCheckCoupan = async () => {
    if (!code) {
      setCouponCodeEntered(false);
      return;
    }
    setCouponCodeEntered(true);
    try {
      const res = await get(`${urls.coupan.checkCoupan}?code=${code}`);
      // console.log(res, "rrrrrrrrrrr");
      if (res) {
        setCoupanType(res.data.result.coupanType);
        setCoupanValue(res.data.result.value);
      }
    } catch (error) {
      console.log(error);
      setCode("");
      setCoupanType("");
      setCoupanValue("");
    }
  };

  const HandleRemoveCoupan = () => {
    setCode("");
    setCoupanType("");
    setCoupanValue("");
  };

  const currentDate = new Date();
  const earlyBirdEndDate = new Date(
    enqueryBookdata && enqueryBookdata.selfPacedInfo
      ? enqueryBookdata.selfPacedInfo.earlyBirdEndDate
      : ""
  );

  let priceToDisplay;

  if (earlyBirdEndDate > currentDate) {
    priceToDisplay =
      enqueryBookdata && enqueryBookdata.selfPacedInfo
        ? enqueryBookdata.selfPacedInfo.earlyBirdPrice
        : "";
  } else {
    priceToDisplay =
      enqueryBookdata && enqueryBookdata.selfPacedInfo
        ? enqueryBookdata.selfPacedInfo.standardPrice
        : "";
  }

  useEffect(() => {
    if (coupanType == "Percentage") {
      const discount = priceToDisplay * (coupanValue / 100);
      const subTotal = priceToDisplay - discount;
      setSubTotal(subTotal);
      const gst = subTotal * (18 / 100);
      setGst(gst);
      const totalCost = subTotal + gst;
      // console.log(totalCost, "----totalCost");
      setTotalCost(totalCost);
      // console.log(priceToDisplay, "priceToDisplay");
    } else {
      const subTotal = priceToDisplay - coupanValue;
      const gst = subTotal * (18 / 100);
      const totalCost = subTotal + gst;

      if (subTotal < 0) {
        setCode("");
        setCoupanType("");
        setCoupanValue("");
        toast.error("This Coupan is cannot be applied");
      } else {
        setSubTotal(subTotal);
        setGst(gst);
        setTotalCost(totalCost);
      }
    }
  }, [HandleCheckCoupan]);

  const courseId = window.localStorage.getItem("courseId");

  const onsiteFormik = useFormik({
    initialValues: {
      name: "",
      email: "",
      contactNumber: "",
      address: "",
      country: "",
      state: "",
      pinCode: "",
      gstIn: "",
      paymentMethod: "razorpay",
      check: false,
    },
    validationSchema: Yup.object({
      name: Yup.string().required().label("Name").max(150),
      email: Yup.string().email().required().label("Email").max(150),
      contactNumber: Yup.number()
        .nullable()
        .required()
        .label("Contact Number")
        .min(1000000000, "Invalid contact number")
        .max(999999999999, "Invalid contact number"),
      country: Yup.string().required().label("Country"),
      state: Yup.string().required().label("State"),
      address: Yup.string().required().label("Address"),
      pinCode: Yup.string().min(6).max(6).required().label("Pin code"),
      paymentMethod: Yup.string().required().label("Payment Method"),
      check: Yup.boolean()
        .required()
        .default(true)
        .label("Buying for someone else"),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      // if (!enqueryBookdata?.programmeName) {
      //   setLoading(false);
      //   return toast.error("Please contact Admin!");
      // }

      try {
        const body = {
          paymentMethod: values.paymentMethod,
          itemDetails: {
            courseId: courseId,
            programmeName: enqueryBookdata?.programmeName,

            // programmeDetails: {
            // scheduleId: enqueryBookdata?.getEnquery?._id },
          },

          customerDetails: {
            name: values.name,
            email: values.email,
            contactNumber: values.contactNumber,
            country: values.country,
            state: values.state,
            pinCode: values.pinCode,
            gstIn: values.gstIn,
            address: values.address,
            currency: "INR",
            forSomeoneElse: values.check,
          },
        };
        if (
          body.itemDetails.programmeName ===
          ProgramNames["Online Instructor-Led"]
        ) {
          body.itemDetails["programmeDetails"] = {
            intructorLedSchedule: enqueryBookdata?.getEnquery?._id,
          };
        } else if (
          body.itemDetails.programmeName === ProgramNames["Classroom"]
        ) {
          body.itemDetails["programmeDetails"] = {
            classRoomSchedule: enqueryBookdata?.getEnquery?._id,
          };
        }
        const res = await post(`${urls.payment.order}`, body);

        setPaypalPaymentId(res.data.result.id);

        if (body.paymentMethod === "paypal") {
          var token = "";
          var links = res.data.result.links;
          for (var i = 0; i < links.length; i++) {
            if (links[i].rel === "approval_url") {
              token = links[i].href.split("EC-", 2)[1];
            }
          }
          setOrderID(token);
          setLoading(false);
          return setShow(true);
        } else {
          let order = res.data.result;
          var options = {
            key: "rzp_live_objF2ussihQKhD",
            amount: order.amount,
            currency: "INR",
            name: "Aphiya Learning PVT LTD",
            description: "Course purchase",
            order_id: order.id,
            prefill: {
              name: values.name,
              email: values.email,
              contact: values.contactNumber,
            },
            notes: {
              address: "Kolkata",
            },
            theme: {
              color: "#3399cc",
            },
            handler: async function (response) {
              window.localStorage.setItem("courseId", null);
              navigate("/payment-success");
            },
          };

          const rzp1 = new window.Razorpay(options);
          rzp1.open();
          rzp1.on("payment.failed", function (response) {
            navigate("/payment-failed");
          });
        }
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    },

    validateOnBlur: false,
  });

  const createOrder = async (data, actions) => {
    return orderID;
  };

  // check Approval
  const onApprove = (data, actions) => {
    // console.log(data, actions, "data=======================================");
    return actions.order.capture().then(async function (details) {
      const { payer } = details;
      try {
        const { data } = await post(urls.payment.confirm, {
          ...details,
          orderId: paypalPaymentId,
        });
        console.log(data, "data====================");
        navigate("/payment-success");
      } catch (error) {
        console.log(error);
      }
    });
  };

  //capture likely error
  const onError = (data, actions) => {
    navigate("/payment-failed");
    setErrorMessage("An Error occured with your payment ");
  };

  React.useEffect(() => {
    if (!onsiteFormik.values.country) {
      return;
    }
    let countryCode = countryWithCode.filter(
      (countryCode) =>
        countryCode.country_name_en === onsiteFormik.values.country
    )[0].alpha2;
    setStates(getStates(`${countryCode.toLowerCase()}`));
  }, [onsiteFormik.values.country]);

  // -----------------Gst count
  const checkDate = new Date(enqueryBookdata?.earlyBirdEndDate);
  let Amount;
  let PriceTotal;
  let priceSubTotal;
  if (checkDate > currentDate) {
    Amount = (enqueryBookdata?.earlyBirdPrice * 18) / 100;
    PriceTotal = enqueryBookdata?.earlyBirdPrice + Amount;
    priceSubTotal = enqueryBookdata?.earlyBirdPrice;
  } else {
    Amount = (enqueryBookdata?.standardPrice * 18) / 100;
    PriceTotal = enqueryBookdata?.standardPrice + Amount;
    priceSubTotal = enqueryBookdata?.standardPrice;
  }

  return (
    <>
      <PayPalScriptProvider
        options={{
          components: "buttons",
          clientId:
            "AWfvR-kjiNCO5j31kFhA3RlVFcA9-NF5N0O5jXZVbNbY0erK3jmfp2qvvRb5CsrRP97e-ni_BLP2uaPs",
        }}
      >
        <Grid container spacing={2} p={1}>
          <Grid item xs={12} md={12} lg={8}>
            <Form component={"form"}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography
                    variant="h6"
                    mb={1}
                    style={{ color: theme.palette.primary.dark }}
                  >
                    Enter Your Billing Details
                  </Typography>
                  <Divider
                    sx={{
                      marginBottom: "10px",
                      color: theme.palette.secondary.dark,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FormLabel sx={styles.text}>Full Name*</FormLabel>
                  <FormInput formik={onsiteFormik} name="name" type="text" />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FormLabel sx={styles.text}>Email*</FormLabel>
                  <FormInput formik={onsiteFormik} name="email" type="email" />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FormLabel sx={styles.text}>Country*</FormLabel>
                  <FormSelect
                    formik={onsiteFormik}
                    options={allCountries?.map((c) => ({
                      label: c,
                      value: c,
                    }))}
                    name="country"
                    type="text"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FormLabel sx={styles.text}>State*</FormLabel>
                  <FormSelect
                    formik={onsiteFormik}
                    options={states?.map((c) => ({ label: c, value: c }))}
                    name="state"
                    type="text"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FormLabel sx={styles.text}>Contact No*</FormLabel>
                  <Box
                    gap={2}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Phone
                      formik={onsiteFormik}
                      name="contactNumber"
                      international
                      countryCallingCodeEditable={false}
                      defaultCountry="IN"
                      value={value}
                      onChange={setValue}
                    />
                    <FormInput
                      formik={onsiteFormik}
                      name="contactNumber"
                      type="number"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={2}>
                  <FormLabel sx={styles.text}>Pincode*</FormLabel>
                  <FormInput formik={onsiteFormik} name="pinCode" />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <FormLabel sx={styles.text}>GST No.</FormLabel>
                  <FormInput formik={onsiteFormik} name="gstIn" />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <FormLabel sx={styles.text}>Address*</FormLabel>
                  <FormInput
                    formik={onsiteFormik}
                    name="address"
                    type="text"
                    multiline={true}
                    rows={4}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <ListItem sx={{ padding: 0 }}>
                    <ListItemIcon>
                      <GroupAddOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText
                      id="switch-list-label-wifi"
                      primary="I am buying this course for someone else"
                    />
                    <Checkbox
                      edge="end"
                      name="check"
                      inputProps="aria-labelledby"
                      checked={onsiteFormik.values.check}
                      onChange={(e) =>
                        onsiteFormik.setFieldValue("check", e.target.checked)
                      }
                      sx={{
                        color: theme.palette.primary.main,
                        "&.Mui-checked": {
                          color: theme.palette.primary.main,
                        },
                      }}
                    />
                  </ListItem>
                  {onsiteFormik.values.check && (
                    <Box sx={{ m: 1, p: 1 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6}>
                          <InputLabel htmlFor="otherEmail" sx={styles.text}>
                            Email
                          </InputLabel>

                          <FormInput
                            size="small"
                            name="otherEmail"
                            placeholder="Enter Email"
                            formik={onsiteFormik}
                            required
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <InputLabel
                            htmlFor="otherContactNumber"
                            sx={styles.text}
                          >
                            Contact Number
                          </InputLabel>

                          <FormInput
                            size="small"
                            placeholder="Enter Mobile No."
                            name="otherContactNumber"
                            formik={onsiteFormik}
                            required
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <ListItem sx={{ padding: 0 }}>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="paymentMethod"
                      value={onsiteFormik.values.paymentMethod}
                      onChange={onsiteFormik.handleChange}
                    >
                      <FormControlLabel
                        // disabled
                        value="paypal"
                        control={<Radio />}
                        label="Paypal"
                        id="paypal"
                        htmlFor="paypal"
                        checked={onsiteFormik.values.paymentMethod === "paypal"}
                      />
                      <FormControlLabel
                        value="razorpay"
                        control={
                          <Radio
                            style={{ color: theme.palette.primary.main }}
                          />
                        }
                        label="Razorpay"
                        id="razorpay"
                        htmlFor="razorpay"
                        checked={
                          onsiteFormik.values.paymentMethod === "razorpay"
                        }
                      />
                    </RadioGroup>
                  </ListItem>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {show && onsiteFormik.values.paymentMethod === "paypal" ? (
                    <PayPalButtons
                      style={{ layout: "vertical" }}
                      createOrder={createOrder}
                      onApprove={onApprove}
                      onError={onError}
                    />
                  ) : null}
                </Grid>
                {/* <Grid item xs={12} sm={12} md={12} lg={12} mt={3}>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    endIcon={<ArrowForwardIosRounded />}
                    sx={{
                      width: "100%",
                    }}
                    loading={loading}
                    onClick={handleLogin}
                  >
                    Proceed to pay
                  </LoadingButton>
                </Grid> */}
              </Grid>
            </Form>
          </Grid>
          {/* ========================= */}

          <Grid item xs={12} sm={12} md={12} lg={4}>
            <Box
              component={"form"}
              onSubmit={onsiteFormik.handleSubmit}
              sx={{
                border: "1px solid gray",
                borderRadius: "20px",

                mt: { xs: 0, sm: 0, md: 12, lg: 12 },
                zIndex: 9,
              }}
              p={4}
            >
              <Typography
                variant="body1"
                mb={2}
                component="div"
                fontWeight={500}
                fontSize="14px"
              >
                Date: {moment(currentDate).format("ddd, Do MMM YYYY")}
              </Typography>
              <Typography variant="h6" mb={1} fontWeight={600}>
                Your Total Basket is{" "}
                <Typography
                  variant="span"
                  fontWeight={600}
                  fontSize="25px"
                  color={theme.palette.primary.dark}
                  mb={2}
                >
                  ₹{PriceTotal}
                </Typography>
              </Typography>

              <Lable>
                <Typography variant="body" mb={2}>
                  Subtotal
                </Typography>{" "}
                <Typography variant="body" mb={2}>
                  {" "}
                  ₹ {priceSubTotal}
                </Typography>
              </Lable>

              {/* <Box sx={{ marginTop: "15px" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    id="outlined-basic"
                    name="code"
                    value={code}
                    label="discount code"
                    required
                    variant="outlined"
                    size="small"
                    onChange={(e) => setCode(e.target.value)}
                    onChange={(e) => {
                      setCouponCodeEntered(true);
                      setCode(e.target.value);
                    }}
                  />

                  <Tooltip title="Apply Coupan">
                    <Button
                      onClick={HandleCheckCoupan}
                      variant="contained"
                      size="small"
                      sx={{
                        marginLeft: "45px",
                      }}
                    >
                      APPLY
                    </Button>
                  </Tooltip>
                </div>
                {coupanType && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        marginTop: "10px",
                        padding: "15px 0",
                        color: "#fff",
                        width: "400px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: `${theme.palette.primary.dark}`,
                          fontSize: { xs: "12px", sm: "14px", md: "16px" },
                        }}
                      >
                        {coupanType && coupanValue
                          ? coupanType == "Percentage"
                            ? `${coupanValue}% OFF`
                            : `₹${coupanValue} FLAT`
                          : ""}{" "}
                        COUPON APPLYED SUCCESSFULLY!
                      </Typography>
                    </div>
                    <Tooltip title="Remove Coupan">
                      <IconButton
                        color="error"
                        aria-label="delete"
                        size="medium"
                        sx={{
                          marginLeft: "15px",
                          "&:hover": {
                            backgroundColor: "transparent",
                            opacity: 0.5,
                          },
                        }}
                        onClick={HandleRemoveCoupan}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}
              </Box> */}
              {/* {!couponCodeEntered && (
                <Typography
                  sx={{ color: "red", marginTop: "5px", fontSize: "12px" }}
                >
                  discount code is required.
                </Typography>
              )} */}
              {enqueryBookdata && (
                <Box
                  sx={{
                    border: `1px solid ${theme.palette.secondary.main}`,
                    padding: "20px",
                    marginTop: "10px",
                    borderRadius: "20px",
                  }}
                >
                  <ProgramName>
                    Programme Name:{" "}
                    {enqueryBookdata?.programmeName
                      ? enqueryBookdata?.programmeName
                      : ProgramNames["Online Self-Paced"]}
                  </ProgramName>
                  <TextDetails>
                    Product Name:{" "}
                    <Typography variant="span" fontSize="18px" color="black">
                      {enqueryBookdata?.courseTitle}
                    </Typography>
                  </TextDetails>
                  {enqueryBookdata?.getEnquery && (
                    <>
                      <TextDetails>Product Details: </TextDetails>
                      <Lable>
                        Type_____________{" "}
                        {enqueryBookdata?.getEnquery?.weekType}
                      </Lable>

                      <Lable>
                        Total day________________{" "}
                        {enqueryBookdata?.getEnquery?.totalDays} Days
                      </Lable>
                      <Lable>
                        Date______
                        {moment(enqueryBookdata?.getEnquery?.endDate).format(
                          "ddd, Do MMM YYYY"
                        )}{" "}
                        -
                        {moment(enqueryBookdata?.getEnquery?.startDate).format(
                          "ddd, Do MMM YYYY"
                        )}
                      </Lable>
                      <Lable>
                        Time__________ {enqueryBookdata?.getEnquery?.startTime}{" "}
                        - {enqueryBookdata?.getEnquery?.endTime}{" "}
                      </Lable>
                    </>
                  )}
                </Box>
              )}

              <Box
                sx={{
                  background: theme.palette.secondary.main,
                  padding: "20px",
                  marginTop: "20px",
                  borderRadius: "20px",
                }}
              >
                <Lable
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="span" mb={2}>
                    Subtotal______________
                  </Typography>{" "}
                  <Typography variant="span" mb={2}>
                    ₹ {priceSubTotal}
                  </Typography>
                </Lable>
                <Lable
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="span" mb={2}>
                    GST______________
                  </Typography>{" "}
                  <Typography variant="span" mb={2}>
                    {typeof Amount === "number" && !isNaN(Amount)
                      ? Amount.toFixed(2)
                      : "Invalid GST"}
                    (18%)
                  </Typography>
                </Lable>
                <Lable
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="span" mb={2}>
                    Total______________
                  </Typography>{" "}
                  <Typography variant="span" mb={2}>
                    ₹ {PriceTotal}
                  </Typography>
                </Lable>
              </Box>
              <Grid item xs={12} sm={12} md={12} lg={12} mt={3}>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  endIcon={<ArrowForwardIosRounded />}
                  sx={{
                    width: "100%",
                  }}
                  loading={loading}
                >
                  Proceed to pay
                </LoadingButton>
              </Grid>
            </Box>
          </Grid>

          {/* =============================== */}
          {/* <Grid
            item
            xs={12}
            md={4}
            lg={4}
            p={2}
            sx={{
              order: { xs: 1, sm: 1, md: 2 },
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              component={"form"}
              onSubmit={onsiteFormik.handleSubmit}
              sx={{ border: "1px solid gray" }}
              p={4}
            >
              <Typography variant="h6" mb={2}>
                Your Total Basket is ₹ {enqueryBookdata?.earlyBirdPrice}
              </Typography>

              <Typography variant="span" mb={2} color="gray" component="div">
                {enqueryBookdata?.courseTitle}
              </Typography>
              <Lable>
                <Typography variant="span" mb={2}>
                  Delivery Method
                </Typography>{" "}
                __________
                <Typography variant="span" mb={2}>
                  {enqueryBookdata?.programmeName
                    ? enqueryBookdata?.programmeName
                    : "Online Self-Pased"}
                </Typography>
              </Lable>
              <Lable>
                <Typography variant="span" mb={2}>
                  Subtotal
                </Typography>{" "}
                __________________
                <Typography variant="span" mb={2}>
                  ₹ {enqueryBookdata?.earlyBirdPrice}
                </Typography>
              </Lable>
              <Box sx={{ marginTop: "20px" }}>
                <FormLabel>Discount code</FormLabel>
                <FormInput
                  formik={onsiteFormik}
                  name="discount"
                  type="text"
                  placeholder="Enter your discount code"
                  InputProps={<GroupAddOutlinedIcon />}
                />
              </Box>
              <Box sx={{ background: "#CACACA", padding: "20px" }}>
                <Lable>
                  <Typography variant="span" mb={2}>
                    Subtotal
                  </Typography>{" "}
                  _______________
                  <Typography variant="span" mb={2}>
                    ₹ {enqueryBookdata?.earlyBirdPrice}
                  </Typography>
                </Lable>
                <Lable>
                  <Typography variant="span" mb={2}>
                    GST
                  </Typography>{" "}
                  ______________
                  <Typography variant="span" mb={2}>
                    18%
                  </Typography>
                </Lable>
                <Lable>
                  <Typography variant="span" mb={2}>
                    Total
                  </Typography>{" "}
                  ____________
                  <Typography variant="span" mb={2}>
                    ₹ {PriceTotal}
                  </Typography>
                </Lable>
              </Box>
            </Box>
          </Grid> */}
        </Grid>
      </PayPalScriptProvider>
    </>
  );
};
