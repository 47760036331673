import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  keyframes,
  styled,
} from "@mui/material";
import React from "react";
import aphia from "../assets/Images/aphia.png";
import onlineclassroomtraining from "../assets/Images/mainhome.jpg";
import blueGirl from "../assets/Images/blueGirl.png";
import manAphiya from "../assets/Images/manAphiya.png";
import showHome from "../assets/Images/showHome.png";
import { Link } from "react-router-dom";
import useScrollToTopOnNavigation from "../hooks/useScrollToTopNavigation";
import urls from "../apis/urlContants";
import { post } from "../apis/apiMethods";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormInput from "../form/FormInput";
import { theme } from "../theme";
import ContactDropusForm from "./Contact.DropusForm";

const FormText = styled(Typography)(({ theme }) => ({
  color: "black",
  lineHeight: "24px",
  display: "flex",
  fontFamily: "Inter",
  fontSize: 30,
  fontWeight: 700,
  justifyContent: "center",
  alignItems: "center",
  letterSpacing: "2px",
  marginBottom: "35px",
}));

const slideInFromLeftAnimation = keyframes`
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0%);
      opacity: 1;
    }
  `;

const fadeInAnimation = keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  `;

const style = {
  // color: "#555",
  lineHeight: "24px",
  fontSize: "15px",
  fontFamily: "inter",
};

const Querys = styled(Box)(({ theme }) => ({
  width: "80%",
  animation: `${fadeInAnimation} 2s ease-out forwards`,
  marginTop: "40px",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const Text = styled(Typography)(({ theme }) => ({
  // color: "#555",
  lineHeight: "24px",
  fontFamily: "Inter",
  fontSize: 15,
  // marginTop: "55px",
  textIndent: 50,
  "::first-letter": {
    color: "Black",
    fontSize: "25px",
    fontWeight: 600,
  },
}));

const Form = styled(Box)(() => ({
  padding: 20,
  borderRadius: "5px",
  marginTop: 20,
  background: "white",
  border: `5px solid ${theme.palette.secondary.main}`,
}));
const OnlineClassroomTraining = () => {
  useScrollToTopOnNavigation();

  return (
    <>
      <Box
        sx={{
          backgroundImage: `radial-gradient(at bottom left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.0)), url(${onlineclassroomtraining})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "100%",
          padding: { xs: "100px 0", sm: "100px 0", md: "170px 50px" },
          // margin: "5px 5px",
        }}
      >
        <Container maxWidth="xl">
          <Typography
            variant="h6"
            sx={{
              opacity: 0,
              animation: `${fadeInAnimation} 1s ease-out forwards`,
              animationDelay: "1s",
              fontFamily: "Quicksand",
              fontSize: { xs: "24px", sm: "36px", md: "52px" },
              color: "#fff",
              fontWeight: 700,
            }}
          >
            Online ClassRoom Training <br />
          </Typography>
        </Container>
      </Box>
      <Box bgcolor="whitesmoke">
        <Container maxWidth="xl">
          <Grid
            container
            spacing={2}
            py={6}
            sx={{
              padding: { xs: "20px 0", sm: "20px 0", md: "50px 50px" },
            }}
          >
            <Grid item xs={12} sm={12} md={12}>
              <Typography
                sx={{ fontWeight: 600, fontSize: 25, fontFamily: "Quicksand" }}
              >
                We improve and transform lives through learning
              </Typography>
            </Grid>

            {/* <Grid item xs={12} sm={12} md={2}>
              <Box>
                <img
                  style={{
                    width: "50%",
                  }}
                  src={girl2}
                  alt="Loading..."
                />
              </Box>
            </Grid> */}
            <Grid item xs={12} sm={12} md={12}>
              <Typography sx={style}>
                Whether you want to learn, upskill, implement or to share what
                you know, you have come to the right place. As a global
                destination for online learning, we empower organizations and
                individuals with flexible, value added and effective skill
                development.
                <Box sx={{ marginBottom: "16px" }} />
                With the rapid evolution in technology, mode of education has
                changed a lot. From traditional classroom learning, people have
                started switching to online classroom training these days.
              </Typography>
            </Grid>
            {/* <Grid item xs={12} sm={12} md={4}>
              <Box>
                <img
                  style={{
                    width: "75%",
                  }}
                  src={girl2}
                  alt="Loading..."
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
              <Typography sx={style}>
                At first time several questions come in mind regarding online
                classroom training like how online training is taken, whether I
                will be able to attend it and learn through it, how will I be
                able to do practical’s, how will I interact with the instructor
                and so on. Let’s see some of the key features of online training
                and difference between classroom and online classroom training.
                Online classroom learning is very flexible. When we think of
                learning online, asynchronous activities such as presentations
                and quizzes appear in mind first. But there is another side to
                online classroom learning and that’s the live, synchronous
                element.
              </Typography>
            </Grid> */}

            {/* <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography sx={style}>
                With the rapid evolution in technology, mode of education has
                changed a lot. From traditional classroom learning, people have
                started switching to online classroom training these days.
              </Typography>
            </Grid> */}
            {/* <Grid item xs={12} sm={12} md={6} lg={6} p={2}>
              <Box>
                <img
                  style={{
                    width: "80%",
                    height: "340px",
                  }}
                  src={image5}
                  alt=""
                />
              </Box>
            </Grid> */}
            {/* <Grid item xs={12} sm={12} md={12} p={2}>
              <Box>
                <img
                  style={{
                    width: "100%",
                    height: "420px",
                  }}
                  src={image9}
                  alt=""
                />
              </Box>
            </Grid> */}
            {/* <Grid item xs={12} sm={12} md={6} lg={6} p={2}>
              <Box>
                <img
                  style={{
                    width: "80%",
                    height: "340px",
                  }}
                  src={image11}
                  alt=""
                />
              </Box>
            </Grid> */}

            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Text>
                Learners from different locations are facing various obstacles
                in attending traditional learning. In order to overcome such
                interruptions, we provide the best way of learning to our
                customers in effective and convenient manner through online. If
                employees can take a training course from their desks,
                organizations no longer have to hold day-long seminars with a
                trainer teaching material to a room full of people. There are
                more benefits of online training aside from cost savings.
              </Text>
              <Typography sx={style} mt={1}>
                At first time several questions come in mind regarding online
                classroom training like how online training is taken, whether I
                will be able to attend it and learn through it, how will I be
                able to do practical’s, how will I interact with the instructor
                and so on. Let’s see some of the key features of online training
                and difference between classroom and online classroom training.
              </Typography>
              <Typography sx={style} mt={1}>
                Online classroom learning is very flexible. When we think of
                learning online, asynchronous activities such as presentations
                and quizzes appear in mind first. But there is another side to
                online classroom learning and that’s the live, synchronous
                element.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Box
                p={1}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  style={{
                    width: "100%",
                  }}
                  src={manAphiya}
                  alt="Loading..."
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <Typography sx={style}>
                In some ways, live online classes are similar to traditional
                classroom training where a trainer presents information and
                interact with people in real time but in online, there are some
                major differences between the two. Let’s try to understand them.
              </Typography>
            </Grid>
            {/* <Grid item xs={12} sm={12} md={12} p={2}>
              <Box>
                <img
                  style={{
                    width: "100%",
                    height: "420px",
                  }}
                  src={image4}
                  alt=""
                />
              </Box>
            </Grid> */}
            <Grid item xs={12} sm={12} md={12}>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: 25,
                  fontFamily: "Quicksand",
                  my: 2,
                }}
              >
                Benefits to Learners
              </Typography>
              <Typography
                sx={{
                  mt: 1,
                  fontWeight: 600,
                  fontSize: 20,
                  fontFamily: "Quicksand",
                }}
              >
                1. Experienced Trainers
              </Typography>
              <Typography sx={style}>
                We choose certified subject matter experts with plentiful
                industry knowledge, and real time working consultant on various
                kind of projects in various MNC's, who have great experience in
                implementing and supporting range of multinational SAP and other
                technologies project, who know how to make their topics engaging
                to create a memorable and valuable learning experience.
              </Typography>
              <Typography
                sx={{
                  mt: 1,
                  fontWeight: 600,
                  fontSize: 20,
                  fontFamily: "Quicksand",
                }}
              >
                2. Comprehensive Courses
              </Typography>
              <Typography sx={style}>
                We offer a wide range of courses in the field of technical,
                functional and domain knowledge. Experts in their respective
                fields create our content.{" "}
              </Typography>
              <Typography
                sx={{
                  mt: 1,
                  fontWeight: 600,
                  fontSize: 20,
                  fontFamily: "Quicksand",
                }}
              >
                3. Training level
              </Typography>
              <Typography sx={style}>
                As provided by industry experts the level of training is very
                high. Practical and real-life use cases are covered in great
                details. All sessions contain an introductory theoretical
                overview and with real time industry specific scenarios.{" "}
              </Typography>
              <Typography
                sx={{
                  mt: 1,
                  fontWeight: 600,
                  fontSize: 20,
                  fontFamily: "Quicksand",
                }}
              >
                4. Interactive Learning
              </Typography>
              <Typography sx={style}>
                Learning is more than just reading or watching videos. Apart
                from virtual instructor led interactive training and classroom
                training, our app features interactive elements such as quizzes,
                assignments, and discussions will enhance your learning
                experience.{" "}
              </Typography>
              <Typography
                sx={{
                  mt: 1,
                  fontWeight: 600,
                  fontSize: 20,
                  fontFamily: "Quicksand",
                }}
              >
                5. Flexibility
              </Typography>
              <Typography sx={style}>
                Life can be busy, but that shouldn't hinder your quest for
                knowledge. Aphia is designed for learners with diverse
                schedules. You can access our content 24/7, from anywhere.{" "}
              </Typography>
              <Typography
                sx={{
                  mt: 1,
                  fontWeight: 600,
                  fontSize: 20,
                  fontFamily: "Quicksand",
                }}
              >
                6. Personalized Learning
              </Typography>
              <Typography sx={style}>
                We understand that everyone's learning journey is unique. Our
                app adapts to your progress, providing recommendations and
                tailored content to help you achieve your goals
              </Typography>
              <Typography
                sx={{
                  mt: 1,
                  fontWeight: 600,
                  fontSize: 20,
                  fontFamily: "Quicksand",
                }}
              >
                7. Course Access duration
              </Typography>
              <Typography sx={style}>Unlimited, Lifetime Access.</Typography>
              <Typography
                sx={{
                  mt: 1,
                  fontWeight: 600,
                  fontSize: 20,
                  fontFamily: "Quicksand",
                }}
              >
                8. Convenience level
              </Typography>
              <Typography sx={style}>
                Most convenient as classes can be taken from comfort of your
                home or even while traveling without missing anything.{" "}
              </Typography>
              <Typography
                sx={{
                  mt: 1,
                  fontWeight: 600,
                  fontSize: 20,
                  fontFamily: "Quicksand",
                }}
              >
                9. Affordable Learning
              </Typography>
              <Typography sx={style}>
                Quality education should be accessible to all. We offer
                competitive pricing and even have free courses to ensure that
                knowledge is within reach.{" "}
              </Typography>
              <Typography
                sx={{
                  mt: 1,
                  fontWeight: 600,
                  fontSize: 20,
                  fontFamily: "Quicksand",
                }}
              >
                10. Doubt clearance
              </Typography>
              <Typography sx={style}>
                Apart from instructor, support team is available to resolve all
                your doubts.{" "}
              </Typography>
              <Typography
                sx={{
                  mt: 1,
                  fontWeight: 600,
                  fontSize: 20,
                  fontFamily: "Quicksand",
                }}
              >
                11. Study Material
              </Typography>
              <Typography sx={style}>
                Students are provided course recordings and complete study
                material which are convenient in handling for future reference.{" "}
              </Typography>
              <Typography
                sx={{
                  mt: 1,
                  fontWeight: 600,
                  fontSize: 20,
                  fontFamily: "Quicksand",
                }}
              >
                12. Certification
              </Typography>
              <Typography sx={style}>
                We will Provide training completion and project completion
                certificate. We also provide guidance for completion of SAP and
                other technology certification and full support for exam
                preparation with study materials under the guidance of certified
                and real time work experience industry expert trainers.{" "}
              </Typography>{" "}
              <Typography
                sx={{
                  mt: 1,
                  fontWeight: 600,
                  fontSize: 20,
                  fontFamily: "Quicksand",
                }}
              >
                13. Job Support
              </Typography>
              <Typography sx={style}>
                Aphia will provide job support for each candidate after
                successfully completion of SAP Training and other technologies
                training and providing 100 % job support until successfully
                placed the candidate and providing guidance for CV/resume
                preparation, interview tips, suggestions for improvement if any
                candidate, communication skills development, personality
                development guidance etc. for successful placement of each
                candidate.{" "}
              </Typography>{" "}
              <Typography
                sx={{
                  mt: 1,
                  fontWeight: 600,
                  fontSize: 20,
                  fontFamily: "Quicksand",
                }}
              >
                14. Training from Future perspective
              </Typography>
              <Typography sx={style}>
                Sessions are recorded to view in future with some exceptions.{" "}
              </Typography>{" "}
              <Typography
                sx={{
                  mt: 1,
                  fontWeight: 600,
                  fontSize: 20,
                  fontFamily: "Quicksand",
                }}
              >
                15. Missed Sessions
              </Typography>
              <Typography sx={style}>
                If a session is missed, you can watch recording before the next
                session. Also, you can attend missed session in batch running in
                parallel.{" "}
              </Typography>{" "}
              <Typography
                sx={{
                  mt: 1,
                  fontWeight: 600,
                  fontSize: 20,
                  fontFamily: "Quicksand",
                }}
              >
                16. Guidance to Students
              </Typography>
              <Typography sx={style}>
                Very personalized and focused guidance is given to individuals
                for their work.{" "}
              </Typography>{" "}
              <Typography
                sx={{
                  mt: 1,
                  fontWeight: 600,
                  fontSize: 20,
                  fontFamily: "Quicksand",
                }}
              >
                17. Community & Support
              </Typography>
              <Typography sx={style}>
                Connect with fellow learners through our community forums and
                receive guidance from instructors and mentors. Learning is a
                collaborative effort at Aphia. Our community is open for
                everyone interested in connecting, learning and sharing new
                ideas, point of views, and proven concepts.{" "}
              </Typography>{" "}
              <Typography
                sx={{
                  mt: 1,
                  fontWeight: 600,
                  fontSize: 20,
                  fontFamily: "Quicksand",
                }}
              >
                18. Training schedule
              </Typography>
              <Typography sx={style}>
                Schedule can be flexible, according to the needs of both teacher
                and students.{" "}
              </Typography>{" "}
              <Typography
                sx={{
                  mt: 1,
                  fontWeight: 600,
                  fontSize: 20,
                  fontFamily: "Quicksand",
                }}
              >
                19. Students evaluation
              </Typography>
              <Typography sx={style}>
                Experts working in leading industries are hired, who can share
                their real-life experience selected best instructor to provide
                best education to participants.
              </Typography>{" "}
              <Typography
                sx={{
                  mt: 1,
                  fontWeight: 600,
                  fontSize: 20,
                  fontFamily: "Quicksand",
                }}
              >
                20. Consultancy
              </Typography>
              <Typography sx={style}>
                Our strategic consultancy services provide expert advice and
                training for organizations to upskill their employees and
                encourage change through learning in the workplace. We can help
                you identify training needs and create bespoke programs for
                training, to be delivered by either our training specialists or
                staff from your own department.
              </Typography>
              <Typography
                sx={{
                  mt: 1,
                  fontWeight: 600,
                  fontSize: 20,
                  fontFamily: "Quicksand",
                }}
              >
                21. Reviews
              </Typography>
              <Typography sx={style}>
                We take pride in the positive feedback we receive from our
                learners. The testimonials, awards, and recognitions we have
                garnered reflect our commitment to excellence and the value we
                bring to our customers.{" "}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontFamily: "Quicksand",
                  fontSize: 25,
                }}
              >
                Join Our Learning Community Today
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Typography sx={style}>
                Discover the endless opportunities for growth and learning that
                Aphia has to offer. Start your technical learning with us and
                unlock your full potential. Whether you're pursuing a degree,
                mastering a skill, or satisfying your curiosity, we're here to
                support you every step of the way. Our Mission is to bring the
                best content to the industry and foster networking, learning and
                sharing of information, knowledge, and skills.
                <Typography
                  variant="subtitle1"
                  mt={1}
                  mb={2}
                  fontFamily="inter"
                >
                  Email us:
                  <a
                    href="mailto:support@aphialearning.com"
                    style={{ textDecoration: "none" }}
                  >
                    support@aphialearning.com
                  </a>{" "}
                  to discuss more about your training needs.
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} display="flex">
              {/* ============================DROPUS CONTACT COMPONENTS==================== */}
              <ContactDropusForm subject="More details about Online classroom training." />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box
                sx={{
                  display: { xs: "none", md: "block" },
                }}
              >
                <img
                  style={{
                    width: "100%",
                  }}
                  src={blueGirl}
                  alt="Loading..."
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Typography>
                To start your new journey of learning,
                <Link to="/signup"> sign up </Link> today and be a part of the
                Aphia family.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};
export default OnlineClassroomTraining;
