import React from "react";
import pmp from "../../assets/Images/courseslogonew.png";
import logo from "../../assets/Images/new-logo.png";
import { Box, Grid, Typography } from "@mui/material";
import { keyframes } from "@emotion/react";
import urls from "../../apis/urlContants";
import { get } from "../../apis/apiMethods";

const slideInFromLeftAnimation = keyframes`
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
`;

export const PMP_CERTIFICATE = ({ selectCourse, menu }) => {
  const courseId = window.localStorage.getItem("courseId");
  const [selectLogo, setSelectLogo] = React.useState([]);

  console.log(selectLogo, "----dd");
  console.log(selectCourse, "----s");

  const getCourseValue = async () => {
    const res = await get(`${urls.course.list}`);
    // console.log(res.data.result.data, "pmp");
    setSelectLogo(res?.data?.result?.data);
  };

  React.useEffect(() => {
    getCourseValue();
  }, [window.localStorage.getItem("courseId")]);

  return (
    <>
      <Box
        sx={{
          width: 340,
          p: 3,
          position: "relative",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundImage: `url(${pmp})`,
          // backgroundImage: `url(${selectCourse?.thumbnailImage})`,
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "160px",
            height: "250px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" textAlign="center">
            <img style={{ height: 30 }} src={logo} alt="Loading..." />
          </Typography>
          <Typography
            variant="h6"
            mt={2}
            sx={{ textAlign: "center", fontWeight: 600 }}
          >
            {selectCourse?.title ? menu : "Black Belt"}
            {/* {selectCourse?.logoText ? selectCourse?.logoText : "Black Belt"} */}
          </Typography>
          {/* {selectLogo?.map(
            (item) => (
              console.log(item, "p"),
              ( */}
          <Typography
            variant="body"
            textAlign="center"
            fontWeight="bold"
            mt={10}
            component="div"
            color="#fff"
            sx={{
              overflowWrap: "break-word",
              width: "100%",
              fontSize: 12,
            }}
          >
            {selectCourse.title}
          </Typography>
          {/* )
            )
          )} */}
        </Box>
      </Box>
    </>
  );
};
